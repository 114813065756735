<template>
  <a-modal
    v-model="visible"
    class="user-info-modify-modal"
    title="Personal Information"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form-model
      :model="form"
      ref="userInfoForm"
      :rules="rules"
      :labelCol="{ span: 4 }"
      :wrapperCol="{ span: 18 }"
    >
      <a-form-model-item label="Username">
        <a-input disabled :value="form.name" />
      </a-form-model-item>
      <a-form-model-item label="Name" prop="displayName">
        <a-input
          :disabled="!modifyAble"
          v-model="form.displayName"
          placeholder="Please enter a name"
          :max-length="32"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UserInfoModifyModal",
  data() {
    return {
      visible: false,
      form: {
        userId: undefined,
        name: "",
        displayName: "",
      },
      rules: {
        displayName: [
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("Please enter a name"));
              } else if (value.length > 32) {
                callback(new Error("The maximum length of the name is 32 characters"));
              } else if (!/^[_a-zA-Z0-9\u4e00-\u9fa5]+$/.test(value)) {
                callback(new Error("Names can only contain English, Chinese characters, numbers, and underscores"));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["hstWebEngine", "localUser"]),
    modifySelf() {
      return this.localUser.UserID === this.form.userId;
    },
    selfIsManager() {
      const { ManagerState, Right } = this.localUser;
      return ManagerState === 2 && Right === 3;
    },
    // 本人修改本人，或者管理员可以修改其他
    modifyAble() {
      return this.modifySelf || this.selfIsManager;
    },
  },
  watch: {
    visible(value) {
      if (value && this.modifySelf) {
        // 由于服务器返回的web本端用户没有用户名字段，所以需要自行拼接到数据中
        this.form.name = this.$route.query.userName;
      }
    },
  },
  methods: {
    handleOk() {
      if (!this.modifyAble) {
        this.visible = false;
        return false;
      }
      this.$refs.userInfoForm.validate(async (valid) => {
        if (valid) {
          try {
            const { userId, displayName } = this.form;
            await this.hstWebEngine.modifyUserInfo({
              UserID: userId,
              Info: {
                DisplayName: displayName,
              },
            });
            this.visible = false;
          } catch ({ msg }) {
            this.$toast(msg);
          }
        }
      });
    },
    handleCancel() {
      this.$refs.userInfoForm.clearValidate();
    },
  },
};
</script>

<style lang="less" scoped>
.user-info-modify-modal {
  /deep/ .ant-modal-body {
    padding-top: 24px;
  }
}
</style>
