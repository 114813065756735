<template>
  <a-modal
    class="no-select"
    v-model="visible"
    :maskClosable="false"
    title="Switching layouts"
    :width="460"
    :footer="null"
    centered
  >
    <div class="layout-select">
      <div
        class="layout-option"
        v-for="item in layoutList"
        :key="item.id"
        @click="onConfirm(item.id)"
      >
        <div :class="{ overview: true, selected: selectedIndex === item.id }">
          <img class="icon-layout" :src="item.icon" alt="icon" />
          <img
            class="icon-selected"
            v-show="selectedIndex === item.id"
            src="@/assets/img/layout/selected.png"
            alt=""
          />
        </div>
        <div class="title">{{ item.title }}</div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { LAYOUT_TYPE } from "@/utils/constants";

export default {
  name: "LayoutSelector",
  props: {
    layoutType: {
      type: Number,
      default: LAYOUT_TYPE.VIDEO,
    },
  },
  data() {
    return {
      visible: false,
      selectedIndex: this.layoutType,
      layoutList: [
        {
          id: LAYOUT_TYPE.DATA,
          title: "Data",
          icon: require("@/assets/img/layout/data.png"),
        },
        {
          id: LAYOUT_TYPE.VIDEO,
          title: "Video",
          icon: require("@/assets/img/layout/video.png"),
        },
        {
          id: LAYOUT_TYPE.DATA_VIDEO,
          title: "Data + Video",
          icon: require("@/assets/img/layout/data-video.png"),
        },
      ],
    };
  },
  methods: {
    onConfirm(id) {
      this.selectedIndex = id;
      this.visible = false;
      this.$emit("on-confirm", id);
    },
  },
  watch: {
    visible(value) {
      value && (this.selectedIndex = this.layoutType);
    },
    layoutType(value) {
      this.selectedIndex = value;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-modal-body {
  padding: 20px 30px;
  background: #f6f7f9;
}
.confirm-btn {
  background: #5aa4f7;
  border-color: #5aa4f7;
  color: #fff;
}
.layout-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .layout-option {
    cursor: pointer;
    &:hover {
      .overview {
        &:not(.selected) {
          border: 2px solid #3c98fe;
        }
      }
    }
    .overview {
      width: 120px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      .icon-selected {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
      }
    }
    .title {
      margin-top: 5px;
      text-align: center;
    }
  }
}
</style>
