<template>
  <div class="clearfix no-select">
    <div class="user-item fl audio">
      <div class="layout-status">
        <div :class="['f-icons', active ? 'active' : '']">
          <share class="icon"></share>
        </div>
        <div class="actions">{{ active ? "Sharing" : "Share" }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { share } from "@/core/icons"; // 布局图标引入
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  name: "ShareBtn",
  components: {
    share,
  },
};
</script>
<style lang="less" scoped>
.layout-status {
  padding-top: 4px;
  height: 60px;
  cursor: pointer;
}
.f-icons {
  margin: 0 auto;
  width: 34px;
  height: 34px;
  position: relative;
  text-align: center;

  &.active {
    color: #5aa4f7;
  }

  .icon {
    width: 36px;
    height: 36px;
  }
  .actions {
    line-height: 16px;
  }
}
.no-devices {
  color: #5f667a;
}
.user-item {
  width: 64px;
  height: 60px;
  font-size: 12px;
  line-height: 16px;
  background-size: 64px 60px;
  color: #c8d7e6;
  text-align: center;
  &:hover {
    background: #434a63;
  }
}
</style>
