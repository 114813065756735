<!--
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2021-10-29 16:36:14
 * @LastEditors: Terry
 * @LastEditTime: 2021-12-02 19:26:27
-->
<template>
  <div class="modal">
    <div v-show="show" id="__modalWrap____" class="modal-wrap"></div>
    <div
      id="__modalBoard___"
      class="modal-board"
      :class="{ show: show, bottom: place === 'bottom', top: place === 'top' }"
    >
      <div class="modal-board-title">Switching shared content</div>
      <div class="modal-content">
        <div
          class="board-item"
          v-for="m in whiteBoardDatas"
          :key="m.mediaId"
          @click.stop="switchBoard(m)"
        >
          <div class="board-canvas" :class="{ 'is-active': id === m.mediaId }">
            <a-tooltip placement="topLeft">
              <template slot="title">
                <span>{{ m.mediaName ? m.mediaName : "Unknown whiteboard" }}</span>
              </template>
              <span>{{ m.mediaName ? m.mediaName : "Unknown whiteboard" }}</span>
            </a-tooltip>
            
          </div>
          <!-- <div class="board-name">白板{{ index + 1 }}</div> -->
          <close-icon class="close-board" @click.stop="closeBoard(m)"></close-icon>
        </div>
        <!-- screenShareDatas -->
        <div
          class="board-item"
          v-for="m in screenShareDatas"
          :key="m.mediaId"
          @click.stop="switchBoard(m)"
        >
          <div class="board-canvas" :class="{ 'is-active': id == m.userId }">
            {{ m.mediaName ? m.mediaName : `Shared screen` }}
          </div>
          <!-- <div class="board-name">白板{{ index + 1 }}</div> -->
          <close-icon class="close-board" @click.stop="closeBoard(m)"></close-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { closeIcon } from "@/core/icons"; // 图标引入
import whiteBoardMixins from "@/views/meeting/whiteBoardMixins";

export default {
  name: "",
  components: {
    closeIcon
  },
  mixins: [whiteBoardMixins],
  props: {
    value: {
      type: Boolean
    },
    position: {
      type: [String],
      default: "bottom"
    }
  },
  data() {
    return {
      // 防止重复点击关闭白板
      isClose: true,
    };
  },
  computed: {
    ...mapGetters(['hstWebEngine', 'mediasMap', 'currentShareMediaId', 'roleAuth', 'localUser', 'boardList']),

    place() {
      return this.position;
    },
    show: {
      get() {
        return this.value;
      },
      set() {
        this.resetValue(false);
      }
    },
    // 白板数据的改变
    whiteBoardDatas() {
      return this.mediasMap["WHITE_BOARD"]; // 白板数据
    },
    localUserId() {
      return this.localUser.UserID;
    },
    // 共享屏幕数据
    screenShareDatas() {
      console.log(this.mediasMap["SCREEN_SHARE"])
      return this.mediasMap["SCREEN_SHARE"];
    },
    id () {
      console.log(this.currentShareMediaId)
      return this.currentShareMediaId;
    },
    // 创建白板权限
    createWhiteboardFlag() {
      const { CreateWhiteboard } = this.roleAuth;
      return CreateWhiteboard == 1;
    },
    // 本地布局权限
    broadcastOwnLayout() {
      const { BroadcastOwnLayout } = this.roleAuth;
      return BroadcastOwnLayout == 1;
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.showOrHidden();
    });
  },
  methods: {
    ...mapMutations(['SET_CURRENT_SHARE_MEDIA_ID']),
    ...mapActions(['setSwitchData']),

    showOrHidden() {
      document
        .querySelector('#__modalWrap____')
        .addEventListener('click', () => {
          this.show && this.resetValue(false);
          document
            .querySelector('#__modalBoard___')
            .addEventListener('click', () => {
              this.resetValue(true);
            });
        });
    },
    // 显示与隐藏
    resetValue(value) {
      this.$emit('input', value);
    },
    // other false 不切换  true 切换到非自己
    closeBoard(m, other) {
      const mediaId = m.mediaId;
      const userId = m.userId;
      const mediaType = m.mediaType;
      const board = this.whiteBoardDatas.find(obj => mediaId == obj.mediaId)
      if (mediaType == 'SCREEN_SHARE' && userId) {
        this.stopScreenShare(userId)
        return
      }
      // 关闭白板
      this.isClose && this.closeWhiteBoard({
        media: board,
        callback: res => {
          console.log(res)
          setTimeout(() => {
            this.isClose = true; 
          }, 500)
          // 关闭的是当前选择的白板，需要切换到其他白板
          // !other && this.currentShareMediaId == mediaId && this.closeSwitchOtherBoard(mediaId)
        },
        fail: (error) => {
          console.warn(error.msg)
          this.$message.warn(error.msg)
          setTimeout(() => {
            this.isClose = true; 
          }, 500)
        }
      });
      this.isClose = false; 
    },
    // 停止屏幕共享
    stopScreenShare(userId) {
      this.hstWebEngine.closeScreenSharing(
        userId,
        () => {},
        (error) => {
         error && this.$message.error(error.msg || `error: ${error.result}`);
        }
      );
    },
    // 切换到其他白板
    closeSwitchOtherBoard(mediaId) {
      const findIndex = this.whiteBoardDatas.findIndex(
          o => mediaId === o.mediaId
        );
        if (findIndex > -1) {
          const mediaBoard =
            findIndex > 0
              ? this.whiteBoardDatas[findIndex - 1]
              : this.whiteBoardDatas.length > 1 ? this.whiteBoardDatas[findIndex + 1] : this.whiteBoardDatas[0];
          this.switchBoard(mediaBoard);
          this.setSwitchData(mediaBoard);
        }
    },
    switchBoard(m) {
      const { mediaId, userId, mediaType } = m;
      const _mediaId = mediaType == 'SCREEN_SHARE' ? userId : mediaId
      if (this.id === _mediaId) return;

      // 存放当前的创建的白板ID
      this.SET_CURRENT_SHARE_MEDIA_ID(`${_mediaId}`);
      this.resetValue(false);

      // 没有同步布局权限， 只能本地切换
      if (!this.broadcastOwnLayout) {
        this.$emit('on-switch-layout', m)
      }

      // from @/views/meeting/whiteBoardMixins
      this.switchWhiteBoard({ mediaId: _mediaId });
      this.setSwitchData(m);
      this.$emit('on-switch', m);
    },

  }
};
</script>

<style lang="less" scoped>
.modal-board {
  width: 660px;
  height: 380px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: none;
  color: #42424a;
  position: absolute;
  left: -175px;
  z-index: 5;
  transition: all 0.3s;
  &.bottom {
    top: -400px;
  }
  &.top {
    top: 60px;
  }
  &.show {
    display: block;
    transition: all 0.3s;
  }
  .modal-board-title {
    text-align: center;
    height: 60px;
    line-height: 60px;
    font-weight: bold;
  }
  .modal-content {
    width: 100%;
    height: 300px;
    margin-bottom: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 28px;

    .board-item {
      float: left;
      width: 180px;
      margin-right: 24px;
      position: relative;
      cursor: pointer;
      margin-bottom: 20px;

      .board-canvas {
        width: 178px;
        height: 100px;
        background: #fff;
        border-radius: 3px;
        border: 1px solid #dee1e5;
        text-align: center;
        line-height: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &.is-active {
          border: 1px solid #4d9cf5;
        }
      }
      .board-name {
        height: 30px;
        text-align: center;
        line-height: 25px;
      }
      .close-board {
        position: absolute;
        top: 7px;
        right: 7px;
        cursor: pointer;
      }
    }
  }
}
.modal-wrap {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
}
</style>
