<!--
 * @Descripttion: 划线工具
 * @version: 
 * @Author: Terry
 * @Date: 2021-10-29 11:53:30
 * @LastEditors: Terry
 * @LastEditTime: 2021-11-23 13:47:15
-->
<template>
  <div class="tool-scribing-wrap">
    <div v-show="show" class="tool-scribing">
      <close-line-icon @click="close"></close-line-icon>
      <!-- 选择 -->
      <tool-select-icon v-show="!toolSelectIconShowIcon" @click="selectClick('toolSelectIconShowIcon', true, 'gmov')"></tool-select-icon>
      <tool-select-focus-icon v-show="toolSelectIconShowIcon" @click="selectClick('toolSelectIconShowIcon', false, 'gmov')"></tool-select-focus-icon>
      <!-- 橡皮擦 -->
      <eraser-hot-icon v-show="!eraserIconShowIcon" @click="selectClick('eraserIconShowIcon', true, 'eraser')"></eraser-hot-icon>
      <eraser-focus-icon v-show="eraserIconShowIcon" @click="selectClick('eraserIconShowIcon', false, 'eraser')"></eraser-focus-icon>
      <!-- 直线图标 -->
      <pencil-focus-icon v-show="pencilIconShowIcon" @click="selectClick('pencilIconShowIcon', false, 'curve')"></pencil-focus-icon>
      <pencil-hot-icon v-show="!pencilIconShowIcon" @click="selectClick('pencilIconShowIcon', true, 'curve')"></pencil-hot-icon>
      <!-- 文字图标 -->
      <text-focus-icon v-show="textIconShowIcon" @click="selectClick('textIconShowIcon', false, 'text')"></text-focus-icon>
      <text-hot-icon v-show="!textIconShowIcon" @click="selectClick('textIconShowIcon', true, 'text')"></text-hot-icon>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  closeLineIcon,
  toolSelectIcon,
  toolSelectFocusIcon,
  eraserFocusIcon,
  eraserHotIcon,
  pencilFocusIcon,
  pencilHotIcon,
  textFocusIcon,
  textHotIcon
} from '@/core/icons' // 图标引入

export default {
  name: 'ToolScribing',
  components: {
    closeLineIcon,
    toolSelectIcon,
    toolSelectFocusIcon,
    eraserFocusIcon,
    eraserHotIcon,
    pencilFocusIcon,
    pencilHotIcon,
    textFocusIcon,
    textHotIcon
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toolSelectIconShowIcon: false,
      eraserIconShowIcon: false,
      pencilIconShowIcon: false,
      textIconShowIcon: false
    }
  },
  computed: {
    ...mapGetters(['hstWebEngine', 'currentShareMediaId']),

    show: {
      get() {
        return this.value
      },
      set() {
        this.$emit('input', false)
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    init(boolean) {
      // this.selectClick('pencilIconShowIcon', boolean, 'curve')
      this.pencilIconShowIcon = boolean
    },
    // 选择划线工具
    selectClick(key, boolean, type) {
      this.reset()
      this[key] = boolean
      // 切换对应划线工具
      this.setWhiteBoardTool(type)
    },
    // type ‘gmov’ 选择， ‘eraser’ 橡皮擦， ‘line’ 直线， ‘curve' 曲线， ‘text' 文本
    setWhiteBoardTool(type) {
      this.hstWebEngine.setWhiteBoardTool(type, this.currentShareMediaId)
    },
    // 重置划线工具
    reset() {
      const b = false
      this.toolSelectIconShowIcon = b
      this.eraserIconShowIcon = b
      this.pencilIconShowIcon = b
      this.textIconShowIcon = b
    },
    // 关闭
    close() {
      this.$emit('input', false)
      this.$emit('on-close')
    }
  }
}
</script>

<style lang="less" scoped>
.tool-scribing-wrap {
  .tool-scribing {
    width: 48px;
    height: 200px;
    background: #32374a;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
    opacity: 0.98;
    border-radius: 6px;
    text-align: center;
    padding-top: 10px;
    position: fixed;
    left: 10px;
    top: 80px;
    cursor: pointer;
  }
}
</style>
