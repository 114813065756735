<template>
  <div class="clearfix">
    <div class="user-item exit">
      <div class="f-icons">
        <exitMeeting class="icon"></exitMeeting>
      </div>
      <div class="actions no-select">Exit</div>
    </div>
  </div>
</template>
<script>
import { exitMeeting } from '@/core/icons' // 布局图标引入
export default {
  data () {
    return {}
  },
  components: {
    exitMeeting
  }
}
</script>
export default { data() {} }
<style lang="less" scoped>
.user-item {
  width: 64px;
  height: 60px;
  font-size: 12px;
  line-height: 16px;
  background-size: 64px 60px;
  text-align: center;
  &:hover {
    background: #434a63;
  }
}
.exit {
  padding-top: 4px;
  height: 60px;
  color: #f45b5b;
  cursor: pointer;
}
.f-icons {
  margin: 0 auto;
  width: 34px;
  height: 34px;
  position: relative;
  text-align: center;

  .icon {
    width: 34px;
    height: 34px;
  }
  .actions {
    line-height: 16px;
  }
}
</style>
