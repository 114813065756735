<template>
  <div class="video-wrap">
    <audio
      ref="audioEl"
      class="audios"
      autoplay
      playsinline
      @play="onPlay"
      @pouse="onPause"></audio>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  props: {
    user: { type: Object, default: () => {} }
  },
  data () {
    return {
      renderuser: {},
      callback: null
    }
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler (newVal, oldVal) {
        console.log('监听渲染音频')
        this.handleRender(newVal, oldVal)
      }
    },
    isMuted: function (val) {
      // 用户是否被静音
      if (val) {
        this.doPause()
      } else {
        this.doPlay()
      }
    }
  },

  computed: {
    ...mapGetters(['hstWebEngine', 'localUser', 'isMuted'])
  },
  mounted() {
    this.SET_HST_AUDIO_REF(this.$refs.audioEl)
  },
  methods: {
    ...mapMutations(['SET_HST_AUDIO_REF']),
    ...mapActions(['setMediaState', 'delMediaSate']),
    handleRender (newVal, oldVal) {
      if (this.isEmptyObject(this.user)) return
      this.$nextTick(() => {
        this.render(newVal, oldVal)
      })
    },
    render (newVal, oldVal) {
      if (this.isEqualUser(newVal, oldVal)) return
      if (!this.isEmptyObject(oldVal)) {
        console.log('@@@@@取消旧的音频渲染')
        this.unRender(oldVal)
      }
      // 加载音频

      const _this = this

      if (this.localUser.UserID !== newVal.userId) {
        console.warn('渲染音频@@@@@@@@', this.localUser.UserID, newVal.userId)
        // 非自己的音频才渲染
        this.callback = this.hstWebEngine.renderRemoteMedia(
          newVal.userId,
          'AUDIO',
          newVal.mediaId,
          this.$refs['audioEl'],
          function () {
            _this.hstWebEngine.getMediaStats(
              {
                userId: newVal.userId,
                mediaType: 'AUDIO',
                mediaId: newVal.mediaId
              },
              function (data) {
                if(data.volume > 0) {
                  // console.warn('音频流信息：', data);
                  _this.setMediaState({
                    userId: newVal.userId + '',
                    state: data.volume
                  })
                }
              },
              1000
            )
          }
        )
      }
    },
    onPlay () {
      console.log('开始播放')
      // 在会前被静音
      if (this.isMuted) {
        this.$nextTick(() => {
          if (this.$refs.audioEl) this.$refs.audioEl.muted = true
        })
      }
    },
    onPause () {
      console.log('暂停播放')
    },
    doPause () {
      // 暂停播放声音
      this.$nextTick(() => {
        this.$refs.audioEl.muted = true
      })
    },
    doPlay () {
      // 继续播放声音
      this.$nextTick(() => {
        this.$refs.audioEl.muted = false
      })
    },
    unRender (user) {
      // 停止接收音频
      try {
        if (this.localUser.UserID && this.localUser.UserID !== user.userId) {
          this.hstWebEngine.disposeRemoteMedia(user.userId, 'AUDIO', user.mediaId, this.$refs['audioEl'], this.callback)
        }
        this.delMediaSate(user.userId) // 音量统计
      } catch (e) {
        console.log(e)
      }
    },
    isEmptyObject (value) {
      return value === undefined || JSON.stringify(value) === '{}'
    },
    isEqualUser (user, other) {
      // 对比新旧媒体
      if (!this.isEmptyObject(other)) {
        return user.userId === other.userId && user.mediaId === other.mediaId
      }
      return false
    }
  },
  beforeDestroy () {
    console.log('音销毁之前')
    this.unRender(this.user)
  }
}
</script>
<style scoped>
video {
  width: 100%;
  height: 100%;
}
</style>
