<template>
  <div v-show="userListShow" class="user-list-layer">
    <div class="head">
      <div class="title">Users</div>
      <div class="close-btn" @click="closeLayer">
        <a-icon type="close" />
      </div>
    </div>
    <div class="user-tool-wrapper">
      <template v-if="nameSearch">
        <a-icon class="user-search-icon" type="search" />
        <a-input
          class="user-search-input"
          ref="searchInput"
          placeholder="Input the user"
          @change="nameSearchChange"
        />
        <a-icon class="user-close-icon" type="close" @click="closeNameSearch" />
      </template>
      <template v-else>
        <div class="state-tab">
          <div
            :class="['tab-item', tabSubClass(undefined)]"
            @click="setFilterState(undefined)"
          >
            <svg-icon class="tab-icon" name="#icon-tab_user"></svg-icon>
            <span>{{ userListLength }}</span>
          </div>
          <div :class="['tab-item', tabSubClass(2)]" @click="setFilterState(2)">
            <svg-icon class="tab-icon" name="#icon-tab_speaking"></svg-icon>
            <span>{{ speakingUserListLength }}</span>
          </div>
          <div :class="['tab-item', tabSubClass(1)]" @click="setFilterState(1)">
            <svg-icon class="tab-icon" name="#icon-tab_applying"></svg-icon>
            <span>{{ applyUserListLength }}</span>
          </div>
        </div>
        <a-icon
          class="user-search-icon"
          type="search"
          @click="openNameSearch"
        />
      </template>
    </div>
    <div class="content">
      <RecycleScroller
        :items="currentUserList"
        keyField="UserID"
        :itemSize="58"
        v-slot="{ item }"
        class="scroller"
      >
        <div class="user-item">
          <a-dropdown :trigger="['contextmenu']">
            <div class="item-left">
              <div class="item-name">
                <p>
                  <span v-if="localUser.UserID === item.UserID"> (I) </span>
                  {{ item.DisplayName }}
                </p>
              </div>
              <div class="item-sub">
                <!-- 入会端图标，当前动作状态 -->
                <device-icon :deviceType="item.TerminalType"></device-icon>
                {{ item | dataStateFilter() }}
                <span
                  v-if="
                    item.hasOwnProperty('DataSharerState') &&
                    item.DataSharerState === 2
                  "
                  >Sharing</span
                >
              </div>
            </div>
            <a-menu slot="overlay" @click="m => handleUserMenuClick(m, item)">
              <a-menu-item key="modifyUserInfo">Viewing user information</a-menu-item>
              <a-menu-item
                key="kickOut"
                v-show="
                  item.UserID !== localUser.UserID &&
                  localUser.ManagerState === 2
                "
                >Kick out of meetings</a-menu-item
              >
            </a-menu>
          </a-dropdown>
          <div class="item-right">
            <div
              class="moderatorIcon"
              v-if="item.DataState && item.DataState === 1"
              placement="bottom"
              :title="item.UserID === localUser.UserID ? 'withdraw application' : 'allow'"
              @click="moderatorIconAction(item)"
            >
              <svg-icon
                class="default-style"
                name="#icon-userlist_speakerApplying"
              ></svg-icon>
              <svg-icon
                class="hover-style"
                name="#icon-userlist_speakerApplying_hot"
              ></svg-icon>
            </div>
            <audio-status
              v-if="item.Audio"
              :device="item.Audio"
              :user="item.UserID"
            ></audio-status>
            <video-status
              v-if="item.Video && item.Video.length > 0"
              :device="item.Video[0]"
              :device-list="item.Video"
              :user="item.UserID"
              :current-user-list="currentUserList"
            ></video-status>
          </div>
        </div>
      </RecycleScroller>
      <user-info-modify-modal ref="userInfoModifyModalRef" />
      <password-modal :visible.sync="visible"></password-modal>
      <invite-confirm-modal
        :visible.sync="inviteModelVisible"
        :inviteType="inviteType"
      ></invite-confirm-modal>
      <div class="list-footer">
        <a-button @click="applyModeratorRight">
          <svg-icon
            class="button-icon"
            name="#icon-userlist_speakeradmin"
          ></svg-icon>
          {{ localUser.DataState | dataStateBtn() }}
        </a-button>
        <a-button @click="applyManagerRight">
          <svg-icon class="button-icon" name="#icon-ic_manager"></svg-icon>
          {{ localUser.ManagerState | ManagerStateBtn() }}
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { RecycleScroller } from '@/components/index'
import AudioStatus from './audioStatus.vue'
import VideoStatus from './videoStatus.vue'
import DeviceIcon from './deviceIcon.vue'
import PasswordModal from './passwordModal.vue'
import InviteConfirmModal from './inviteConfirmModal.vue'
import UserInfoModifyModal from './UserInfoModifyModal.vue'
import sortName from './utils/arraySortByName'
export default {
  name: 'UserListLayer',
  components: {
    RecycleScroller,
    AudioStatus,
    VideoStatus,
    DeviceIcon,
    PasswordModal,
    InviteConfirmModal,
    UserInfoModifyModal,
  },
  data() {
    return {
      items: [],
      visible: false, // 申请管理员密码输入框
      inviteModelVisible: false,
      inviteType: 'Audio',
      inviteData: {},
      // 是否在根据昵称搜索
      nameSearch: false,
      usersFilterParams: {
        // 发言状态0关、1申请、2开、undefined所有
        audioState: undefined,
        displayName: '',
      },
      sortUserList: [],
    }
  },
  watch: {
    groupUserList: {
      immediate: true,
      deep: true,
      handler(newVal) {
        // 排除掉会控虚拟用户
        const invalidTerminalType = [26]
        const allUsers = Object.values(newVal).filter(
          ({ TerminalType }) =>
            !invalidTerminalType.includes(Number(TerminalType))
        )
        // 再按 SeatList 排序
        this.sortUserList = sortName(allUsers).sort(function (a, b) {
          return a.SeatList - b.SeatList
        })
      },
    },
  },
  computed: {
    ...mapState({
      groupUserList: ({ user }) => user.groupUserList,
    }),
    ...mapGetters([
      'localUser',
      'userListShow',
      'hstWebEngine',
      'roleAuth',
      'validUserList',
    ]),
    currentUserList() {
      //自己排到第一个
      const _localUserIndex = this.sortUserList.findIndex(
        e => e.UserID === this.localUser.UserID
      )
      const _localUser = this.sortUserList.splice(_localUserIndex, 1)
      this.sortUserList.splice(0, 0, _localUser[0])
      // 非自己的主持人 排在第二位
      const _presenterIndex = this.sortUserList.findIndex(
        e => e.DataState === 2 && e.UserID !== this.localUser.UserID
      )
      if (_presenterIndex > -1) {
        const _presenter = this.sortUserList.splice(_presenterIndex, 1)
        this.sortUserList.splice(1, 0, _presenter[0])
      }

      const { displayName, audioState } = this.usersFilterParams
      const nameFilterRes = this.sortUserList.filter(
        e => e.DisplayName && e.DisplayName.indexOf(displayName) > -1
      )
      if (audioState === undefined) {
        return nameFilterRes
      } else if (audioState === 1) {
        return this.getApplyingUsers(nameFilterRes)
      } else if (audioState === 2) {
        return nameFilterRes.filter(
          e => e.Audio && parseInt(e.Audio.State) === 2
        )
      }
    },
    userListLength() {
      return this.validUserList.length
    },
    // 正在发言参会人
    speakingUserListLength() {
      return this.validUserList.filter(
        e => e.Audio && parseInt(e.Audio.State) === 2
      ).length
    },
    // 申请状态参会人
    applyUserListLength() {
      return this.getApplyingUsers(this.validUserList).length
    },
    tabSubClass() {
      return state =>
        this.usersFilterParams.audioState === state ? 'actived' : ''
    },
  },
  created() {
    this.hstWebEngine.on('Manager.Invite.Media', inviteType => {
      this.inviteType = inviteType
      this.inviteModelVisible = true
    })
  },
  methods: {
    ...mapMutations(['SET_USER_LIST_SHOW']),
    closeLayer() {
      this.SET_USER_LIST_SHOW(false)
    },
    moderatorIconAction(item) {
      if (item.UserID === this.localUser.UserID) {
        this.applyModeratorRight()
        return
      }
      // 授予别人主持人
      this.hstWebEngine.grantModeratorRight(
        item.UserID,
        () => {},
        res => {
          this.$toast(res.msg)
        }
      )
    },
    applyModeratorRight() {
      this.hstWebEngine.applyModeratorRight()
    },
    applyManagerRight() {
      if (this.localUser.ManagerState && this.localUser.ManagerState === 2) {
        // 放弃管理员
        this.hstWebEngine.giveUpManagerRight(res => {
          this.$toast(res.msg)
        })
        return
      }
      if (this.roleAuth.ApplyAdminPermissions !== '1') {
        this.$toast('You have not requested administrator privileges')
        return
      }
      // 弹出输入密码框
      this.visible = true
    },
    setFilterState(state) {
      this.usersFilterParams.audioState = state
    },
    nameSearchChange(e) {
      this.usersFilterParams.displayName = e.target.value
    },
    openNameSearch() {
      this.nameSearch = true
      this.$nextTick(() => {
        this.$refs.searchInput.focus()
      })
    },
    closeNameSearch() {
      this.nameSearch = false
      this.usersFilterParams.displayName = ''
    },
    getApplyingUsers(users) {
      // 包含音视频、主持人申请
      const videoApply = user =>
        user.Video && user.Video.some(({ State }) => parseInt(State) === 1)
      const audioApply = user => user.Audio && parseInt(user.Audio.State) === 1
      const hostApply = user => parseInt(user.DataState) === 1
      return users.filter(e => videoApply(e) || audioApply(e) || hostApply(e))
    },
    handleUserMenuClick({ key }, user) {
      typeof this[key] === 'function' && this[key](user)
    },
    // 修改参会人信息
    modifyUserInfo(user) {
      const { UserID: userId, Name: name, DisplayName: displayName } = user
      const { userInfoModifyModalRef: ur } = this.$refs
      ur.visible = true
      ur.form = { userId, name, displayName }
    },
    kickOut(user) {
      this.hstWebEngine.kickUser(user.UserID)
    },
  },
  filters: {
    dataStateFilter: function (item) {
      const dotString =
        item.DataSharerState && item.DataSharerState === 2 ? ',' : ''
      if (item.DataState && item.DataState === 2) {
        return 'Host' + dotString
      }
      if (item.ManagerState && item.ManagerState === 2) {
        return 'Administrator' + dotString
      }
      return ''
    },
    dataStateBtn: val => {
      const text = new Map([
        [1, 'Cancellation of application'],
        [2, 'Give up the host'],
        [0, 'Apply the host'],
        [undefined, 'Apply the host'],
      ])
      return text.get(val)
    },
    ManagerStateBtn: val => {
      const text = new Map([
        [0, 'Apply administrator'],
        [2, 'Give up administrator'],
        [undefined, 'Apply administrator'],
      ])
      return text.get(val)
    },
    formatLength: val => (val > 999 ? '999+' : val),
  },
}
</script>

<style lang="less" scoped>
.user-list-layer {
  display: flex;
  flex-direction: column;
  user-select: none;

  .head {
    height: 30px;
    border-bottom: 1px solid #e5e7f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;

    .close-btn {
      height: 100%;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .user-tool-wrapper {
    height: 50px;
    border-bottom: 1px solid #e5e7f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .state-tab {
      display: flex;
      align-items: center;
      height: 100%;
      .tab-item {
        height: 100%;
        display: flex;
        align-items: center;
        color: #9ba2bb;
        padding: 0 15px;
        cursor: pointer;
        &:hover {
          color: #3c98fe;
        }
        &.actived {
          color: #3c98fe;
          position: relative;
          &:after {
            display: block;
            width: 100%;
            content: '';
            height: 3px;
            background: #3c98fe;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
        .tab-icon {
          width: 20px;
          height: 20px;
        }
        span {
          margin: 10px;
        }
      }
    }
    .user-search-input {
      border: none;
      outline: none;
      box-shadow: none;
      padding: 0;
    }
    .user-search-icon {
      padding: 10px;
      margin: 0 5px;
      font-size: 20px;
      cursor: pointer;
      color: #8b94b2;
    }
    .user-close-icon {
      padding: 10px;
      margin: 0 5px;
      cursor: pointer;
      color: #8b94b2;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: #f1f3f9;

    .scroller {
      height: 0;
      padding: 5px 20px 15px;
      flex: 1;
      .user-item {
        display: flex;
        flex-direction: row;
        .item-left {
          flex: 1;
          flex-direction: column;
          width: 0;
          .item-name {
            p {
              white-space: nowrap; /* 规定文本是否折行 */
              overflow: hidden; /* 规定超出内容宽度的元素隐藏 */
              text-overflow: ellipsis;
              margin: 0;
            }
            span {
              color: gray;
            }
          }
          .item-sub {
            flex: 1;
            color: orangered;
            font-size: 10px;
          }
        }
        .item-right {
          width: 120px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          .moderatorIcon {
            cursor: pointer;
            .default-style {
              display: block;
            }
            .hover-style {
              display: none;
            }
            &:hover {
              .default-style {
                display: none;
              }
              .hover-style {
                display: block;
              }
            }
          }
          .svg-icon {
            font-size: 32px;
            color: blue;
            &:hover {
              color: red;
            }
          }
        }
      }
    }
    .list-footer {
      border-top: 1px solid #dedede;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 40px;
      .ant-btn {
        margin-right: 14px;
      }
      .button-icon {
        margin-right: 10px;
      }
    }
  }
}
</style>
