<!--
 * @Descripttion: 登录会议之前左侧页面 
 * @version: 
 * @Author: Terry
 * @Date: 2021-10-25 17:56:05
 * @LastEditors: Terry
 * @LastEditTime: 2021-10-25 20:06:02
-->
<template>
  <div class="meeting-info">
    <div class="meeting-name">{{ meetingInfo.roomName }}</div>
    <div class="invite-link">
      <div><span>Invitation Link: </span></div>
      <span class="wb">{{ meetingInfo.meetingURL }}</span>
    </div>
    <div class="btn-wrap">
      <a-button class="btn-copy " size="small" @click="copyMeetingLink">Copy</a-button>
      <a-button class="join-btn" @click="joinClick">Join the meeting</a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BeforeLoginLeft',
  components: {},
  props: {
    info: {
      type: [Object],
      default: () => {}
    }
  },
  data() {
    return {
      userName: this.$route.query.userName, // 用户名
      meetingInfo: {
        roomName: '',
        meetingURL: ''
      }
    }
  },
  computed: {},
  watch: {
    info: {
      handler() {
        this.handleInfo()
      }
    },
    deep: true
  },
  created() {
    this.handleInfo()
  },
  methods: {
    handleInfo() {
      const { roomName = '', meetingURL = '' } = this.info
      this.meetingInfo = { roomName, meetingURL }

    },
    joinClick() {
      this.$emit('on-join')
    },
    copyMeetingLink() {
      const text = `${this.userName} invites you to attend a meeting. Click the link below to join ${this.meetingInfo.meetingURL}`
      this.$copyText(text)
        .then(() => {
          // this.$toast.top('复制邀请信息成功')
          this.$toast('Copy invitation link successfully', {
            className: ['my-toast', 'toast-warning'],
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
            isCopy: true,
            mode: 'override'
          })
        })
        .catch(err => {
          console.log('copy.err', err)
          this.$tosat('Copy failed')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.meeting-info {
  padding-top: 20px;
  color: #42424a;
  width: 360px;
  margin-right: 100px;
  .meeting-name {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 50px;
  }

  .btn-copy {
    margin: 10px 0 45px;
    width: 70px;
    height: 30px;
    background: linear-gradient(0deg, #f8faff, #ffffff);
    border: 1px solid #c6ccd8;
    box-shadow: 0px 1px 0px 0px rgba(6, 22, 45, 0.05);
    border-radius: 4px;
  }
  .join-btn {
    display: block;
    // width: 100px;
    background: #5aa4f7;
    border-color: #5aa4f7;
    color: #fff;
    padding: 0 40px;
    height: 40px;
  }
}
</style>
