<template>
   <div class="clearfix no-select">
    <div class="user-item fl vedio" ref="mScreen">
      <div class="vedio-status" @click="clickhandle">
        <div class="f-icons">
          <template v-if="!recordStatus">
            <recordStart class="icon"></recordStart>
          </template>
          <template v-if="recordStatus">
            <recordingStop class="icon"></recordingStop>
          </template>
        </div>
        <div class="actions no-select">
          {{ recordStatus ? 'Recording' : 'Record' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { recordStart, recordingStop }from '@/core/icons'
export default {
  components: {
    recordStart,
    recordingStop
  },
  props: {
    status: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      recordStatus: false
    }
  },
  methods: {
    clickhandle () {
      // this.recordStatus = !this.recordStatus
      this.$emit('onChange', this.recordStatus)
    }
  },
  watch: {
    status: {
      handler (val) {
        this.recordStatus = val
      },
      immediate: true
    }
  }
}
</script>
<style lang="less" scoped>
.vedio-status {
  padding-top: 4px;
  height: 60px;
  cursor: pointer;
}
.canclose {
  color: #5f667a;
  cursor: not-allowed;
}
.f-icons {
  margin: 0 auto;
  width: 34px;
  height: 34px;
  position: relative;
  text-align: center;

  .icon {
    width: 34px;
    height: 34px;
  }
  .no-device {
    color: #c7d4e8;
  }
  .actions {
    line-height: 16px;
  }
}
.no-devices {
  color: #5f667a;
}
.user-item {
  width: 64px;
  height: 60px;
  font-size: 12px;
  line-height: 16px;
  background-size: 64px 60px;
  color: #c8d7e6;
  text-align: center;
  &:hover {
    background: #434a63;
  }
}
.selects {
  width: 20px;
  height: 60px;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #434a63;
  }
  .arrow-up,
  .no-dev-up,
  .arrow-down,
  .no-dev-down {
    position: absolute;
    top: 18px;
    left: 6px;
    width: 0;
    height: 0;

    font-size: 0;
    line-height: 0;
  }
  .arrow-up {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 6px solid #9dadc5;
  }
  .arrow-down {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #9dadc5;
  }

  .no-dev-up {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 6px solid #5f667a;
  }
  .no-dev-down {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #5f667a;
  }
}
.content-item {
  padding: 16px 20px;
  .radios {
    display: block;
    height: 30px;
    line-height: 30px;
  }
}
.bor-1 {
  border-bottom: 1px solid #e1e5ec;
}
.tooltip-title {
  font-weight: bold;
  color: #42424a;
  font-size: 16px;
  line-height: 30px;
}
</style>