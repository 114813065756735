<template>
  <div class="user-list-video">
    <a-popover
      v-model="popShow"
      v-if="deviceList.length > 1"
      trigger="click"
      overlayClassName="video-select-pop"
      placement="bottomRight"
    >
      <svg-icon class="default-style" :name="videoIconName"></svg-icon>
      <div slot="content" class="video-select">
        <div
          class="video-select-item"
          v-for="item in deviceList"
          :key="item.ID"
          @click="changeState(item)"
        >
          <svg-icon
            v-if="item.State === 1"
            class="apply-icon"
            name="#icon-gongjulan_shipinjushou"
          ></svg-icon>
          <a-icon
            v-else
            :class="['selected-icon', item.State === 2 ? 'selected' : '']"
            type="check-circle"
            theme="filled"
          />
          <div class="video-name">{{ item.Name }}</div>
        </div>
      </div>
    </a-popover>
    <svg-icon
      v-else
      class="default-style"
      :name="videoIconName"
      @click.native="changeState(deviceList[0])"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "VideoStatusX",
  props: {
    user: {
      type: Number,
      default: 0,
    },
    deviceList: {
      type: Array,
      default: () => [],
    },
    currentUserList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      popShow: false,
    };
  },
  watch: {
    currentUserList() {
      this.popShow = false;
    },
  },
  computed: {
    ...mapGetters(["hstWebEngine", "localUser", "roleAuth"]),
    //0:关闭;1:申请广播;2:已广播
    videoIconName: function () {
      const icon = new Map([
        [0, "userlist_video_closed"],
        [1, "userlist_videoApplying"],
        [2, "userlist_video"],
      ]);
      let multipleState = 0;
      this.deviceList.some(({ State }) => parseInt(State) === 2) &&
        (multipleState = 2);
      this.deviceList.some(({ State }) => parseInt(State) === 1) &&
        (multipleState = 1);
      const iconName = `#icon-${icon.get(multipleState)}`;
      return iconName;
    },
  },
  methods: {
    ...mapActions(["SetVideoStatus", "SetLocalPubVideo"]),
    changeState(video) {
      this.popShow = false;
      // 判断是操作自己，还是别人
      if (this.localUser.UserID === this.user) {
        this.changeSelfState(video);
        return;
      }
      this.changeOthersState(video);
    },
    changeSelfState({ State, ID }) {
      //0:关闭;1:申请发言;2:已发言
      if (State === 0) {
        this.hstWebEngine.broadcastVideo({ mediaId: ID });
        return;
      }
      this.SetLocalPubVideo(false);
      if (
        this.roleAuth.BroadcastOwnVideo === "0" ||
        this.roleAuth.BroadcastOwnVideo === undefined
      ) {
        this.SetVideoStatus(3);
      } else {
        // 无需权限
        this.SetVideoStatus(2);
      }
      this.hstWebEngine.unbroadcastVideo({ mediaId: ID });
    },
    changeOthersState({ State, ID }) {
      // 判断是否有权限： 仅管理员或主持人有权限操作他人音视频
      if (this.localUser.DataState !== 2 && this.localUser.ManagerState !== 2) {
        this.$toast("You do not have permission to operate, please apply for host or administrator permissions");
        return;
      }
      //0:关闭;1:申请广播;2:已广播
      if (State === 2) {
        this.hstWebEngine.unbroadcastVideo({ mediaId: ID, userId: this.user });
        return;
      }
      // 管理员开启他人音频
      this.hstWebEngine.boradOthersMedia(
        { mediaId: ID, userId: this.user },
        "Video",
        (direct) => {
          if (direct || State === 1) return;
          this.$notification.success({
            placement: "bottomRight",
            bottom: "50px",
            duration: 3,
            message: "The operation has been successful, waiting for the other party's consent",
          });
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.user-list-video {
  position: relative;

  .svg-icon {
    font-size: 36px;
  }
}

.video-select-pop {
  .video-select {
    padding: 7px 10px;
  }

  .video-select-item {
    display: flex;
    align-items: center;
    cursor: pointer;

    .selected-icon {
      color: transparent;
      font-size: 16px;
      margin-right: 10px;

      &.selected {
        color: #3c98fe;
      }
    }

    .apply-icon {
      font-size: 20px;
      margin-right: 6px;
    }

    .video-name {
      width: calc(100% - 26px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 28px;
    }
  }
}
</style>

<style lang="less">
.video-select-pop {
  .ant-popover-inner-content {
    min-width: 250px;
    width: 250px;
  }
}
</style>
