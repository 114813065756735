<template>
  <div v-show="chatShow" class="chat-tool">
    <div class="head">
      <div class="title">Chat</div>
      <!-- <div class="title">聊天 ｜ <span @click="clearMsg">清屏</span></div> -->
      <div class="close-btn" @click="closeChat">
        <a-icon type="close" />
      </div>
    </div>
    <div class="content">
      <DynamicScroller
        ref="scroller"
        :items="chatList"
        :min-item-size="24"
        class="scroller"
        @resize="scrollToBottom()"
      >
        <DynamicScrollerItem
          slot-scope="{ item, index, active }"
          :item="item"
          :active="active"
          :data-index="index"
        >
          <chat-item :data="item"></chat-item>
        </DynamicScrollerItem>
      </DynamicScroller>
    </div>
    <div class="input">
      <div :class="['emoji-wrapper', emojiSelectorShow ? 'show' : '']">
        <img
          class="emoji-item"
          v-for="item in emojiList"
          :src="item.value"
          :alt="item.type"
          :key="item.key"
          @click="addEmoji(item)"
        />
      </div>
      <div class="head-tool">
        <div>All users</div>
        <div
          :class="['emoji-btn', emojiSelectorShow ? 'active' : '']"
          @click.stop="emojiSelectorShow = !emojiSelectorShow"
        >
          <a-icon type="smile" />
        </div>
      </div>
      <a-textarea
        class="chat_editor"
        placeholder="Please enter message"
        ref="chat_editor"
        :max-length="256"
        v-model="msgContent"
        @pressEnter.prevent="sendMsg"
      />
      <div class="send-btn-wrapper">
        <a-button class="send-btn" @click="sendMsg">Send</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep'
import { mapGetters, mapMutations } from 'vuex'
import { isIE } from '@/utils/util'
import { isTarget, formatChatList } from './utils'
import ChatItem from './ChatItem.vue'
import { DynamicScroller, DynamicScrollerItem } from '@/components/index'

const emojisContext = require.context('./emoji', false, /.png$/)
let emojiList = []

emojisContext.keys().forEach(key => {
  const emojiName = /^\.\/([()a-zA-Z0-9]+)\.png$/.exec(key)[1]
  emojiList.push({
    key,
    name: /^\(([a-zA-Z0-9]+)\)$/.exec(emojiName)[1],
    type: `/:E${emojiName.length}${emojiName.toUpperCase()}`,
    value: require(`./emoji/${emojiName}.png`),
  })
})

export default {
  name: 'ChatTool',
  components: { ChatItem, DynamicScroller, DynamicScrollerItem },
  data() {
    return {
      emojiSelectorShow: false,
      emojiList,
      chatList: [],
      msgContent: '',
    }
  },
  computed: {
    ...mapGetters(['chatShow', 'hstWebEngine']),
  },
  created() {
    this.hstWebEngine.on('ON.CHAT.MSG', msgs => {
      const oldChatList = clonedeep(this.chatList)
      const newChatList = msgs.map(e => {
        e.formated = oldChatList.findIndex(o => o.id === e.id) > -1
        return e
      })
      this.chatList = formatChatList(newChatList)
      this.scrollToBottom()
    })
  },
  watch: {
    chatShow: {
      immediate: true,
      handler(value) {
        this.hstWebEngine.turnChatLayer(value)
        if (!value) {
          this.hstWebEngine.on('UN.READ.MSG.NUM', num => {
            this.SET_UNREAD_NUM(num)
          })
        } else {
          this.scrollToBottom()
        }
      },
    },
    emojiSelectorShow: {
      immediate: true,
      handler(value) {
        const fn = e => {
          const isWrapper = isTarget(e.target, 'emoji-wrapper')
          const isItem = isTarget(e.target, 'emoji-item')
          if (!isWrapper && !isItem && this.emojiSelectorShow) {
            this.emojiSelectorShow = false
          }
        }
        if (value) {
          document.addEventListener('click', fn)
        } else {
          document.removeEventListener('click', fn)
        }
      },
    },
  },
  methods: {
    ...mapMutations(['SET_CHAT_SHOW', 'SET_UNREAD_NUM']),
    closeChat() {
      this.SET_CHAT_SHOW(false)
    },
    // clearMsg() {
    //   this.hstWebEngine.clearMessage()
    // },
    scrollToBottom() {
      this.$refs.scroller.scrollToBottom()
    },
    addEmoji(item) {
      if (this.msgContent.length >= 256) {
        this.$message.warning('Chat content cannot be longer than 256 characters')
        this.emojiSelectorShow = false
        this.$refs['chat_editor'].$el.focus()
        return
      }
      const { type } = item
      const { msgContent } = this
      if (isIE()) {
        this.msgContent += type
      } else {
        const start = this.$refs['chat_editor'].$el.selectionStart
        const textA = msgContent.slice(0, start)
        const textB = msgContent.slice(start)
        this.msgContent = `${textA}${type}${textB}`
      }
      this.emojiSelectorShow = false
      this.$refs['chat_editor'].$el.focus()
    },
    sendMsg() {
      if (this.msgContent === '') {
        this.$message.warning('You cannot send an empty message')
        return false
      }
      this.hstWebEngine.sendMessage(
        this.msgContent,
        () => {
          this.msgContent = ''
        },
        error => {
          this.$message.error(error.msg)
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
.chat-tool {
  display: flex;
  flex-direction: column;
  user-select: none;

  .head {
    height: 30px;
    border-bottom: 1px solid #e5e7f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;

    .close-btn {
      height: 100%;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .content {
    flex: 1;
    display: block;
    overflow: hidden;
    background: #f1f3f9;

    .scroller {
      height: 100%;
      padding: 5px 20px 15px;
    }
  }

  .input {
    height: 115px;
    background: #f1f3f9;
    border-top: 1px solid #e5e7f0;
    position: relative;

    .emoji-wrapper {
      width: 100%;
      height: 0;
      position: absolute;
      left: 0;
      top: 0;
      background: #fff;
      transition: all 0.3s;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      &.show {
        height: 170px;
        top: -170px;
        padding: 5px;
      }

      .emoji-item {
        width: 30px;
        height: 30px;
        margin: 5px 4px;
        cursor: pointer;
      }
    }

    .head-tool {
      height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;

      .emoji-btn {
        height: 100%;
        padding: 0 10px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.active {
          color: #5aa4f7;
        }
      }
    }

    .chat_editor {
      width: 100%;
      height: 45px;
      padding: 3px 5px;
      outline: none;
      border: none;
      resize: none;
      background: transparent;
      border-radius: 0;
      line-height: 20px;

      &:focus {
        box-shadow: none;
      }
    }

    .send-btn-wrapper {
      text-align: right;

      .send-btn {
        width: 90px;
        height: 28px;
        background: #5aa4f7;
        border: 1px solid #4899f4;
        color: #fff;
      }
    }
  }
}
</style>
