/*
 * @Descripttion:
 * @version:
 * @Author: Terry
 * @Date: 2021-10-25 17:07:00
 * @LastEditors: Terry
 * @LastEditTime: 2021-10-25 17:08:38
 */
import NotLogged from './not-logged'

export default NotLogged
