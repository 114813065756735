/*
 * @Descripttion: 白板相关的功能
 * @version:
 * @Author: Terry
 * @Date: 2021-10-26 14:40:58
 * @LastEditors: Terry
 * @LastEditTime: 2021-12-02 17:24:23
 */
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'hstWebEngine',
      'mediasMap',
      'boardNumber',
      'roleAuth',
      'role',
      'localUser',
      'boardList',
      'currentShareMediaId',
    ]),

    // 本地布局权限
    broadcastOwnLayout() {
      const { BroadcastOwnLayout } = this.roleAuth
      return BroadcastOwnLayout == 1
    },
    // 创建白板权限
    createWhiteboardFlag() {
      const { CreateWhiteboard } = this.roleAuth
      return CreateWhiteboard == 1
    },
    // 白板数据
    whiteBoardDatas() {
      return this.mediasMap['WHITE_BOARD']
    },
    // 现在所有共享的流
    mediaList() {
      if (!this.mediasMap) return []
      const keys = Object.keys(this.mediasMap)
      let list = []
      keys.forEach(key => {
        // 去除video和audio VIDEO, AUDIO
        if (key != 'VIDEO' && key != 'AUDIO') {
          list = [...list, ...this.mediasMap[key]]
        }
      })

      return list
    },

    // 判断是不是其他人正在开启共享
    permissionShare() {
      const localUserId = this.localUserId
      let permission = true
      // if (this.mediaList.length == 0) permission = false;

      this.mediaList.map(item => {
        if (item.userId != localUserId) {
          permission = false
        }
      })

      return permission
    },
    // 关闭白板权限
    closeOthersWhiteboard() {
      const { CloseOthersWhiteboard = 0 } = this.roleAuth
      return CloseOthersWhiteboard == 1
    },
    localUserId() {
      return this.localUser.UserID
    },
  },
  data() {
    return {
      // 计算版本数量
      // boardNumber: 1,
      userName: this.$route.query.userName, // 用户名
      // 防止重复点击创建白板
      isCreate: true,
    }
  },
  watch: {
    // 监听自己没有创建白板权限时，需要关闭自己创建的所有白板
    createWhiteboardFlag: {
      handler(newVal, oldVal) {
        newVal != oldVal && !newVal && this.handlerAllCreateWhiteboard()
      },
    },
    // 监听角色变化
    localUser: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        // 有非主持人变成主持人，需要同步本地布局
        if (
          (!oldVal || (oldVal && oldVal.DataState != 2)) &&
          newVal.DataState &&
          newVal.DataState === 2
        ) {
          this.switchWhiteBoard({
            mediaId: this.currentShareMediaId,
          })
          // this.hstWebEngine.uploadLayout() // 同步布局 (暂时屏幕 切换后同步无效 后续处理)
        }
      },
    },
  },

  methods: {
    ...mapActions(['setBoardList', 'setSwitchData']),
    ...mapMutations(['SET_CURRENT_SHARE_MEDIA_ID']),

    // 判断共享白板权限
    judgePermssion() {
      // if (this.whiteBoardDatas.length > 8) {
      //   this.$message.warn(
      //     "当前打开的文档数量已超限制，请先关闭一些已打开的文档"
      //   );
      //   return;
      // }
      // const msg1 = '会议开启仅管理员操作，请联系管理员或申请管理员';
      const msg2 = 'You do not have permission to share'
      // const msg3 = '会议室同时只允许1个人共享，请等待对方结束后再共享';
      const msg3 = 'Another user is sharing, please wait for them to finish before sharing!'
      // 无权限
      if (!this.createWhiteboardFlag) {
        this.$message.warn(msg2)
        return
      }
      //有权限时 其他端正在共享
      if (this.createWhiteboardFlag && !this.permissionShare) {
        this.$message.warn(msg3)
        return
      }

      return true
    },
    // 创建白板
    async createWhiteBoard(data, fn) {
      if (!this.judgePermssion()) {
        setTimeout(() => {
          this.isCreate = true
        }, 500)
        return
      }

      // const boardName = `${data.displayName}-白板${this.boardNumber}`
      const boardName = `white board ${this.boardNumber}`
      const type = 'create'
      const LayoutMode = data ? data.LayoutMode : ''

      // 创建回调函数
      const callback = ({ msg, data }) => {
        setTimeout(() => {
          this.isCreate = true
        }, 500)

        const { mediaId, error } = data
        if (msg === 'success') {
          this.setBoardList({ mediaId, boardName })
          // 存放当前的创建的白板ID
          this.SET_CURRENT_SHARE_MEDIA_ID(`${mediaId}`)
          // 成功回调
          fn && fn({ mediaId, type: true })

          return
        }

        if (!error) return
        // 处理错误信息
        // const { result } = error;
        const errMsg = error.msg
        // this.$message.warn(`code:${result},msg:${errMsg}`)
        this.$message.warn(`${errMsg}`)
        fn && fn({ error, type: false })
      }

      // if (!this.isCreate) {
      //   this.mediaList.length < 9 && this.$message.warn("上一个创建的白板正在生成，请稍后创建！");
      // }
      // 调用websdk创建版本
      this.isCreate &&
        this.hstWebEngine.operateShare({
          type,
          data: Object.assign({}, data, {
            broadcastOwnLayout: this.broadcastOwnLayout,
            board_name: boardName,
            LayoutMode,
            UserID: this.localUserId,
          }),
          callback,
        })
      this.isCreate = false
    },
    // 校验关闭的是不是自己的白板
    judgeCloseBoardPremission(id) {
      return this.mediaList.find(midea => {
        const { userId, mediaId } = midea
        return userId == this.localUserId && id == mediaId
      })
    },
    // 关闭白板
    closeWhiteBoard({ media, callback, fail }) {
      const { mediaId } = media
      if (
        !this.judgeCloseBoardPremission(mediaId) &&
        !this.closeOthersWhiteboard
      ) {
        // this.$message.warn("您暂无权限操作！");
        fail && fail({ msg: 'You do not have permission to operate!' })
        return
      }

      const currentMedia = this.findCurrentMedia(mediaId)
      const { mediaType, userId } = currentMedia

      // 关闭共享屏幕
      if (mediaType === 'SCREEN_SHARE') {
        this.hstWebEngine.closeScreenSharing(callback, fail, userId)
      } else {
        // 关闭共享白板
        this.hstWebEngine.operateShare({
          type: 'close',
          data: media,
          callback,
          fail,
        })
      }
    },
    // 找到要关闭的媒体流
    findCurrentMedia(mid) {
      return this.mediaList.find(midea => {
        const { mediaId, mediaType, userId } = midea
        if (mediaType == 'SCREEN_SHARE') return userId == mid
        return mid == mediaId
      })
    },
    // 切换白板
    switchWhiteBoard(obj, callback) {
      //  同步本地布局权限
      const broadcastOwnLayout = this.broadcastOwnLayout
      const data = Object.assign({}, obj, {
        broadcastOwnLayout,
      })
      this.hstWebEngine.operateShare({
        type: 'switch',
        data,
        callback,
      })
      if( this.localUser.DataState && this.localUser.DataState === 2) { //主持人才同步布局
        // this.hstWebEngine.uploadLayout()
      }
    },
    // 关闭自己所有创建的白板
    handlerAllCreateWhiteboard() {
      console.log('Close all whiteboards you created')
      this.hstWebEngine.operateShare({
        type: 'close',
        mine: true,
        data: { userId: this.localUserId },
        callback: res => {
          console.log(res)
        },
        fail: error => {
          console.log(error)
        },
      })
    },
    // 处理返回的媒体流数据
    cacheShareMedias(obj) {
      const { media, type } = obj
      const layoutOwn = this.broadcastOwnLayout
      const currentMediaId = this.currentShareMediaId

      this.hstWebEngine.cacheShareMedias({
        media,
        layoutOwn,
        currentMediaId,
        type,
      })
    },
  },
}
