<template>
  <div class="video-wrp" ref="vrp">
    <auidoIcon :name="displayName"></auidoIcon>
    <div :class="{ screen_share_no_upright: netFeature && !netFeature.value }">
      <video
        oncontextmenu="return false;"
        ref="videoEl"
        autoplay
        playsinline
        muted
        class="videos"
        style="object-fit: contain"
      ></video>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import auidoIcon from "@/components/hstMedia/audioIcon";
import { objToStrMap } from "@/utils/util";

export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    isControl: {
      type: Boolean,
      default: false,
    },
    mediaType: {
      type: String,
      default: "",
    },
    mediaId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      displayName: "",
      hasVol: false, // 音频图标
      volume: "0",
      userInfo: {},
      callback: null,
      renderuser: {}, // 渲染的用户
      callbakc: null,
    };
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.handleRender(newVal, oldVal);
      },
    },
    groupUserList: {
      immediate: true,
      deep: true,
      handler(newVal) {
        const userList = objToStrMap(newVal);
        if (userList.has(this.user.userId + "")) {
          this.userInfo = userList.get(this.user.userId + "");
          this.displayName =
            this.userInfo.TerminalType !== 16
              ? this.userInfo.DisplayName + "-screen sharing"
              : "screen sharing";
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      "netFeature",
      "currentCam",
      "currentMic",
      "isChangeLayout",
      "hstWebEngine",
      "groupUserList",
      "mediaLayout",
    ]),
  },
  components: { auidoIcon },
  methods: {
    handleRender(newVal, oldVal) {
      if (this.isEmptyObject(this.user)) return;
      this.$nextTick(() => {
        this.render(newVal, oldVal);
      });
    },
    render(newVal, oldVal) {
      // 显示用户名
      console.log("media_share", newVal.userId, newVal.mediaId);
      if (this.isEqualUser(newVal, oldVal)) return;
      if (!this.isEmptyObject(oldVal)) {
        this.unRenderMedia(oldVal);
      }
      // 渲染video标签
      if (!this.isEmptyObject(newVal)) {
        this.callback = this.hstWebEngine.renderRemoteMedia(
          newVal.userId,
          newVal.mediaType,
          newVal.mediaId,
          this.$refs["videoEl"]
        );
      }
    },
    unRenderMedia(user) {
      // 停止接收渲染视频
      if (!this.isEmptyObject(user)) {
        this.hstWebEngine.disposeRemoteMedia(
          user.userId,
          user.mediaType,
          user.mediaId,
          this.$refs["videoEl"],
          this.callback
        );
      }
    },
    isEmptyObject(value) {
      return value === undefined || JSON.stringify(value) === "{}";
    },
    isEqualUser(user, other) {
      if (!this.isEmptyObject(other)) {
        return user.userId === other.userId && user.mediaId === other.mediaId;
      }
      return false;
    },
  },
  beforeDestroy() {
    this.unRenderMedia(this.user);
  },
};
</script>
<style lang="less" scoped>
.video-wrp {
  width: 100%;
  height: 100%;
  position: relative;
  perspective: 500;
  -webkit-perspective: 500;
  .videos {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #222324 url("~@/assets/img/videoloading.gif") no-repeat center;
    box-sizing: border-box;
    box-sizing: border-box;
  }
  .screen_share_no_upright {
    width: 100%;
    height: 100%;
    transform: rotateX(180deg);
  }
  .topbtn {
    position: absolute;
    left: 10px;
    top: 40px;
    z-index: 1000;
    .ant-btn {
      margin-right: 8px;
    }
  }
}
</style>
