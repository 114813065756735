/*
 * @Descripttion: 渲染白板
 * @version:
 * @Author: Terry
 * @Date: 2021-10-28 11:08:30
 * @LastEditors: Terry
 * @LastEditTime: 2021-10-28 11:09:41
 */
import hstWhiteBoard from './white-board'

export default hstWhiteBoard
