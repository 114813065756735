/**
 * node节点是否为指定类名节点
 * @param {*} n node节点
 * @param {*} c 类名
 * @returns 是否
 */
export const isTarget = (n, c) => n && n.classList && n.classList.contains(c);

// 网址正则
export const linkRegex =
  /(http:\/\/[\w\-_]+\.{1}[\w\-_]+|ftp|https:\/\/[\w\-_]+.{1}[\w\-_]+)+([\w\-.,@?^=%&amp;:/~+#]*[\w\-@?^=%&amp;/~+#])?/gi;

// 表情正则
export const emojiRegex =
  /\/:E\d{1,}\((NO|OK|JUS|TOUX|ZAI|FAD|KAF|KU|JIO|HUAIX|DAX|QIANG|DEY|WEIX|XIND|XINS|WOSH|WUYU|YUN|HAN|DAND|ZHUT|SHQI|YIWEN|SE|HUA|KUM|SHUAI|TIAOP|JIUB|NANG|BIZ|FAN|KUL|GUZ|ZAIJ)\)/gi;

// 将文本消息转换为html
export const formatChatMsg = (msg) => {
  msg = msg
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/(\r\n)|(\n)/g,'<br>')
    .replace(/[^\S\n\r\t]/g, "&nbsp;")
  msg = msg.replace(linkRegex, '<a href="$1$2" target="_blank">$1$2</a>');
  return msg.replace(emojiRegex, (key) => {
    let emojiKey = key.replace(/\/:E\d+/, "").toLowerCase();
    const src = require(`./emoji/${emojiKey}.png`);
    return `<img src='${src}'>`;
  });
};

export const formatChatList = (list) => {
  return list.map((e) => {
    if (!e.formated) {
      e.content = formatChatMsg(e.content);
      e.formated = true;
    }
    return e;
  });
};
