
function arrSortMinToMax(list) {
  let numberArr = []; // 以数字开头的字符串
  let cnArr = []; //中文开头的字符串
  let enArr = [];
  let resArr = [];

  list.forEach(item => {
    const firstChar = item.DisplayName.charAt(0);
    if(/^\d+/.test(firstChar)) {
      numberArr.push(item);
    } else if (isChinese(firstChar)) {
      cnArr.push(item);
    } else if (/^[a-zA-Z]+$/.test(firstChar)) {
      enArr.push(item);
    } else {
      resArr.push(item);
    }
  })
  numberArr.sort(function(a,b){
    // 提取起始数字, 然后比较返回
    return /^\d+/.exec(a.DisplayName) - /^\d+/.exec(b.DisplayName);
  })
  cnArr.sort(function(a,b){
    // 按照中文拼音, 比较字符串
    return a.DisplayName.localeCompare(b.DisplayName, 'zh-CN')
  })
  enArr.sort(function(a,b){
    return a.DisplayName.localeCompare(b.DisplayName, 'en');
  })
  resArr.sort(function(a,b){
    return a.DisplayName.localeCompare(b.DisplayName);
  })
  // 昵称按，数字, 英文，中文，其他特殊符号，升序
  return [...numberArr, ...enArr, ...cnArr, ...resArr];
}

// 检测是否为中文，true表示是中文，false表示非中文ß
function isChinese(str) {
  // 中文万国码正则
  if (/[\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d]/.test(str)) {
      return true;
  } else {
      return false;
  }
}

export default arrSortMinToMax
