<template>
  <div class="user-list-audio" @click="changeState">
    <svg-icon class="default-style" :name="audioIconName" ></svg-icon>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { objToStrMap } from '@/utils/util'
export default {
  name: 'AudioStatusX',
  props: {
    user: {
      type: Number,
      default: 0
    },
    device: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      volume: 0
    }
  },
  watch: {
    mediaState: {
      immediate: true,
      deep: true,
      handler (newVal) {
        const volMap = objToStrMap(newVal)
        if (this.user && volMap.has(this.user + '')) {
          this.volume = volMap.get(this.user + '').state
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'hstWebEngine',
      'localUser',
      'mediaState',
      'roleAuth',
    ]),
    //0:关闭;1:申请发言;2:已发言
    audioIconName: function() {
      const icon = new Map([
        [0, 'userlist_audio_closed'],
        [1, 'userlist_audioApplying_hot'],
        [2, 'userlist_audio'],
      ])
      const iconName = `#icon-${icon.get(this.device.State)}`;
      if (this.device.State === 2 && this.volume > 0) {
        if (Math.round(Number(this.volume)/5) === 0) {
          return '#icon-userlist_audio';
        }
        return '#icon-userlist_speaking' + Math.round(Number(this.volume)/5);
      }
      return iconName;
    }
  },
  methods: {
    ...mapActions(['SetAudioStatus', 'SetLocalPubAudio']),
    changeState() {
      // 判断是操作自己，还是别人
      if (this.localUser.UserID === this.user) {
        this.changeSelfState();
        return;
      }
      this.changeOthersState();
    },
    changeSelfState(){
      //0:关闭;1:申请发言;2:已发言
      if (this.device.State === 0) {
        this.hstWebEngine.broadcastAudio({ mediaId: '0' }) // 这里只用默认的麦克风广播
        return
      }
      this.SetLocalPubAudio(false)
      if (this.roleAuth.BroadcastOwnAudio === '0' || this.roleAuth.BroadcastOwnAudio === undefined) {
        this.SetAudioStatus(3)
      } else {
        // 无需权限
        this.SetAudioStatus(2)
      }
      this.hstWebEngine.unbroadcastAudio({ mediaId: 0 })
    },
    changeOthersState(){
      // 判断是否有权限： 仅管理员或主持人有权限操作他人音视频
      if (this.localUser.DataState !== 2 && this.localUser.ManagerState !== 2) {
        this.$toast('You do not have permission to operate, please apply for host or administrator permissions');
        return;
      }
      //0:关闭;1:申请发言;2:已发言
      if (this.device.State === 2) {
        this.hstWebEngine.unbroadcastAudio({ mediaId: 0, userId: this.user })
        return
      }
      // 管理员开启他人音频
      this.hstWebEngine.boradOthersMedia({ mediaId: 0, userId: this.user}, "Audio", (direct)=> {
        if(direct) return;
        this.$notification.success(
          {
            placement: 'bottomRight',
            bottom: '50px',
            duration: 3,
            message: "The operation has been successful, waiting for the other party's consent"
          }
        );
      });
    }
  }
}
</script>
<style lang="less" scoped>
.user-list-audio{
  .svg-icon {
    font-size: 36px;
  }
}
.audio-status {
  height: 60px;
  cursor: pointer;
  padding-top: 4px;
}

</style>
