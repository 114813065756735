<!--
 * @Descripttion: 共享白板工具
 * @version:
 * @Author: Terry
 * @Date: 2021-10-29 11:55:32
 * @LastEditors: Terry
 * @LastEditTime: 2021-11-29 20:07:43
-->
<template>
  <div
    class="tool-share-wrap"
    :class="{
      'is-full': screenfull,
      'all-show': allShow,
      'transion-out': transionOut,
      'chat-show': chatShow,
    }"
  >
    <div id="---toolShare---" class="tool-share">
      <div class="share-close tool-item" @click="stopShare">Stop sharing</div>
      <template v-if="!hideForTest">
        <div class="share-switch tool-item" @click="showModal">
          <switch-share-icon></switch-share-icon>
          <span class="total-num" :class="{ 'item-num': num > 9 }">
            {{ num }}</span
          >
        </div>
        <div v-show="showMark" class="share-add tool-item">
          <add-board-share-icon @click="createBoard"></add-board-share-icon>
        </div>
        <div v-show="showMark" class="share-show tool-item" @click="toolClick">
          <show-tool-share-icon v-if="!toolShow"></show-tool-share-icon>
          <show-tool-click-share-icon
            v-if="toolShow"
          ></show-tool-click-share-icon>
        </div>
        <!-- <div class="share-roate tool-item">
          <roate-tool-icon></roate-tool-icon>
        </div> -->
      </template>
      <div class="share-fix tool-item" @click="fixClick">
        <fix-tool-icon v-if="!fixShow"></fix-tool-icon>
        <fix-hot-tool-icon v-if="fixShow"></fix-hot-tool-icon>
      </div>

      <!-- 切换白板弹窗 -->
      <modal-board
        ref="modalBoard"
        v-model="show"
        :position="position"
        @on-switch="onSwitch"
        @on-switch-layout="switchLayout"
      ></modal-board>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  switchShareIcon,
  addBoardShareIcon,
  showToolShareIcon,
  showToolClickShareIcon,
  roateToolIcon,
  fixToolIcon,
  fixHotToolIcon,
} from '@/core/icons' // 图标引入
import ModalBoard from './modal-board'
import whiteBoardMixins from '@/views/meeting/whiteBoardMixins'

export default {
  name: 'ToolShare',
  components: {
    switchShareIcon,
    addBoardShareIcon,
    showToolShareIcon,
    showToolClickShareIcon,
    roateToolIcon,
    fixToolIcon,
    fixHotToolIcon,
    ModalBoard,
  },
  mixins: [whiteBoardMixins],
  props: {
    // 共享类型，默认白板
    type: {
      type: String,
      default: 'whiteBoard',
    },
    // 临时配置属性，因为共享桌面不需要这些按钮
    hideForTest: {
      type: Boolean,
      default: false,
    },
    // 标注
    markShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // 固定
      fixShow: false,
      // 显示划线工具
      toolShow: false,
      // 切换白板弹窗
      show: false,
      // 共享按钮工具条显隐
      transionOut: false,
      // 共享按钮工具条是否一直显示
      allShow: false,
    }
  },
  computed: {
    ...mapGetters([
      'hstWebEngine',
      'mediasMap',
      'currentShareMediaId',
      'browserScreenfull',
      'localUser',
      'roleAuth',
    ]),

    // 聊天窗口是否开启
    chatShow() {
      return this.$store.state.chat.chatShow
    },
    // 白板的数量
    num() {
      return (
        this.mediasMap['WHITE_BOARD'].length +
        this.mediasMap['SCREEN_SHARE'].length
      )
    },
    // 是否全屏
    screenfull() {
      return this.browserScreenfull
    },
    // 切换白板弹窗位置
    position() {
      return this.browserScreenfull ? 'top' : 'bottom'
    },
    // 是不是共享白板
    isShareWhiteBoard() {
      return this.type === 'whiteBoard'
    },
    // 标注
    showMark() {
      return this.markShow
    },
    isMine() {
      let flag = false
      this.whiteBoardDatas.map(o => {
        const { userId, mediaId } = o
        if (userId == this.localUserId && mediaId == this.currentShareMediaId) {
          flag = true
        }
      })

      return flag
    },
    localUserId() {
      return this.localUser.UserID
    },
    // 白板数据的改变
    whiteBoardDatas() {
      return this.mediasMap['WHITE_BOARD']
    },
    markWhiteboard() {
      const { MarkWhiteboard } = this.roleAuth
      return MarkWhiteboard == 1
    },
  },
  watch: {
    markWhiteboard(newVal, oldVal) {
      // 不是自己创建的白板，没有标注权限
      if (!this.isMine && !newVal) {
        this.toolShow = false
      }
    },
  },
  created() {},
  mounted() {
    this.mouseEvent()
    this.mouseAddEventListener()
  },
  methods: {
    resetStatus () { // 重置状态
      this.toolShow = false
    },
    init(boolean) {
      this.toolShow = boolean
    },
    // 监听鼠标事件
    mouseAddEventListener() {
      document.querySelector('body').addEventListener('mousemove', ev => {
        const y = ev.clientY
        const clientHeight = window.innerHeight
        const h = clientHeight - y
        // 是否一直显示，要看图钉是否展示一直固定
        this.allShow = this.show ? this.show : this.fixShow
        // 非全屏状态
        if (!this.screenfull && h > 60 && h < 120) {
          this.allShow = true
          !this.transionOut && this.mouseEvent()
        }
        // 全屏状态
        if (this.screenfull && y > 20 && y < 130) {
          this.allShow = true
          !this.transionOut && this.mouseEvent()
        }
      })
    },
    // 鼠标显隐定时器
    mouseEvent() {
      this.transionOut = true
      setTimeout(() => {
        this.transionOut = false
      }, 3000)
    },
    // 显示划线工具显隐
    setToolShow() {
      this.toolShow = false
      this.toolClick()
    },
    // 标注 显示划线
    toolClick() {
      if (!this.markWhiteboard && !this.isMine) {
        this.$message.warn('You do not have permission to operate')
        return
      }
      this.toolShow = !this.toolShow
      this.$emit('on-show-tool', this.toolShow)
    },
    // 显示白板列表
    showModal() {
      this.show = true
    },
    // 固定
    fixClick() {
      this.fixShow = !this.fixShow
    },
    // 创建白板
    createBoard() {
      console.log('Create directly')
      // from whiteBoardMixins.js
      this.createWhiteBoard(null, obj => {
        console.log(obj)
      })
    },
    // 切换白板没有权限同步布局
    switchLayout(m) {
      if (!m) return
      this.$emit('on-switch-no-layout', m)
    },
    // 切换共享白板
    onSwitch(board) {
      this.$emit('on-switch', board)
    },
    // 停止共享白板
    stopShare() {
      if (this.isShareWhiteBoard) {
        this.$refs.modalBoard.closeBoard({ mediaId: this.currentShareMediaId })
      } else {
        this.$emit('on-stop-screen-share')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.tool-share-wrap {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0px;
  justify-content: center;
  display: flex;
  transition: all 0.3s;
  opacity: 0;
  z-index: 998;
  &.is-full {
    top: 78px;
    position: fixed;
    left: 10%;
    width: 80%;
    &.chat-show {
      width: calc(80% - 360px);
    }
  }
  &.transion-out {
    opacity: 1;
    transition: all 0.3s;
  }
  &.all-show {
    opacity: 1;
    transition: all 0.3s;
  }
  .tool-share {
    // width: 307px;
    height: 40px;
    background: #32374a;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
    opacity: 0.96;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    bottom: 10px;
    .tool-item {
      float: left;
      margin-right: 20px;
      padding-top: 2px;
      cursor: pointer;
    }
    .share-close {
      width: 80px;
      height: 24px;
      background: #f45b5b;
      border-radius: 4px;
      line-height: 24px;
      text-align: center;
      color: #fff;
      font-weight: 400;
      padding-top: 0px;
    }
    .share-switch {
      // width: 80px;
      height: 24px;
      position: relative;
      .total-num {
        position: absolute;
        top: 4px;
        left: 3px;
        color: #000;
        font-weight: bold;
        font-size: 12px;
        &.item-num {
          top: 5px;
          left: -1px;
        }
      }
    }
    .share-fix {
      margin-right: 0;
    }
  }
}
</style>
