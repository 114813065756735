<template>
  <a-modal
    class="no-select"
    v-model="shareModalShow"
    :maskClosable="false"
    title="Select a shared content"
    :width="480"
    centered
  >
    <div class="share-content">
      <div
        class="share-item"
        v-for="item in shareContents"
        :key="item.id"
        @click="selectedIndex = item.id"
        @dblclick="handleShareClick(item.id)"
      >
        <div :class="{ overview: true, selected: selectedIndex === item.id }">
          <img :src="item.icon" alt="icon" />
        </div>
        <div class="title">{{ item.title }}</div>
      </div>
    </div>
    <template #footer>
      <a-button class="share-btn" @click="handleShareClick()">Start sharing</a-button>
    </template>
  </a-modal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ShareModal",
  data() {
    return {
      selectedIndex: 0,
      shareModalShow: false,
      shareContents: [
        {
          id: 0,
          title: "Desktop",
          icon: require("@/assets/img/icon-share-desktop.png"),
        },
        {
          id: 1,
          title: "White board",
          icon: require("@/assets/img/icon-share-wb.png"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["mediasMap", "localUser"]),

    localUserId() {
      return this.localUser.UserID;
    },
    // 白板数据的改变
    whiteBoardDatas() {
      return this.mediasMap["WHITE_BOARD"]; // 白板数据
    },

    // 共享屏幕数据
    screenShareDatas() {
      return this.mediasMap["SCREEN_SHARE"];
    },

    // 判断当前渲染的白板是否是自己共享
    onlyOwnShareBoard() {
      const findObj = this.whiteBoardDatas.find(media => media.userId != this.localUserId)
      return this.whiteBoardDatas.length > 0 && !findObj
    },
    // 判断当前渲染的共享屏幕是不是自己共享
    onlyOwnShareScreen() {
      const findObj = this.screenShareDatas.find(media => media.userId != this.localUserId)
      return this.screenShareDatas.length > 0 && !findObj
    }
  },
  watch: {
    shareModalShow(value) {
      value && (this.selectedIndex = 0)
    }
  },
  methods: {
    handleShareClick(id) {
      id !== undefined && (this.selectedIndex = id);
      if(this.selectedIndex == 0 && ( this.onlyOwnShareBoard || this.onlyOwnShareScreen)) {
        this.$message.warn('You are sharing, please end the current share first')
        return
      }

      if(this.selectedIndex == 1 && this.onlyOwnShareScreen) {
        this.$message.warn('You are sharing, please end the current share first')
        return
      }

      this.$emit("on-share-confirm", this.selectedIndex);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-modal-body {
  padding: 40px;
  background: #f6f7f9;
}
.share-btn {
  background: #5aa4f7;
  border-color: #5aa4f7;
  color: #fff;
}
.share-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .share-item {
    cursor: pointer;
    &:hover {
      .overview {
        border: 1px solid #3c98fe;
      }
    }
    .overview {
      width: 180px;
      height: 100px;
      border-radius: 3px;
      background: #fff;
      border: 1px solid #dee1e5;
      display: flex;
      justify-content: center;
      align-items: center;
      &.selected {
        border: 2px solid #3c98fe;
      }
    }
    .title {
      margin-top: 5px;
      text-align: center;
    }
  }
}
</style>
