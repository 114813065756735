<template>
  <div :class="[layout === 1 ? 'fcl' : 'pip']">
    <div
      class="screen-wrapper"
      :style="{ width: layout === 1 ? mainWith : '100%' }"
    >
      <div
        class="pre-page page-btn"
        v-if="videoStart > 0"
        @click="moveVideoToPre"
      >
        <a-icon type="left" />
      </div>
      <div
        class="next-page page-btn"
        v-if="videoEnd < restVideosLength"
        @click="moveVideoToNext"
      >
        <a-icon type="right" />
      </div>
      <div
        :class="[index === 0 ? 'video-big' : 'screen-min']"
        :style="{
          width: index === 0 ? (layout === 1 ? mainWith : '') : minWith,
        }"
        v-for="(item, index) in displayingVideos"
        :key="`${item.userId}_${item.mediaId}`"
      >
        <hstVideo
          ref="video1"
          :user="item"
          :isBig="index === 0"
          :videoSize="videoSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import clonedeep from "lodash.clonedeep";
import auidoIcon from "@/components/hstMedia/audioIcon";
import hstVideo from "@/components/hstMedia/hstVideo";
import { throttle } from "@/utils/util";

// 每页视频的数量
const VIDEO_PAGE_SIZE = 4;

export default {
  name: "FocusLayout",
  components: { auidoIcon, hstVideo },
  props: {
    videoDatas: {
      type: Array,
      default: () => [],
    },
    videoLocations: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      fullHeight: 800,
      fullWidth: 600,
      mainWith: "200px",
      minWith: "40px",
      videoSize: {
        leftBlack: false,
        videoWidth: 0,
        maxIconWidth: 200,
      },
      videoStart: 0,
      videoEnd: VIDEO_PAGE_SIZE,
    };
  },
  computed: {
    ...mapGetters(["browserScreenfull"]),
    layout() {
      return this.videoDatas.length > 2 ? 1 : 0;
    },
    displayingVideos() {
      let videos = clonedeep(this.videoDatas);
      videos.map((item1) => {
        let curPos = -1
        this.videoLocations.map((item) => {
          let flag = item.id === item1.userId && item.mediaId === parseInt(item1.mediaId)
          if (flag) {
            curPos = item.pos
          }
        })
        item1.pos = curPos
        return item1
      })
      videos.sort((a, b) => { // 按视频位置排序
        return a.pos - b.pos
      })
      if (videos.length <= 2) {
        return videos;
      }
      const [firstVideo, ...restVideos] = videos;
      return [firstVideo, ...restVideos.slice(this.videoStart, this.videoEnd)];
    },
    chatShow() {
      return this.$store.state.chat.chatShow;
    },
    restVideosLength() {
      return this.videoDatas.length - 1;
    },
  },
  watch: {
    browserScreenfull() {
      // 浏览器全屏状态变化重新计算窗口大小
      this.calcVideoSize();
    },
    chatShow() {
      this.calcVideoSize();
    },
    videoDatas: {
      deep: true,
      handler() {
        // 视频数量变化要重新计算下视频的宽高
        this.$nextTick(() => {
          this.calcVideoSize();
        });
      },
    },
    restVideosLength(value) {
      if (value <= VIDEO_PAGE_SIZE) {
        this.videoStart = 0;
        this.videoEnd = value;
      } else {
        const expectEnd = this.videoStart + VIDEO_PAGE_SIZE;
        if (expectEnd > value) {
          this.videoEnd = value;
          this.videoStart = value - VIDEO_PAGE_SIZE;
        } else {
          this.videoEnd = expectEnd;
        }
      }
    },
  },
  created() {
    this.resetVideoPageNum();
  },
  mounted() {
    this.calcVideoSize();
    window.onresize = throttle(() => {
      // 窗口缩放时，节流调整大小窗口的宽度
      // 退出全屏时，先执行了onresize,再执行了浏览器全屏变化,需要监听再计算
      this.calcVideoSize();
    }, 200);
  },
  methods: {
    calcVideoSize() {
      // 计算布局内视频的宽度
      const docHeight = document.documentElement.clientHeight; // 浏览器显示区域高度
      const videoRatio = 1.7777777; // 16：9的比例
      this.fullHeight = this.browserScreenfull ? docHeight : docHeight - 90;
      this.fullWidth = document.querySelector(
        ".logined-meeting-content .left-part"
      ).offsetWidth;
      const dataRatio = (this.fullWidth / this.fullHeight).toFixed(6); // 显示区域的比例

      const pipWidth = ((this.fullHeight * 16) / 9).toFixed(2); // 画中画时的视频宽度
      this.mainWith = (pipWidth * 4) / 5 + "px"; // 焦点时大视频的宽度
      this.minWith = pipWidth / 5 + "px"; // 焦点时小视频的宽度
      // 计算传递给显示视频组件的参数
      this.videoSize.maxIconWidth = pipWidth / 5 - 20; // 小窗口用户名的显示最大宽度
      this.videoSize.leftBlack = dataRatio >= videoRatio; // 左右黑边还是上下黑边
      // 计算内部video的宽度
      this.videoSize.videoWidth =
        dataRatio >= videoRatio
          ? (this.videoSize.videoWidth =
              this.layout === 0 ? pipWidth + "px" : (pipWidth * 4) / 5 + "px")
          : "100%";
    },
    // 向上翻页
    moveVideoToPre() {
      if (this.videoStart - VIDEO_PAGE_SIZE <= 0) {
        this.videoStart = 0;
        this.videoEnd = VIDEO_PAGE_SIZE;
      } else {
        this.videoStart -= VIDEO_PAGE_SIZE;
        this.videoEnd -= VIDEO_PAGE_SIZE;
      }
    },
    // 向下翻页
    moveVideoToNext() {
      const rvl = this.restVideosLength;
      if (this.videoEnd + VIDEO_PAGE_SIZE > rvl) {
        this.videoEnd = rvl;
        this.videoStart = rvl - VIDEO_PAGE_SIZE;
      } else {
        this.videoStart += VIDEO_PAGE_SIZE;
        this.videoEnd += VIDEO_PAGE_SIZE;
      }
    },
    resetVideoPageNum() {
      const rvl = this.restVideosLength;
      this.videoStart = 0;
      this.videoEnd = rvl < VIDEO_PAGE_SIZE ? rvl : VIDEO_PAGE_SIZE;
    },
  },
  beforeDestroy() {
    window.onresize = null;
  },
};
</script>
<style lang="less" scoped>
.fcl {
  width: 100%;
  height: 100%;
  position: relative;
  .screen-wrapper {
    height: 20%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    .page-btn {
      width: 18px;
      height: 50%;
      background: #32374a;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 20;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
    .pre-page {
      left: 0;
    }
    .next-page {
      right: 0;
    }
    .video-big {
      position: absolute;
      top: -400%;
      height: 400%;
      width: 100%;
      z-index: 1;
      border: 1px solid #454d5c;
    }
    .screen-min {
      width: 218px;
      height: 100%;
      text-align: center;
      background: #454d5c;
      border: 1px solid #454d5c;
      position: relative;
    }
  }
}
.pip {
  width: 100%;
  height: 100%;
  position: relative;
  .video-big {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
  .screen-min {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 384px;
    height: 20%;
    text-align: center;
    border: 1px solid #ffffff;
    box-shadow: 0px 6px 16px 0px rgba(50, 55, 74, 0.5);
    z-index: 10;
  }
}
</style>
