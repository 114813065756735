<template>
  <div class="clearfix no-select">
    <div class="user-item fl audio">
      <div class="layout-status">
        <div class='user-num'>{{ Object.keys(validUserList).length > 999 ? '999+' : Object.keys(validUserList).length }}</div>
        <div :class="['f-icons', active ? 'active' : '']">
          <tool-bar-user-icon class="icon"></tool-bar-user-icon>
        </div>
        <div class="actions">Users</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { toolBarUserIcon } from "@/core/icons"; // 布局图标引入

export default {
  name: "ToolBarUser",
  components: { toolBarUserIcon },
  props: {
    active: Boolean,
  },
  computed: {
    ...mapGetters(["validUserList"])
  }
};
</script>

<style lang="less" scoped>
.layout-status {
  padding-top: 4px;
  height: 60px;
  cursor: pointer;
  position: relative;

  .user-num {
    position: absolute;
    left: 40px;
    top: 6px;
    font-size: 12px;
    background: #9aa0ba;
    color: #fff;
    border-radius: 8px;
    padding: 0 5px;
    z-index: 100;
    line-height: 16px;
  }
}
.f-icons {
  margin: 0 auto;
  width: 34px;
  height: 34px;
  position: relative;
  text-align: center;

  &.active {
    color: #5aa4f7;
  }

  .icon {
    width: 32px;
    height: 32px;
  }
  .actions {
    line-height: 16px;
  }
}
.user-item {
  width: 64px;
  height: 60px;
  font-size: 12px;
  line-height: 16px;
  background-size: 64px 60px;
  color: #c8d7e6;
  text-align: center;
  &:hover {
    background: #434a63;
  }
}
</style>
