<!--
 * @Descripttion: 未全屏时的底部工具栏
 * @version:
 * @Author: Terry
 * @Date: 2021-10-26 09:59:23
 * @LastEditors: Terry
 * @LastEditTime: 2021-10-26 15:37:54
-->
<template>
  <div class="meeting-footer flex flexSb">
    <!-- 底部工具栏 -->
    <div class="footer-left">
      <div class="tool-c">
        <audio-status
          :micList="devicesList.micDevs"
          ref="toolAudio"
          :spkList="devicesList.spkDevs"
          @changeMic="changeMic"
          @downHand="audioDownHand"
        ></audio-status>
      </div>
      <div class="tool-c">
        <video-status
          :camList="devicesList.camDevs"
          ref="toolVideo"
          :resList="resolutionList"
          @downHand="videoDownHand"
        ></video-status>
      </div>
      <div class="tool-c" @click="showShareModal">
        <share-btn :active="isSelfShare"></share-btn>
      </div>
      <div @click="toLayoutScreenfull">
        <!-- <div @click="getDeviceList"> -->
        <layout-status> <!--布局按钮,焦点视频全屏的效果--></layout-status>
      </div>
    </div>
    <div class="footer-right">
      <invite-btn
        :active="inviteShow"
        v-if="!isAnonymousJoin"
        @click.native="openInviteModal"
      />
      <user
        :active="userListShow"
        @click.native="SET_USER_LIST_SHOW(!userListShow)"
      ></user>
      <!-- <video-status
          :camList="devicesList.camDevs"
          ref="toolVideo"
          :resList="resolutionList"
          @downHand="videoDownHand"
        ></video-status> -->
      <record-status
        :status="cloudRecordStatus"
        @onChange="operateCloudRecord"
      ></record-status>
      <chat-btn :active="chatShow" @click.native="SET_CHAT_SHOW(!chatShow)" />
      <exit-client class="exit-btn" @click.native="exit"></exit-client>
    </div>
    <!-- <a-modal
      v-model="loginFrequencyVisible"
      :maskClosable="false"
      title="入会提示"
      :width="360"
      centered
      @cancel="closeLoginFrequency"
      :footer="null"
    >
      <div class="nt-container">
        <div class="nt-item">{{ loginFrequencyNessage }}</div>
        <div class="nt-footer">
          <a-button
            type="primary"
            @click="closeLoginFrequency"
            class="sure-btn"
            size="small"
            >确定</a-button
          >
        </div>
      </div>
    </a-modal> -->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import audioStatus from '@/components/toolBar/audioStatus'
import videoStatus from '@/components/toolBar/videoStatus'
import layoutStatus from '@/components/toolBar/layoutStatus'
import exitClient from '@/components/toolBar/exitClient'
import ShareBtn from '@/components/toolBar/shareBtn'
import ChatBtn from '@/components/toolBar/chatBtn'
import user from '@/components/toolBar/user'
import inviteBtn from '@/components/toolBar/inviteBtn'
import recordStatus from'@/components/toolBar/recordStatus'

export default {
  name: 'ToolbarBottom',
  components: {
    audioStatus,
    videoStatus,
    layoutStatus,
    exitClient,
    ShareBtn,
    ChatBtn,
    user,
    inviteBtn,
    recordStatus
  },
  props: {
    devices: {
      type: [Object],
      default: () => {},
    },
    resolution: {
      type: [Array],
      default: () => {},
    },
    isSelfShare: {
      type: Boolean,
      default: false,
    },
    isAnonymousJoin: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      devicesList: {},
    }
  },
  computed: {
    ...mapGetters(['chatShow', 'userListShow', 'inviteShow', 'roleAuth', 'cloudRecordStatus', 'hstWebEngine', 'localUser']),
    resolutionList() {
      if (!this.resolution) return []

      return this.resolution
    },
  },
  watch: {
    devices: {
      handler() {
        this.handleDeviceList()
      },
    },
  },
  created() {
    this.handleDeviceList()
  },
  methods: {
    ...mapMutations(['SET_CHAT_SHOW', 'SET_USER_LIST_SHOW', 'SET_INVITE_SHOW']),
    operateCloudRecord (status) {
      if (status) {
        if ((this.localUser && this.localUser.ManagerState == 2 && this.localUser.Right == 3) || this.localUser.DataState === 2) {
          this.$confirm({
            title: 'The current meeting is not over, are you sure you want to turn off cloud recording?',
            onOk: () => {
              this.hstWebEngine.stopRecord()
            }
          })
        } else {
          this.$info({
            title: 'Only the host or administrator can operate cloud recording',
            okText: 'Got it',
          })
        }
      } else {
        if ((this.localUser && this.localUser.ManagerState == 2 && this.localUser.Right == 3) || this.localUser.DataState === 2) {
          this.hstWebEngine.startRecord()
        } else {
          this.$info({
            title: 'Only the host or administrator can operate cloud recording',
            okText: 'Got it',
          })
        }
      }
    },
    // 设备列表
    handleDeviceList() {
      const { micDevs = [], camDevs = [], spkDevs = [] } = this.devices
      this.devicesList = { micDevs, camDevs, spkDevs }
    },
    // 开关音频
    changeMic() {
      this.$emit('on-change-audio')
    },
    // 下载音频
    audioDownHand() {
      this.$emit('on-down-audio')
    },
    // 下载视频
    videoDownHand() {
      this.$emit('on-down-video')
    },
    // 布局
    toLayoutScreenfull() {
      this.$emit('on-layout-click')
    },
    // 开启视频
    openVideo() {
      this.$nextTick(() => {
        this.$refs.toolVideo.openVideo()
      })
    },
    // 开启音频
    openAudio() {
      this.$nextTick(() => {
        this.$refs.toolAudio.openAudio()
      })
    },
    // 退出
    exit() {
      this.$emit('on-exit')
    },
    // 创建版本
    createBoard() {},
    // 打开共享
    showShareModal() {
      this.$emit('on-show-share')
    },
    // 打开通讯录邀请
    openInviteModal() {
      const { OnlineInvitation } = this.roleAuth
      if (parseInt(OnlineInvitation) === 1) {
        this.SET_INVITE_SHOW(true)
      } else {
        this.$toast('You do not have permission to operate')
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/utils/utils.less';

.meeting-footer {
  padding: 0 20px;
  height: 60px;
  background: @primary-color;

  .footer-left {
    display: flex;
    height: 60px;
    flex-direction: row;
    color: #c7d4e8;
  }

  .footer-right {
    display: flex;
    height: 60px;

    .exit-btn {
      font-size: 12px;
      color: #f45b5b;
      text-align: center;
      cursor: pointer;
      line-height: 16px;

      &:hover {
        background: #434a63;
      }
    }
  }
}
</style>
