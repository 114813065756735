<!--
 * @Descripttion: 白板渲染
 * @version:
 * @Author: Terry
 * @Date: 2021-10-28 11:08:23
 * @LastEditors: Terry
 * @LastEditTime: 2021-11-29 20:48:11
-->
<template>
  <div class="whiteWrapper">
    <!-- 当前渲染白板名称 -->
    <auidoIcon v-if="whiteBoardName" :name="whiteBoardName"></auidoIcon>
    <!-- 当前渲染白板容器 -->
    <div ref="canvas" class="canvas white-wrapper"></div>
    <!-- 左侧划线工具 -->
    <tool-scribing
      ref="toolScribing"
      v-model="toolLineShow"
      @on-close="handleClose"
    ></tool-scribing>
    <!-- 操作工具 -->
    <tool-share
      ref="toolShare"
      @on-show-tool="handleLineShow"
      @on-switch-no-layout="onSwitch"
    ></tool-share>
  </div>
</template>

<script>
import auidoIcon from '@/components/hstMedia/audioIcon'
import { mapGetters, mapMutations } from 'vuex'
import { objToStrMap } from '@/utils/util'
import { ToolShare, ToolScribing } from './components'
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    mediaType: {
      type: String,
      default: '',
    },
    mediaId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      displayName: '',
      boardname: '',
      hasVol: false, // 音频图标
      volume: '0',
      callback: null,
      userInfo: {},
      currentBoard: null,
      toolLineShow: false,
    }
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        // console.log(newVal);
        this.handleRender(newVal, oldVal)
      },
    },
    // groupUserList: {
    //   immediate: true,
    //   deep: true,
    //   handler(newVal) {
    //     const userList = objToStrMap(newVal);
    //     if (userList.has(this.user.userId + "")) {
    //       this.userInfo = userList.get(this.user.userId + "");
    //       console.log(this.userInfo)
    //       this.displayName = this.userInfo.DisplayName;
    //     }
    //   }
    // },
    // 监听标准权限 当标注权限被关闭时，关闭当前白板标注
    markWhiteboard: {
      handler(newValue, oldValue) {
        oldValue != newValue &&
          newValue == 0 &&
          this.handlerMarkWhiteboard(false)
      },
    },
  },
  components: {
    auidoIcon,
    ToolShare,
    ToolScribing,
  },
  computed: {
    ...mapGetters([
      'hstRtcEngine',
      'hstWebEngine',
      'groupUserList',
      'mediaLayout',
      'roleAuth',
      'localUser',
      'mediasMap',
      'currentShareMediaId',
    ]),
    // 白板数据的改变
    whiteBoardDatas() {
      return this.mediasMap['WHITE_BOARD']
    },
    whiteBoardName() {
      if (this.displayName) {
        return this.displayName + '-' + this.boardname
      } else {
        return this.boardname
      }
    },
    // 标注权限
    markWhiteboard() {
      console.log(this.roleAuth)
      const { MarkWhiteboard } = this.roleAuth
      return MarkWhiteboard == 1
    },
    localUserId() {
      return this.localUser.UserID
    },
    isMine() {
      let flag = false
      this.whiteBoardDatas.map(o => {
        const { userId, mediaId } = o
        if (userId == this.localUserId && mediaId == this.currentShareMediaId) {
          flag = true
        }
      })

      return flag
    },
  },
  mounted() {},
  methods: {
    ...mapMutations(['SET_CURRENT_SHARE_MEDIA_ID']),

    handleRender(newVal, oldVal) {
      if (this.isEmptyObject(this.user)) return
      this.$nextTick(() => {
        this.render(newVal, oldVal)
      })
    },
    render(newBoard, oldBoard) {
      // 同一个白板
      if (this.isEqualBoard(newBoard, oldBoard)) return
      // 没有白板
      if (!this.isEmptyObject(oldBoard)) {
        this.unRnderBoard(oldBoard)
      }
      // 新的白板
      if (
        !this.isEmptyObject(newBoard) &&
        !this.isEqualBoard(newBoard, oldBoard)
      ) {
        this.renderBoard(newBoard)
        this.$refs.toolShare.resetStatus() // 重置选中状态
      }
    },
    // 没有同步布局权限时 本地切换白板
    onSwitch(newBoard) {
      if (!newBoard) return

      const { mediaType } = newBoard
      mediaType === 'WHITE_BOARD' && this.renderBoard(newBoard)
    },
    // 白板标注按钮Action
    handleLineShow(boolean) {
      if (!this.markWhiteboard && !this.isMine) {
        this.$message.warn('您暂无权限操作')
        return
      }
      this.handlerMarkWhiteboard(boolean)
    },
    // 同步标记状态
    handlerMarkWhiteboard(boolean) {
      this.toolLineShow = boolean

      this.hstWebEngine.operateShare({
        type: 'edit',
        data: Object.assign({}, this.currentBoard, { editType: boolean }),
        callback: editStatus => {
          this.toolLineShow = editStatus
          if (editStatus) {
            this.$refs.toolScribing.selectClick(
              'pencilIconShowIcon',
              true,
              'curve'
            )
          }
        },
      })
    },
    // 关闭左侧划线工具
    handleClose() {
      this.handlerMarkWhiteboard(false)
      this.$refs.toolShare.init(false)
    },
    // 创建白板的用户名称
    showCreateWhiteBoardUserName(userId) {
      const userList = objToStrMap(this.groupUserList)
      if (userList.has(userId + '')) {
        this.userInfo = userList.get(userId + '')
        this.displayName = this.userInfo.DisplayName
      }
    },
    // 渲染白板
    renderBoard(board) {
      // 隐藏标注工具栏
      this.toolLineShow = false
      this.currentBoard = board
      const { mediaType, mediaId } = board
      const findBoard = this.whiteBoardDatas.find(o => o.mediaId == mediaId)

      const { userId = '' } = findBoard
      this.callback = this.hstWebEngine.renderRemoteMedia(
        userId,
        mediaType,
        mediaId,
        this.$refs['canvas'],
        data => {
          const { isDocument, curPage, mediaName, totalPage } = data
          
          const name = `${mediaName}(${parseInt(curPage + 1)}/${totalPage})`
          // 共享文档需要获取文档后缀，文档页码
          this.boardname = isDocument ? name : mediaName
          this.showCreateWhiteBoardUserName(userId)
          // 监听白板页码变化
          this.hstRtcEngine.subEvent('onWhiteBoardCurPageChanged', curdata => {
            const name = `${mediaName}(${parseInt(
              curdata.curPage + 1
            )}/${totalPage})`
            this.boardname = isDocument ? name : mediaName
            this.showCreateWhiteBoardUserName(userId)
          })
          this.SET_CURRENT_SHARE_MEDIA_ID(mediaId)
          console.log('渲染白板成功', data)
          // 有权限标注，调用标记白板
          if (this.markWhiteboard || this.localUserId == userId) {
            this.handlerMarkWhiteboard(true)

            this.$refs.toolShare.init(true)
            this.$refs.toolScribing.init(true)
          }
        }
      )
    },

    // 停止接收渲染白板
    unRnderBoard(user) {
      if (!this.isEmptyObject(user)) {
        this.hstWebEngine.disposeRemoteMedia(
          user.userId,
          user.mediaType,
          user.mediaId,
          this.$refs['canvas'],
          this.callback
        )
      }
    },
    isEmptyObject(value) {
      return value === undefined || JSON.stringify(value) === '{}'
    },
    isEqualBoard(newBoard, oldBoard) {
      if (!this.isEmptyObject(oldBoard)) {
        return newBoard.mediaId === oldBoard.mediaId
      }
      return false
    },
  },
  // 销毁
  beforeDestroy() {
    this.user && this.unRnderBoard(this.user)
  },
}
</script>

<style lang="less" scoped>
.whiteWrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  // background: #fff;
  canvas {
    background: #fff;
  }
  .canvas {
    width: 100%;
    height: 100%;
    background: #e9eaee;
    // background: #272829 url('~@/assets/img/videoloading.gif') no-repeat center;
  }
  .text {
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
    padding: 0 0.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 1.5rem;
    height: 0.42rem;
    background: #000000;
    opacity: 0.6;
    border-radius: 0.21rem;
    font-size: 0.24rem;
    line-height: 0.42rem;
    text-align: center;
    color: #9aa0a6;
    z-index: 1;
  }
  .loading {
    width: 0.6rem;
    height: 0.6rem;
    // background-image: url(~assets/loading.png);
    background-size: cover;
    animation: rotating 1.6s linear infinite;
  }
}
</style>
