<template>
  <div class="share-content">
    <div
      :class="{ 'no-share': true, 'share-self': shareSelfScreen }"
      v-if="!shareData || !shareData.mediaType || sharingSelfScreen"
    >
      <template v-if="sharingSelfScreen">
        <img src="@/assets/img/share-bg.png" alt="" />
        <div class="message">Sharing a desktop</div>
      </template>
      <template v-else>
        <img src="@/assets/img/video_noData.png" alt="" />
        <div class="welcome">Welcome to use Cloudmeeting</div>
        <div class="message">No one is sharing data at the meeting, please be patient.</div>
      </template>
    </div>
    <template v-else>
      <hstWhiteBoard
        v-if="shareData.mediaType === 'WHITE_BOARD'"
        :user="shareData"
      ></hstWhiteBoard>
      <hstScreenShare
        v-else-if="shareData.mediaType === 'SCREEN_SHARE'"
        :user="shareData"
      ></hstScreenShare>
      <hstMediaShare
        v-else-if="shareData.mediaType === 'VIDEO'"
        :user="shareData"
      ></hstMediaShare>
      <hst-audio-share
        v-if="shareData.mediaType === 'AUDIO'"
        :user="shareData"
      />
    </template>
    <!-- 操作工具 -->
    <tool-share
      v-if="shareData.mediaType === 'SCREEN_SHARE'"
      type="screen"
      ref="toolShare"
      :markShow="false"
      @on-stop-screen-share="stopScreenShare"
    ></tool-share>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ToolShare } from "@/components/hstMedia/hstWhiteBoard/components";
import hstWhiteBoard from "@/components/hstMedia/hstWhiteBoard";
import hstMediaShare from "@/components/hstMedia/hstMediaShare";
import hstAudioShare from "@/components/hstMedia/hstAudioShare";
import hstScreenShare from "@/components/hstMedia/hstScreenShare";
import appConfig from "@/config/appConfig";

export default {
  name: "HstShare",
  components: {
    hstWhiteBoard,
    hstScreenShare,
    hstMediaShare,
    hstAudioShare,
    ToolShare,
  },
  props: {
    shareDataMedia: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["hstWebEngine"]),
    shareSelfScreen() {
      return this.$store.state.medias.shareSelfScreen;
    },
    // 当前共享即是自己创建的屏幕共享
    sharingSelfScreen() {
      const { shareSelfScreen, shareData } = this;
      return (
        shareSelfScreen && shareData && shareData.mediaType === "SCREEN_SHARE"
      );
    },
  },
  watch: {
    shareDataMedia: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.handlerMedia();
      },
    },
  },
  data() {
    return {
      welcomeTitle: appConfig.language.welcome,
      shareData: {},
    };
  },
  methods: {
    handlerMedia() {
      this.shareData = this.shareDataMedia;
    },
    // 停止屏幕共享
    stopScreenShare() {
      this.hstWebEngine.closeScreenSharing(
        this.shareData.userId,
        () => {},
        (error) => {
          error && this.$message.error(error.msg || `error: ${error.result}`);
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.full {
  width: 100%;
  height: 100%;
}
.share-content {
  .full;
  .no-share {
    .full;
    background: #e3e7f1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.share-self {
      background: #272829;
    }
    .welcome {
      font-size: 24px;
      color: #42424a;
      margin-top: 20px;
      line-height: 1.5;
    }
    .message {
      font-size: 14px;
      color: #767a82;
    }
  }
}
</style>
