<template>
  <div>
    <a-modal :visible="visible" class='invite-modal' :title="inviteType === 'Audio' ? 'Audio invitation' : 'Video Invitation'" :maskClosable='false' :keyboard='false' @cancel="handleCancel">
      <p class="info"><a-icon type="info-circle" />{{inviteType === 'Audio' ? 'Host requests that you be unmuted' : 'Host requests to start your video'}}</p>
      <template slot="footer">
        <a-button key="submit" type="primary" @click="handleOk">
          Agree
        </a-button>
        <a-button key="back" @click="handleCancel">
          Refuse
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    inviteType: {
      type: String
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      'hstWebEngine',
    ])
  },
  methods: {
    handleOk(e) {
      this.hstWebEngine.agreeOpenMedia();
      this.$emit('update:visible', false)
    },
    handleCancel(e) {
      this.hstWebEngine.refuseOpenMedia();
      this.$emit('update:visible', false)
    },
  },
};
</script>
<style lang="less" scoped>
.invite-modal {
  /deep/.ant-modal-body{
    padding: 30px 20% 0 20%;
  }
  /deep/.ant-form label{
    font-size: 18px;
  }
  .info{
    font-size: 24px;
    line-height: 32px;
    display: flex;
    justify-items: center;
  }
  /deep/.anticon-info-circle{
    font-size: 32px;
    color: #5aa4f7;
    margin-right: 16px;
  }
}
</style>
