<template>
  <div
    class="audio-share-wrap audo-share-stop"
    :class="{ 'audio-share-play': groupUserList[user.userId].MediaShareState === 2 }"
  >
    <span class="info" :title="`${groupUserList[user.userId].DisplayName}-Sharing audio`">
      {{ groupUserList[user.userId].DisplayName }}-Sharing audio
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      userInfo: {},
      displayName: ''
    }
  },
  computed: {
    ...mapGetters(['groupUserList'])
  }
}
</script>

<style lang="less" scoped>
.audio-share-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  background: #272829 url('~@/assets/img/player_dynamic_pause.png') no-repeat center;
  .info {
    position: absolute;
    top: 8px;
    left: 8px;
    max-width: 200px;
    padding: 6px;
    font-size: 12px;
    color: #fff;
    background: rgba(81, 86, 113, 0.6);
    border-radius: 3px;
    z-index: 10;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.audio-share-play {
  background-image: url('~@/assets/img/player_dynamic_effect.gif');
}
</style>
