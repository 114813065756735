<template>
  <div class=" clearfix no-select">
    <div class="user-item fl audio" ref="mScreen">
      <div class="layout-status">
        <div class="f-icons">
          <layout class="icon"></layout>
        </div>
        <div class="actions">Layout</div>
      </div>
    </div>
    <!-- <a-popover v-model="layoutVisable" :getPopupContainer="getPop" placement="bottomLeft" trigger="click">
      <template slot="content">
        <div class="content-item  no-select">
          <div class="tooltip-title">布局样式</div>
          <a-radio-group :default-value="mediaLayout" @change="onChangeLayout">
            <a-radio class="radios" :value="1"> 画中画</a-radio>
            <a-radio class="radios" :value="2"> 焦点布局 </a-radio>
          </a-radio-group>
        </div>
      </template>
      <div class="selects fl">
        <span class="arrow-up"></span>
      </div>
    </a-popover> -->
  </div>
</template>
<script>
import { layout } from '@/core/icons' // 布局图标引入
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    layoutList: {
      type: Array,
      default: () => []
    }
  },
  name: 'Layout',
  computed: {
    ...mapGetters(['mediaLayout'])
  },
  data () {
    return {
      layoutVisable: false,
      getPop: () => this.$refs['mScreen']
    }
  },
  components: {
    layout
  },
  methods: {
    ...mapActions(['setLayout']),
    onChangeLayout (e) {
      // 切换布局
      this.setLayout(e.target.value)
    }
  }
}
</script>
<style lang="less" scoped>
.layout-status {
  padding-top: 4px;
  height: 60px;
  cursor: pointer;
}
.f-icons {
  margin: 0 auto;
  width: 34px;
  height: 34px;
  position: relative;
  text-align: center;

  .icon {
    width: 34px;
    height: 34px;
  }
  .actions {
    line-height: 16px;
  }
}
.no-devices {
  color: #5f667a;
}
.user-item {
  width: 64px;
  height: 60px;
  font-size: 12px;
  line-height: 16px;
  background-size: 64px 60px;
  color: #c8d7e6;
  text-align: center;
  &:hover {
    background: #434a63;
  }
}
.selects {
  width: 20px;
  height: 60px;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #434a63;
  }
  .arrow-up,
  .arrow-down {
    position: absolute;
    top: 18px;
    left: 6px;
    width: 0;
    height: 0;

    font-size: 0;
    line-height: 0;
  }
  .arrow-up {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 6px solid #9dadc5;
  }
  .arrow-down {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #9dadc5;
  }
}
.content-item {
  padding: 20px;
  .radios {
    display: block;
    height: 30px;
    line-height: 30px;
  }
}
.bor-1 {
  border-bottom: 1px solid #e1e5ec;
}
.tooltip-title {
  font-weight: bold;
  color: #42424a;
  line-height: 30px;
}
</style>
