<template>
  <a-modal
    v-model="visible"
    :maskClosable="isMaskClose"
    :title="title"
    :width="width"
    centered
    @cancel="closeNotice"
    :footer="null"
  >
    <div class="nt-container">
      <div class="nt-item">
        {{ noticeTxt }}
      </div>
      <div class="nt-footer">
        <a-button type="primary" @click="closeNotice" class="sure-btn" size="small">{{ btnTxt }}</a-button>
      </div>
    </div>
  </a-modal>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isMaskClose: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Tips for Joining'
    },
    btnTxt: {
      type: String,
      default: 'OK'
    },
    noticeTxt: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 360
    }
  },
  data () {
    return {
      visible: false
    }
  },
  watch: {
    value (val) {
      this.visible = val
    },
    visible (val) {
      this.$emit('input', val)
    }
  },
  mounted () {
    if (this.value) {
      this.visible = true
    }
  },
  methods: {
    closeNotice () {
      // 关闭modal
      this.visible = false
      this.$emit('colseNotice')
    }
  }
}
</script>
<style lang="less" scoped>
.nt-container {
  padding: 15px;
  .nt-item {
    text-align: center;
    padding: 15px;
  }
  .nt-footer {
    padding-top: 15px;
    text-align: right;
    .sure-btn {
      border: none;
      width: 100px;
      height: 30px;
      background: #5aa4f7;
      color: #fff;
    }
  }
}
</style>
