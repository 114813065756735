/*
 * @Descripttion: 
 * @version: 
 * @Author: Terry
 * @Date: 2021-10-29 16:36:03
 * @LastEditors: Terry
 * @LastEditTime: 2021-10-29 16:36:04
 */
import ModalBoard from './modal-board'

export default ModalBoard
