<template>
  <div class="clearfix no-select">
    <div class="user-item fl vedio" ref="mScreen">
      <div class="vedio-status" @click="changeStatus(videoStatus)" :class="{ canclose: isCameraStop }">
        <div class="f-icons" v-if="videoStatus < 5">
          <template v-if="videoStatus === 1">
            <videoIcon class="icon"></videoIcon>
          </template>
          <template v-if="videoStatus === 2">
            <videoStop class="icon"></videoStop>
          </template>
          <template v-if="videoStatus === 3">
            <videoStop class="icon"></videoStop>
          </template>
          <template v-if="videoStatus === 4">
            <videoHandUp class="icon"></videoHandUp>
          </template>
        </div>
        <div v-else class="f-icons no-devices">
          <a-tooltip placement="top">
            <template slot="title">
              <span>No Video</span>
            </template>
            <div>
              <novideo class="icon"></novideo>
            </div>
          </a-tooltip>
        </div>
        <div class="actions no-select" :class="{ 'no-devices': videoStatus === 5 }">
          {{ videoStatus | vedioStatuTxt }}
        </div>
      </div>
    </div>
    <template v-if="videoStatus < 5">
      <a-popover v-model="uvModel" :getPopupContainer="getPop" placement="bottomLeft" trigger="click">
        <template slot="content">
          <div class="content-item  no-select bor-1">
            <div class="tooltip-title">Select the camera</div>
            <a-radio-group :value="currentCam" @change="onCamChange">
              <template v-if="camList.length > 0">
                <a-radio v-for="item in camList" :key="item.devId" class="radios" :value="item.devId">
                  {{ item.devName | devName }}
                </a-radio>
              </template>
              <template v-else>
                No Video
              </template>
            </a-radio-group>
          </div>
          <!-- <div class="content-item no-select ">
            <div class="tooltip-title">摄像头分辨率</div>
            <a-radio-group :default-value="currentResolution" @change="onResChange">
              <template v-if="resList.length > 0">
                <a-radio v-for="item in resList" :key="item.value" class="radios" :value="item.key">
                  {{ item.value }}
                </a-radio>
              </template>
              <template v-else>
                无分辨率
              </template>
            </a-radio-group>
          </div> -->
        </template>
        <div class="selects fl">
          <span :class="[place === 'bottom' ? 'arrow-up' : 'arrow-down']"></span>
        </div>
      </a-popover>
    </template>
    <template v-else>
      <div class="selects  fl">
        <span :class="[place === 'bottom' ? 'no-dev-up' : 'no-dev-down']"></span>
      </div>
    </template>
  </div>
</template>
<script>
import { videoIcon, videoHandUp, videoStop, novideo } from '@/core/icons' // 图标引入
import { mapActions, mapGetters } from 'vuex'
import { RESOLUTIONMAP } from '@/utils/constants'
import { changeByValue } from '@/utils/util'
export default {
  props: {
    place: {
      type: String,
      default: 'bottom' // 工具栏的位置
    },
    camList: {
      type: Array,
      default: () => []
    },
    resList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      value: 1,
      uvModel: false,
      mediaId: '0', // 一开始的mediaId
      getPop: () => this.$refs['mScreen']
    }
  },
  computed: {
    ...mapGetters([
      'hstWebEngine',
      'hstRtcEngine',
      'videoStatus',
      'currentCam',
      'isCameraStop',
      'currentResolution',
      'serviceAuth',
      'localPubVideo',
      'roleAuths',
      'roleAuth',
      'mediasMap'
    ])
  },
  watch: {
    uvModel: function (val) {
      this.$emit('openPoupe', val)
    }
  },
  components: {
    videoIcon,
    videoHandUp,
    videoStop,
    novideo
  },
  mounted () {
    // 映射devId 到 0
    console.log('自动切换映射......')
    const resolution = RESOLUTIONMAP[this.currentResolution] // 当前分辨率
    this.hstWebEngine.switchCamDev(this.mediaId, this.currentCam)
    this.hstRtcEngine.setVideoParam(this.mediaId, {
      width: resolution[0],
      height: resolution[1] - (resolution[1] % 8),
      frameRate: resolution[1] > 540 ? 25 : 15
    })
  },
  methods: {
    ...mapActions(['SetCurCamera', 'SetVideoStatus', 'SetResolution', 'SetLocalPubVideo']),
    changeStatus (sta) {
      if (this.isCameraStop) {
        console.log('过于频繁开关视频广播 -> 不可操作')
      } else {
        switch (sta) {
          case 1:
            this.stopVideo()
            break
          case 2:
            this.openVideo()
            // this.SetVideoStatus(1) // 开启视频
            break
          case 3:
            this.openVideo()
            this.SetVideoStatus(4) // 举手申请
            break
          case 4:
            this.handDown()
            this.SetVideoStatus(3) // 无摄像头
            break
          default:
        }
      }
    },
    openVideo () {
      this.hstWebEngine.broadcastVideo({ mediaId: 0 })
    },
    stopVideo () {
      // 关闭摄像头
      // 取消广播本地视频
      console.log('关闭视频')
      this.SetLocalPubVideo(false)
      if (this.roleAuth.BroadcastOwnVideo === '0' || this.roleAuth.BroadcastOwnVideo === undefined) {
        this.SetVideoStatus(3)
      } else {
        // 无需权限
        this.SetVideoStatus(2)
      }
      this.hstWebEngine.unbroadcastVideo({ mediaId: 0 })
    },
    handDown () {
      // 放下手
      this.$emit('downHand')
      if (this.roleAuth.BroadcastOwnVideo === '0' || this.roleAuth.BroadcastOwnVideo === undefined) {
        this.SetVideoStatus(3)
      } else {
        // 无需权限
        this.SetVideoStatus(2)
      }
      this.hstWebEngine.unbroadcastVideo({ mediaId: 0 })
    },
    onCamChange (e) {
      const videoMap = this.mediasMap['VIDEO']
      const resolution = RESOLUTIONMAP[this.currentResolution] // 当前分辨率
      // console.log('changeCamera' + this.currentCam)
      this.SetCurCamera(e.target.value)
      if (this.videoStatus === 1) {
        // 正在广播，切换当前的mediaId
        changeByValue(videoMap, 'isLocal', true, 'mediaId', this.currentCam)
      }

      this.hstRtcEngine
        .switchDevice(2, '0', e.target.value, {
          width: resolution[0],
          height: resolution[1] - (resolution[1] % 8), // 兼容pc端的分辨率需要被8整除
          frameRate: resolution[1] > 540 ? 25 : 15
        })
        .then(data => {
          console.log(data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    onResChange (e) {
      // width: 640, height: 360, frameRate: 15
      this.SetResolution(e.target.value)
      const resolution = RESOLUTIONMAP[e.target.value]
      console.log(0, this.currentCam, '切换分辨率')
      if (this.videoStatus === 1) {
        // 使用切换摄像的参数来切换分辨率
        this.hstRtcEngine.switchDevice(2, '0', this.currentCam, {
          width: resolution[0],
          height: resolution[1] - (resolution[1] % 8),
          frameRate: resolution[1] > 540 ? 25 : 15
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.vedio-status {
  padding-top: 4px;
  height: 60px;
  cursor: pointer;
}
.canclose {
  color: #5f667a;
  cursor: not-allowed;
}
.f-icons {
  margin: 0 auto;
  width: 34px;
  height: 34px;
  position: relative;
  text-align: center;

  .icon {
    width: 34px;
    height: 34px;
  }
  .no-device {
    color: #c7d4e8;
  }
  .actions {
    line-height: 16px;
  }
}
.no-devices {
  color: #5f667a;
}
.user-item {
  width: 64px;
  height: 60px;
  font-size: 12px;
  line-height: 16px;
  background-size: 64px 60px;
  color: #c8d7e6;
  text-align: center;
  &:hover {
    background: #434a63;
  }
}
.selects {
  width: 20px;
  height: 60px;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #434a63;
  }
  .arrow-up,
  .no-dev-up,
  .arrow-down,
  .no-dev-down {
    position: absolute;
    top: 18px;
    left: 6px;
    width: 0;
    height: 0;

    font-size: 0;
    line-height: 0;
  }
  .arrow-up {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 6px solid #9dadc5;
  }
  .arrow-down {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #9dadc5;
  }

  .no-dev-up {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 6px solid #5f667a;
  }
  .no-dev-down {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #5f667a;
  }
}
.content-item {
  padding: 16px 20px;
  .radios {
    display: block;
    height: 30px;
    line-height: 30px;
  }
}
.bor-1 {
  border-bottom: 1px solid #e1e5ec;
}
.tooltip-title {
  font-weight: bold;
  color: #42424a;
  font-size: 16px;
  line-height: 30px;
}
</style>
