<template>
  <div class="clearfix no-select">
    <div class="user-item fl audio" ref="mScreen">
      <div class="audio-status" @click="changeStatus(audioStatus)">
        <div class="f-icons" v-if="audioStatus < 5">
          <div class="volume-wrap" v-if="audioStatus === 1" key="volumes">
            <!-- <img class="icon vols" :src="require(`@/assets/img/${imgName}.png`)" /> -->
            <!--  -->
            <audioMic class="icon"></audioMic>
            <audioMicVolume1 v-show="volume > 0 && volume <= 15" class="icon vols"></audioMicVolume1>
            <audioMicVolume2 v-show="volume > 15 && volume <= 35" class="icon vols"></audioMicVolume2>
            <audioMicVolume3 v-show="volume > 35 && volume <= 50" class="icon vols"></audioMicVolume3>
            <audioMicVolume4 v-show="volume > 50 && volume <= 65" class="icon vols"></audioMicVolume4>
            <audioMicVolume5 v-show="volume > 65 && volume <= 85" class="icon vols"></audioMicVolume5>
            <audioMicVolume6 v-show="volume > 85 && volume <= 100" class="icon vols"></audioMicVolume6>
          </div>
          <template v-if="audioStatus === 2">
            <audioStop class="icon"></audioStop>
          </template>
          <template v-if="audioStatus === 3">
            <audioStop class="icon"></audioStop>
          </template>
          <template v-if="audioStatus === 4">
            <audioHandUp class="icon"></audioHandUp>
          </template>
        </div>
        <div v-else class="f-icons no-devices" key="no-device">
          <a-tooltip placement="topRight">
            <template slot="title">
              <span>No Audio</span>
            </template>
            <div>
              <noaudio class="icon"></noaudio>
            </div>
          </a-tooltip>
        </div>
        <div class="actions no-select" :class="{ 'no-devices': audioStatus === 5 }">
          {{ audioStatus | audioStatuTxt }}
        </div>
      </div>
    </div>
    <template v-if="audioStatus < 5">
      <a-popover v-model="aModel" :getPopupContainer="getPop" placement="bottomLeft" trigger="click">
        <template slot="content">
          <div class="content-item bor-1 no-select">
            <div class="tooltip-title">Choosing a Audio</div>
            <a-radio-group :default-value="currentMic" @change="onMicChange">
              <template v-if="micList.length > 0">
                <a-radio v-for="item in micList" :key="item.devId" class="radios" :value="item.devId">
                  {{ item.devName }}
                </a-radio>
              </template>
              <template v-else>
                No Audio
              </template>
            </a-radio-group>
          </div>
          <!-- <div class="content-item no-select">
            <div class="tooltip-title">选择扬声器</div>
            <a-radio-group :default-value="currentSpk" @change="onSpkChange">
              <template v-if="spkList.length > 0">
                <a-radio v-for="item in spkList" :key="item.devId" class="radios" :value="item.devId">
                  {{ item.devName }}
                </a-radio>
              </template>
              <template v-else>
                无扬声器
              </template>
            </a-radio-group>
          </div> -->
        </template>
        <div class="selects fl">
          <span :class="[place === 'bottom' ? 'arrow-up' : 'arrow-down']"></span>
        </div>
      </a-popover>
    </template>
    <template v-else>
      <div class="selects  fl">
        <span :class="[place === 'bottom' ? 'no-dev-up' : 'no-dev-down']"></span>
      </div>
    </template>
  </div>
</template>
<script>
import {
  audioVol,
  audioMicVolume1,
  audioMicVolume2,
  audioMicVolume3,
  audioMicVolume4,
  audioMicVolume5,
  audioMicVolume6,
  audioMic,
  audioHandUp,
  audioStop,
  noaudio
} from '@/core/icons' // 图标引入
import { mapActions, mapGetters } from 'vuex'
import { objToStrMap } from '@/utils/util'
export default {
  props: {
    place: {
      type: String,
      default: 'bottom' // 工具栏的位置
    },
    micList: {
      type: Array,
      default: () => []
    },
    spkList: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      status: 2, // 1 正在语音 2 关闭语音状态  3举手状态 4 无麦克风
      aModel: false,
      curMic: '',
      curSpk: '',
      mediaId: '0',
      getPop: () => this.$refs['mScreen'],
      volume: ''
    }
  },
  components: {
    audioVol,
    audioMicVolume1,
    audioMicVolume2,
    audioMicVolume3,
    audioMicVolume4,
    audioMicVolume5,
    audioMicVolume6,
    audioMic,
    audioHandUp,
    audioStop,
    noaudio
  },
  watch: {
    aModel: function (val) {
      this.$emit('openPoupe', val)
    },
    mediaState: {
      immediate: true,
      deep: true,
      handler (newVal) {
        const volMap = objToStrMap(newVal)
        if (this.localUser && volMap.has(this.localUser.UserID + '')) {
          this.volume = volMap.get(this.localUser.UserID + '').state
        }
      }
    }
  },
  mounted () {
    this.hstWebEngine.switchMicDev(this.mediaId, this.currentMic)
  },
  computed: {
    ...mapGetters([
      'hstWebEngine',
      'localUser',
      'audioStatus',
      'currentMic',
      'currentSpk',
      'serviceAuth',
      'roleAuths',
      'roleAuth',
      'mediaState'
    ])
    // imgName: function () {
    //   console.log(this.volume)
    //   const a = parseInt(this.volume / 10) * 10

    //   return 'tb_mic_open' + a + '@3x'
    // }
  },
  methods: {
    ...mapActions(['SetCurMicro', 'SetAudioStatus', 'SetLocalPubAudio', 'SetCurSpeaker']),
    changeStatus (sta) {
      switch (sta) {
        case 1:
          this.stopAudio()
          // this.SetAudioStatus(2) // 停止音频
          break
        case 2:
          this.openAudio()
          // this.SetAudioStatus(1) // 解除静音
          break
        case 3:
          this.openAudio()
          this.SetAudioStatus(4) // 举手申请
          break
        case 4:
          this.handDown()
          this.SetAudioStatus(3) // 放下
          break
        default:
      }
    },
    openAudio () {
      // 打开麦克风
      console.log('开始广播音频')
      this.hstWebEngine.broadcastAudio({ mediaId: '0' }) // 这里只用默认的麦克风广播
    },
    stopAudio () {
      // 关闭麦克风
      console.log('关闭麦克风')
      this.SetLocalPubAudio(false)
      if (this.roleAuth.BroadcastOwnAudio === '0' || this.roleAuth.BroadcastOwnAudio === undefined) {
        this.SetAudioStatus(3)
      } else {
        // 无需权限
        this.SetAudioStatus(2)
      }
      this.hstWebEngine.unbroadcastAudio({ mediaId: 0 })
    },
    handDown () {
      // 放下手
      this.$emit('downHand')
      this.stopAudio()
    },
    onMicChange (e) {
      this.SetLocalPubAudio(false)
      // 切换麦克风
      if (this.audioStatus === 1) {
        console.log('切换麦克风 ' + this.mediaId + 'to' + e.target.value)
        this.hstWebEngine.switchMicDev(0, e.target.value).then(() => {
          this.SetLocalPubAudio(true)
          this.$emit('changeMic')
        })
      }

      this.SetCurMicro(e.target.value)
    },
    onSpkChange (e) {
      // 切换扬声器
      this.hstWebEngine.switchSpkDev(e.target.value)
      this.SetCurSpeaker(e.target.value)
    }
  }
}
</script>
<style lang="less" scoped>
.audio-status {
  height: 60px;
  cursor: pointer;
  padding-top: 4px;
}
.f-icons {
  margin: 0 auto;
  width: 34px;
  height: 34px;
  position: relative;
  text-align: center;

  .volume-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .icon {
    width: 34px;
    height: 34px;
  }
  .vols {
    position: absolute;
    top: 0;
    left: 0;
  }
  .no-device {
    color: #c7d4e8;
  }
  .actions {
    line-height: 16px;
  }
}
.no-devices {
  color: #5f667a;
}
.user-item {
  width: 64px;
  height: 60px;
  font-size: 12px;
  line-height: 16px;
  background-size: 64px 60px;
  color: #c8d7e6;
  text-align: center;
  &:hover {
    background: #434a63;
  }
}
.selects {
  width: 20px;
  height: 60px;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #434a63;
  }
  .arrow-up,
  .no-dev-up,
  .arrow-down,
  .no-dev-down {
    position: absolute;
    top: 18px;
    left: 6px;
    width: 0;
    height: 0;

    font-size: 0;
    line-height: 0;
  }
  .arrow-up {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 6px solid #9dadc5;
  }
  .arrow-down {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #9dadc5;
  }

  .no-dev-up {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 6px solid #5f667a;
  }
  .no-dev-down {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #5f667a;
  }
}
.content-item {
  padding: 16px 20px;
  cursor: pointer;
  .radios {
    display: block;
    height: 30px;
    line-height: 30px;
  }
}
.bor-1 {
  border-bottom: 1px solid #e1e5ec;
}
.tooltip-title {
  font-weight: bold;
  font-size: 16px;
  color: #42424a;
  line-height: 30px;
}
</style>
