<template>
  <div class="meeting-wrapper">
    <!-- 未登录 -->
    <not-logged v-if="loginStatus === 0"></not-logged>

    <!-- 已经登录 -->
    <div class="logined" v-if="loginStatus === 2">
      <!-- 非全屏 -->
      <div class="meeting-header no-select clearfix" v-if="!isFullscreen">
        <!-- 会议title -->
        <div class="meeting-time fl" title="Join meeting time （加入会议时间）">{{ timeRecord }}</div>
        <div class="meeting-name">
          <span style="display: inline-block"
            >{{ meetingInfo.roomName }}
            <!-- 原有逻辑是根据roleAuth.OnlineInvitation == 1 -->
            <i
              v-if="
                localUser.DataState === 2 ||
                (localUser.ManagerState === 2 && localUser.Right === 3)
              "
              class="icons"
              title="Meeting Information"
              @click="() => (meetingInfoVisible = true)"
            >
              <meetingInfo class="icon"></meetingInfo>
            </i>
          </span>
        </div>
        <!-- 全屏相关按钮 -->
        <div class="full-screen fr" @click="toggleScreenfulls">
          <div class="icons" title="Full screen display">
            <template v-if="isFullscreen">
              <fullScreenBack class="icon"></fullScreenBack>
            </template>
            <template v-else>
              <fullScreen class="icon"></fullScreen>
            </template>
          </div>
        </div>
      </div>

      <div :class="['logined-meeting-content', isFullscreen ? 'full' : '']">
        <div class="left-part">
          <!-- 全屏显示 -->
          <div class="meeting-main" id="meetingScreen" ref="mScreen">
            <!-- 头部工具栏 -->
            <toolbar-top
              ref="toolbarTop"
              v-show="isFullscreen"
              :devices="devicesList"
              :show="isShow"
              :resolution="resolutionList"
              :isSelfShare="isSelfShare"
              :isAnonymousJoin="isAnonymousJoin"
              @on-change-audio="changeMic"
              @on-show-bar="showBar"
              @on-layout-click="toLayoutScreenfull"
              @on-exit="exitMeetModal"
              @on-exit-ffullscreen="exitFullscreen"
              @on-mouseover="mouseOver"
              @on-show-share="showShareModal"
            ></toolbar-top>

            <screen-layout
              :layoutType="layoutType"
              :showMedia="showMedia"
              :videoDatas="videoDatas"
              :shareLayoutData="presentShareData"
              :videoLocations="videoLocations"
            />

            <div class="audio-wrapper" style="display: block">
              <hstAudio
                ref="userAudio"
                :user="item"
                v-for="item in audioDatas"
                :key="`${item.userId}_${item.mediaId}`"
              ></hstAudio>
            </div>

            <!-- 全屏时右上角退出全屏 -->
            <!-- <div
              v-if="isFullscreen"
              class="full-screen back-full-screen"
              @click="exitFullscreen"
            >
              <div class="icons" title="退出全屏">
                <fullScreenBack class="icon"></fullScreenBack>
              </div>
            </div> -->
          </div>

          <!-- 底部工具栏 -->
          <toolbar-bottom
            ref="toolbarBottom"
            v-show="!isFullscreen"
            :devices="devicesList"
            :resolution="resolutionList"
            :isSelfShare="isSelfShare"
            :isAnonymousJoin="isAnonymousJoin"
            @on-change-audio="changeMic"
            @on-down-audio="audioDownHand"
            @on-down-video="videoDownHand"
            @on-layout-click="toLayoutScreenfull"
            @on-exit="exitMeetModal"
            @on-show-share="showShareModal"
          ></toolbar-bottom>
        </div>
        <!-- 右侧 弹层 聊天/人员列表 -->
        <div class="right-part" v-show="chatShow || userListShow">
          <user-list-layer
            v-show="userListShow"
            class="right-part-tool"
          ></user-list-layer>
          <chat-tool v-show="chatShow" class="right-part-tool"></chat-tool>
        </div>
      </div>
    </div>

    <!-- 离开或结束会议 -->
    <a-modal
      v-model="exitVisible"
      :maskClosable="false"
      title="Exit"
      :width="380"
      class="no-select"
      centered
      :getContainer="getRefAff"
      @ok="() => (modal2Visible = false)"
      :footer="null"
    >
      <div class="leave-container no-select">
        <div class="leave clearfix" @click="leaveMeeting">
          <div class="l-left fl">
            <leveMeeting class="l-icon"></leveMeeting>
          </div>
          <div class="l-right fl">
            <div class="leave-tit">Leaving the meeting</div>
            <div class="others">Others continue</div>
          </div>
        </div>
        <div
          class="leave end clearfix"
          v-if="
            this.localuser &&
            this.localuser.ManagerState == 2 &&
            this.localuser.Right == 3
          "
          @click="endMeeting"
        >
          <div class="l-left fl">
            <endMeeting class="l-icon"></endMeeting>
          </div>
          <div class="l-right fl">
            <div class="leave-tit">End the meeting</div>
            <div class="others">Everyone walks out of the meeting</div>
          </div>
        </div>
      </div>
    </a-modal>
    <!-- 会议信息 -->
    <a-modal
      v-model="meetingInfoVisible"
      title="Meeting Information"
      :width="500"
      :maskClosable="false"
      :mask="false"
      centered
      @ok="() => (meetingInfoVisible = false)"
      @cancel="() => (hasCopyed = false)"
      :footer="null"
    >
      <div class="info-container">
        <div class="mt-itme">
          <div class="mt-title">Meeting Topic</div>
          <p class="meet-title">{{ meetingInfo.roomName }}</p>
        </div>
        <div class="mt-item" v-if="meetingInfo.roomType == 1">
          <div class="mt-title">Time</div>
          <p class="orange">
            {{ meetingInfo.hopeStartTime | moment }} -
            {{ meetingInfo.hopeEndTime | moment }}
          </p>
        </div>
        <div class="mt-item" v-if="meetingInfo.roomType == 2">
          <div class="mt-title">Time</div>
          <p class="orange">
            {{ meetingInfo.startTime }} - {{ meetingInfo.endTime }}
          </p>
        </div>
        <!-- <div class="mt-item">
          <div class="mt-title">邀请链接</div>
          <p class="blue">{{ meetingInfo.meetingURL }}</p>
        </div> -->
        <div class="mt-item">
          <div class="mt-title">Invitation code</div>
          <p class="darkbold">{{ meetingInfo.inviteCode | NumberFormat }}</p>
        </div>
        <div class="mt-item call-in" v-if="meetingInfo.phoneMeetingRight == 1">
          <div class="mt-phone-title">Call to join the meeting</div>
          <ul class="steps">
            <li class="step">
              <span class="sequ">1</span> From the telephone, dial（0755）3636 9555
            </li>
            <li class="step">
              <span class="sequ">2</span> At the beep, press *1 to select Chinese and *2 to select English
            </li>
            <li class="step">
              <span class="sequ">3</span> Enter the meeting invitation code at the prompt：{{
                meetingInfo.inviteCode | NumberFormat
              }}
            </li>
            <li class="step">
              <span class="sequ">4</span> After the meeting, press *6 to apply for speaking or give up speaking
            </li>
          </ul>
        </div>
        <div class="mt-footer">
          <a-button
            class="copy-btn"
            :class="{ 'has-copyed': hasCopyed }"
            @click="copyMeetingInfo"
          >
            Copy infomation
          </a-button>
        </div>
      </div>
    </a-modal>
    <!-- 入会密码 -->
    <a-modal
      v-model="pwdVisiable"
      :maskClosable="false"
      title="Room password"
      :width="360"
      centered
      class="no-select"
      @cancel="cancelJoin"
      :footer="null"
    >
      <div class="pwd-container">
        <div class="pwd-item">
          <div class="pwd-title">Enter the room password</div>
          <a-form
            id="formLogin"
            class="user-layout-login"
            ref="formLogin"
            :form="form"
          >
            <a-form-item :help="helpInfo" :validate-status="validateStatu">
              <a-input
                size="default"
                type="password"
                autocomplete="new-password"
                :maxLength="32"
                :placeholder="'Room password'"
                @change="changePassword"
                @pressEnter="passwordJoin"
                v-decorator="[
                  'password',
                  {
                    rules: [
                      { required: true, message: $t('user.password.required') },
                    ],
                    validateTrigger: 'blur',
                  },
                ]"
              >
                <!-- <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" /> -->
              </a-input>
            </a-form-item>
          </a-form>
        </div>
        <div class="pwd-footer">
          <a-button class="foot-btn cancel-btn" @click="cancelJoin">
            Cancel
          </a-button>
          <a-button class="foot-btn" @click="passwordJoin"> Confirm </a-button>
        </div>
      </div>
    </a-modal>
    <!-- 入会提示 -->
    <a-modal
      v-model="noticeVisiable"
      :maskClosable="false"
      title="Tips for meeting"
      :width="360"
      centered
      @cancel="closeNotic"
      :footer="null"
    >
      <div class="nt-container">
        <div class="nt-item">
          {{ notice }}
        </div>
        <div class="nt-footer">
          <a-button
            type="primary"
            @click="closeNotic"
            class="sure-btn"
            size="small"
            >Confirm</a-button
          >
        </div>
      </div>
    </a-modal>
    <!-- 登录频繁提示 -->
    <a-modal
      v-model="loginFrequencyVisible"
      :maskClosable="false"
      title="Tips for meeting"
      :width="360"
      centered
      @cancel="closeLoginFrequency"
      :footer="null"
    >
      <div class="nt-container">
        <div class="nt-item">{{ loginFrequencyNessage }}</div>
        <div class="nt-footer">
          <a-button
            type="primary"
            @click="closeLoginFrequency"
            class="sure-btn"
            size="small"
            >Confirm</a-button
          >
        </div>
      </div>
    </a-modal>
    <!-- 会议提示 -->
    <a-modal
      v-model="proxyNoticeVisiable"
      :maskClosable="false"
      :closable="false"
      title="Tips for meeting"
      :width="360"
      centered
      @cancel="() => (proxyNoticeVisiable = false)"
      :footer="null"
    >
      <div class="nt-container">
        <div class="nt-item">Trying to reconnect, please wait...</div>
        <div class="nt-footer">
          <a-button
            class="foot-btn cancel-btn"
            type="primary"
            @click="() => (proxyNoticeVisiable = false)"
            size="small"
          >
          Hide
          </a-button>
          <a-button
            type="primary"
            @click="leaveMeeting"
            class="foot-btn sure-btn"
            size="small"
            >Leaving the meeting</a-button
          >
        </div>
      </div>
    </a-modal>
    <!-- 断线重连 -->
    <hstModal
      v-model="porxyFailVisible"
      :isMaskClose="false"
      :closable="false"
      :title="'Tips for meeting'"
      :noticeTxt="'Disconnection failed to reconnect'"
      :btnTxt="'Leaving the meeting'"
      @colseNotice="leaveMeeting"
    ></hstModal>
    <hstModal
      v-model="enterRommLockVisible"
      :isMaskClose="true"
      :title="'Tips for meeting'"
      :noticeTxt="'The administrator has locked the meeting room, please wait for the administrator to unlock it'"
      @colseNotice="() => (enterRommLockVisible = false)"
    >
    </hstModal>
    <!-- 登录提示 -->
    <hstModal
      v-model="enterVisible"
      :isMaskClose="true"
      :title="'Login Tips'"
      :noticeTxt="loginTypeTip[loginType]"
      @colseNotice="userpwdError"
    >
    </hstModal>
    <hstModal
      v-model="mutedVisible"
      :isMaskClose="true"
      :title="'Tips for meeting'"
      :noticeTxt="noticeText"
      @colseNotice="() => (mutedVisible = false)"
    >
    </hstModal>
    <hstModal
      v-model="noConnectVisible"
      :isMaskClose="true"
      :title="'Tips for meeting'"
      :noticeTxt="leaveText"
      @colseNotice="outMeeting"
    >
    </hstModal>
    <!-- 缓存被清除提示 -->
    <hstModal
      v-model="storageCleared"
      :isMaskClose="false"
      :width="400"
      title="Tips for meeting"
      noticeTxt="Browser browsing data is cleared, please refresh the page to re-enter the meeting"
      @colseNotice="reloadPage"
    >
    </hstModal>
    <a-modal
      v-model="kickVisible"
      :maskClosable="false"
      title="Tips for meeting"
      :width="360"
      centered
      @cancel="outMeeting"
      :footer="null"
    >
      <div class="nt-container">
        <div class="nt-item">
          {{ leaveText }}
        </div>
        <div class="nt-footer">
          <a-button
            type="primary"
            @click="outMeeting"
            class="sure-btn"
            size="small"
            >Confirm({{ kickTime }})</a-button
          >
        </div>
      </div>
    </a-modal>

    <!-- 共享版本，屏幕，文档 -->
    <share-modal ref="shareModal" @on-share-confirm="shareCreate" />
    <!-- electron 屏幕共享选择层 -->
    <select-screen-model
      :visible.sync="screenSelectModelShow"
      @screen-selected="startShareScreen"
    ></select-screen-model>
    <!-- 选择布局类型 -->
    <layout-selector
      ref="layoutSelector"
      :layoutType="layoutType"
      @on-confirm="confirmLayout"
    />
    <!-- 会中邀请其他参会人 -->
    <address-book-modal v-model="inviteModalShow" @ok="onInviteOk" />
    <!-- 账号强制登录被挤掉 -->
    <force-logout v-model="forceLogoutShow" :user-name="userName" />
  </div>
</template>
<script>
import {
  fullScreen,
  fullScreenBack,
  fullScreenBacks,
  meetingInfo,
  layout,
  exitMeeting,
  leveMeeting,
  endMeeting,
} from '@/core/icons' // 图标引入
import hstAudio from '@/components/hstMedia/hstAudio.vue'
import audioStatus from '@/components/toolBar/audioStatus'
import videoStatus from '@/components/toolBar/videoStatus'
import layoutStatus from '@/components/toolBar/layoutStatus'
import exitClient from '@/components/toolBar/exitClient'
import hstModal from '@/components/hstModal'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import screenfull from 'screenfull'
import { addZero, getStorage, removeByValue, strMapToObj, throttle2 } from '@/utils/util'
import {
  defaultResolutionList,
  proxyCode,
  notify,
  LAYOUT_TYPE,
} from '@/utils/constants'
import ShareBtn from '@/components/toolBar/shareBtn.vue'
import {
  NotLogged,
  BeforeLoginLeft,
  ToolbarBottom,
  ToolbarTop,
  ShareModal,
  ScreenLayout,
  LayoutSelector,
  ChatTool,
  UserListLayer,
} from './components'
import SelectScreenModel from './components/select-screen-model'
import whiteBoardMixins from './whiteBoardMixins'
import AddressBookModal from '@/components/addressBook/AddressBookModal'
import ForceLogout from '@/components/forceLogout'

const loginTypeTip = {
  1: 'Username or password error, please reenter',
  2: 'Your account has been disabled, please contact the administrator',
}

const FUNCTIONAL_KEY = 'functioning'

// function getQueryParamByKey
export default {
  name: 'Meeting',
  data() {
    return {
      loginStatus: 0, // 登录状态 0 未登录 1 登录前 2 已登录
      roomId: this.$route.query.roomId, // 会议室号
      userName: this.$route.query.userName, // 用户名
      userPass: '', // 用户密码
      token: this.$route.query.token, // 会议token
      roomPwd: this.$route.query.roomPwd, // 新用户带的会议密码
      loginType: this.$route.query.type,
      isAnonymousJoin: !this.$route.query.hasOwnProperty('type'),
      cesAddr: this.$route.query.cesAddr,
      loginTypeTip,
      isLogin: false,
      renderState: 1,
      noCheck: false,
      localuser: {}, // 本地用户信息
      groupUsers: new Map(), // 分组用户
      // serviceAuths: {}, // 产品功能权限
      // roleAuths: {}, // 角色权限
      timer: null, // 计时器
      showMedia: false,
      isFullscreen: false, // 页面全屏
      barTimer: null, // 全屏状态下顶部工具栏显示计时器
      isShow: false, // 全屏状态下顶部工具栏显示
      mainLayout: 1, // 页面主布局
      timeRecord: '00 : 00',
      aDownHand: false, // 音频举手
      vDownHand: false, // 视频举手
      vModel: false, // 选择视频Model
      uvModel: false,
      aModel: false, // 选择音频Model
      uaModel: false,
      layoutVisable: false, // 切换布局modal
      uLayout: false,
      mediaDatas1: [],
      mediaDatas2: [],
      switchShareData: {}, // 切换的展示数据
      showShareData: {}, // 需要展示的数据内容
      exitVisible: false, // 退出弹窗
      hasCopyed: false, // 复制
      meetingInfoVisible: false, // 会议信息弹窗
      meetingInfo: {},
      enterVisible: false, // 入会用户名密码错误提示
      form: this.$form.createForm(this), // 输入密码表单
      pwdVisiable: false, // 输入入会密码弹窗
      oldPwd: '', // 上次输入的密码
      helpInfo: '', // 密码错误提示消息
      validateStatu: 'success', // 输入框的状态
      noticeVisiable: false, // 通知信息弹窗
      proxyNoticeVisiable: false, // 断线重连的弹窗
      porxyFailVisible: false, // 重连失败弹窗
      enterRommLockVisible: false, // 进入锁定会议室弹窗
      noticeText: '', // 通知提示语
      leaveText: '', // 被通知离开会议室的提示语
      leaveType: 1, // 离开模式
      closeVisible: false, // 管理员关闭会议室
      kickVisible: false, // 被请出开会议室弹窗
      mutedVisible: false, // 全场静音弹窗
      noConnectVisible: false, // 被代理主动断开弹窗
      timer2: null,
      timeruser: null, // 获取用户信息的timer
      kickTime: 3, // 踢出倒计时
      notice: '', // 通知
      screenSelectModelShow: false,
      devicesList: {
        camDevs: [],
        micDevs: [],
        spkDevs: [],
      },
      // resolution: [], // 分辨率列表
      mediaStatus: 'uninit',
      audioStatsTimer: null,
      LAYOUT_TYPE,
      layoutType: LAYOUT_TYPE.VIDEO, // 布局类型
      storageCleared: false,
      loginFrequencyVisible: false,
      loginFrequencyNessage: '',
      forceLogoutShow: false,
      videoLocations: [],
    }
  },
  mixins: [whiteBoardMixins],
  components: {
    fullScreen,
    fullScreenBack,
    fullScreenBacks,
    meetingInfo,
    layout,
    exitMeeting,
    leveMeeting,
    endMeeting,
    hstAudio,
    audioStatus,
    videoStatus,
    layoutStatus,
    exitClient,
    hstModal,
    ShareBtn,
    NotLogged,
    BeforeLoginLeft,
    ToolbarBottom,
    ToolbarTop,
    ShareModal,
    ScreenLayout,
    LayoutSelector,
    ChatTool,
    UserListLayer,
    SelectScreenModel,
    AddressBookModal,
    ForceLogout,
  },
  computed: {
    ...mapGetters([
      'hstWebEngine',
      'hstRtcEngine',
      'mediaLayout',
      'mediasMap',
      'switchData',
      'audioStatus',
      'videoStatus',
      'currentMic',
      'micDevList',
      'currentCam',
      'isCameraStop',
      'camDevList',
      'resolutionList',
      'currentResolution',
      'localUser',
      'localPubAudio',
      'localPubVideo',
      'layoutScreenfull',
      'groupUserList',
      'serviceAuth',
      'roleAuth',
      'roleAuths',
      'role',
      'browserScreenfull',
      'currentShareMediaId',
      'boardList',
      'chatShow',
      'userListShow',
      'inviteShow',
    ]),

    // 音频元素
    hstAudioRef: function () {
      return this.$store.state.medias.hstAudioRef
    },
    // 视频数据的改变
    videoDatas: function () {
      return this.mediasMap['VIDEO'] // 视频
    },
    // 媒体共享的改变
    shareDatas: function () {
      return this.mediasMap['MEDIA_SHARE'] // 媒体共享
    },
    // 屏幕共享的改变
    screenShareDatas: function () {
      return this.mediasMap['SCREEN_SHARE'] // 屏幕共享
    },
    // 白板数据的改变
    whiteBoardDatas: function () {
      return this.mediasMap['WHITE_BOARD'] // 白板数据
    },
    presentShareData: function () {
      // 正在显示的共享数据
      let { mediaType, mediaId, userId, userData } = this.switchShareData
      mediaType === 'VIDEO' && (mediaType = 'MEDIA_SHARE')
      const _mediaId = mediaType === 'SCREEN_SHARE' ? userId : mediaId
      if (!this.mediasMap[mediaType] || !this.mediasMap[mediaType].length)
        return {}
      var selIndex = this.mediasMap[mediaType].findIndex((item, index) => {
        let id = item.mediaId
        // 屏幕共享
        if (mediaType === 'SCREEN_SHARE') {
          id = item.userId
        }

        // 显示当前切换的白板
        if (mediaType === 'WHITE_BOARD') {
          if (userData === index) {
            return userData === index
          }
          return item.mediaId.split(';')[1] == `${_mediaId}`
        } else {
          // 获取对应的媒体ID
          return `${id}` == `${_mediaId}`
        }
      })
      return selIndex !== -1 ? this.mediasMap[mediaType][selIndex] : {}
    },
    isSelfShare() {
      const { presentShareData, localuser } = this
      return Number(presentShareData.userId) === Number(localuser.UserID)
    },
    // 音频数据列表
    audioDatas: function () {
      return this.mediasMap['AUDIO'] // 音频
    },
    // 切换总媒体统计数
    totalMedias: function () {
      return this.videoDatas.length
    },
    // 控制通讯录展示
    inviteModalShow: {
      get() {
        return this.inviteShow
      },
      set(value) {
        this.SET_INVITE_SHOW(value)
      },
    },
  },
  created() {
    const { roomId, userName, token, cesAddr } = this
    this.userPass = getStorage('userPass') || ''
    console.log(
      `'入会传来的参数', roomId -> ${roomId},userName -> ${userName} ,token -> ${token},cesAddr -> ${cesAddr}`
    )
    // 初始化hst web engine
    const clientType = process.env.VUE_APP_CLIENT_TYPE
    this.initWebEngine(cesAddr ? { cesAddr, clientType } : { clientType })
      .then(() => {
        this.getMeetingInfos() // 获取会议信息 + 获取设备列表
      })
      .catch(err => {
        // 限制频繁登录
        if (err.status === 'login_frequency_excess') {
          let countDown = 10
          this.loginFrequencyVisible = true
          this.loginFrequencyNessage = `Login is too frequent, automatically jump to login after（${countDown}）seconds`
          this.backLoginTimer = setInterval(() => {
            countDown--
            if (countDown <= 0) {
              this.closeLoginFrequency()
            } else {
              this.loginFrequencyNessage = `Login is too frequent, automatically jump to login after（${countDown}）seconds`
            }
          }, 1000)
        } else {
          this.$error({
            content: err.msg,
            onOk: this.goBackByFrom,
          })
        }
      })
    // this.onDeviceChange() // 监听设备的插拔
    this.listenStorage()
  },
  mounted() {
    // 浏览器可能会禁止音频自动播放，需要手动play
    const hide = this.$message.info('If you cannot hear the sound, click anywhere to continue', 0)
    const autoplayFun = () => {
      this.hstAudioRef && this.hstAudioRef.play()
      hide()
      window.removeEventListener('click', autoplayFun)
    }
    window.addEventListener('click', autoplayFun)
    // 加入会议室
    // 解决按f11时图标不变化的问题
    window.addEventListener('keydown', this.KeyDown, true) // 监听按键事件
    if (screenfull.isEnabled) {
      screenfull.on('change', this.screenChange)
    }
  },
  watch: {
    presentShareData: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.handlerRenderCurrentMediaId(newVal)
      },
    },
    isFullscreen: function (val) {
      this.toggleBrowserScreenfull(val) // 全局浏览器f11全屏
    },
    micDevList: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal.length > 0) {
          // 设置麦克风的默认状态
          if (this.currentMic === undefined || parseInt(this.currentMic) > newVal.length - 1) {
            this.SetCurMicro(newVal[0].devId)
          }
        } else {
          // 无麦克风设备
          this.SetAudioStatus(5)
        }
      },
    },
    camDevList: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal.length > 0) {

          if (this.currentCam === undefined || parseInt(this.currentCam) > newVal.length - 1) { // 缓存的id不存在 使用默认
            // 设置默认摄像头
            this.SetCurCamera(newVal[0].devId)
          }
        } else {
          this.SetVideoStatus(5)
        }
      },
    },
    resolutionList: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal.length > 0) {
          if (this.currentResolution === undefined) {
            // 设置默认摄像头
            this.SetResolution(1080)
          }
        }
      },
    },
    roleAuth: {
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal && this.camDevList.length > 0) {
          if (
            newVal.BroadcastOwnVideo === '0' ||
            newVal.BroadcastOwnVideo === undefined
          ) {
            // 有一种 情况是没有权限，也没有 BroadcastOwnVideo 字段
            if (!this.localPubVideo) this.SetVideoStatus(3)
            // 失去了权限
          } else {
            // 获得权限
            if (!this.localPubVideo) this.SetVideoStatus(2)
          }
        }

        if (newVal && this.micDevList.length > 0) {
          if (
            newVal.BroadcastOwnAudio === '0' ||
            newVal.BroadcastOwnAudio === undefined
          ) {
            if (!this.localPubAudio) this.SetAudioStatus(3)
          } else {
            // 无需权限
            if (!this.localPubAudio) this.SetAudioStatus(2)
          }
        }

        // 失去邀请权限后关闭通讯录
        if (parseInt(newVal?.OnlineInvitation) === 0) {
          this.SET_INVITE_SHOW(false)
        }
      },
    },
    localuser: {
      // 本地用户状态监听
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        // 设置权限 TODO 这里授予管理员仍不为admin
        if (newVal && newVal.Right === 2) {
          this.SetRole('mentee')
        }
        if (newVal && newVal.Right === 3 && newVal.ManagerState === 2) {
          this.SetRole('admin')
        }
      },
    },
    localPubAudio(val) {
      const audioMap = this.mediasMap['AUDIO']
      if (this.localuser) {
        if (val) {
          // this.setAudioStats() // 开始音量统计
          audioMap.push({
            userId: this.localuser.UserID,
            meidaType: 'AUDIO',
            mediaId: this.currentCam,
            isLocal: val,
          })
        } else {
          // 删除本地音频
          // if (this.audioStatsTimer) this.audioStatsTimer.stop() // 关闭音量统计
          removeByValue(
            audioMap,
            'userId',
            this.localuser.UserID,
            'isLocal',
            true
          )
        }
      }
    },
    localPubVideo(val) {
      const videoMap = this.mediasMap['VIDEO']
      if (this.localuser) {
        if (val) {
          videoMap.push({
            userId: this.localuser.UserID,
            meidaType: 'VIDEO',
            mediaId: this.currentCam,
            isLocal: val,
          })
        } else {
          // 删除本地视频
          removeByValue(
            videoMap,
            'userId',
            this.localuser.UserID,
            'isLocal',
            true
          )
        }
      }
    },
    totalMedias(val) {
      // 监听媒体总数自动切换布局
      if (val > 0) {
        this.showMedia = true
      }
      if (val > 2) {
        this.setLayout(2)
      } else if (val > 0 && val < 3) {
        this.setLayout(1)
      } else {
        this.showMedia = false
      }
    },
    isShow(val) {
      if (val) {
        this.topBarShow(10000)
      }
    },
    videoList(val) {
      if (val) {
        console.log('video的个数' + val.length)
      }
    },
    switchData: {
      deep: true,
      handler(val) {
        if (val) {
          // const { mediaType  } = val
          let { mediaType, mediaId, userId, userData } = val
          let layoutMode = 2
          if (mediaType === 'WHITE_BOARD') layoutMode = 2
          if (mediaType === 'SCREEN_SHARE') layoutMode = 1

          // this.mediasMap[mediaType].map((item, index) => {
          //   if(mediaId == item.mediaId) {
          //     const _userData = userData > index ? index - 1 : index
          //     this.switchShareData = { mediaType, mediaId, userId, userData: _userData, layoutMode }
          //   }
          // })
          // this.switchShareData = val;
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'initWebEngine',
      'setRtcEngine',
      'setNetfeature',
      'destoryEngine',
      'setLayout',
      'onSwitchAudio',
      'setPreOpenAudio',
      'setPreOpenVideo',
      'setMicList',
      'SetCurMicro',
      'setSpkList',
      'setCamList',
      'SetCurCamera',
      'setResolutionList',
      'SetResolution',
      'SetLocalUser',
      'setMediaState',
      'SetLocalPubVideo',
      'SetVideoStatus',
      'SetLocalPubAudio',
      'SetAudioStatus',
      'SetGroupInfo',
      'UpdateGroupInfo',
      'DeleteGroupInfo',
      'ClearGroupInfo',
      'setMedias',
      'emptyVideoMap',
      'setSwitchData',
      'toggleLayoutScreenfull',
      'toggleBrowserScreenfull',
      'toggleMuted',
      'SetserviceAuth',
      'SetRoleAuth',
      'SetRoleAuths',
      'SetRole',
    ]),

    ...mapMutations([
      'SET_CURRENT_BOARD',
      'SET_CURRENT_SHARE_MEDIA_ID',
      'SET_SHARE_SELF_SCREEN',
      'SET_INVITE_SHOW',
      'CLEAR_LOCAL_USER',
      'SET_RECORD_STATUS'
    ]),

    //  当前渲染的媒体的id
    handlerRenderCurrentMediaId(currentMedia) {
      if (!currentMedia) {
        return
      }

      const { mediaType, userId, mediaId } = currentMedia
      let _mediaId = mediaType == 'SCREEN_SHARE' ? userId : mediaId

      if (!_mediaId && _mediaId != 0) return
      if (mediaType === 'WHITE_BOARD') {
        this.mediasMap[mediaType].findIndex(item => {
          _mediaId == item.mediaId &&
            this.SET_CURRENT_SHARE_MEDIA_ID(item.mediaId)
        })
      } else {
        // 存放当前的创建的白板ID
        this.SET_CURRENT_SHARE_MEDIA_ID(`${_mediaId}`)
      }
    },
    // 创建白板
    shareCreate(type) {
      console.log('点击共享创建')
      if (type === 1) {
        // 视频模式时创建 就换成数据+视频模式
        const layoutType =
          this.layoutType == LAYOUT_TYPE.VIDEO
            ? LAYOUT_TYPE.DATA_VIDEO
            : this.layoutType
        // 白板共享  from ./whiteBoardMixins.js
        this.createWhiteBoard(
          { LayoutMode: layoutType, displayName: this.localUser.DisplayName },
          obj => {
            const { type } = obj
            // true 创建成功
            if (type) {
              this.$refs.shareModal.shareModalShow = false
            }
          }
        )
      }
      // 桌面共享
      if (type === 0) {
        // from ./whiteBoardMixins.js
        if (!this.permissionShare) {
          this.$message.warning('Another user is sharing, please wait for the other user to finish before sharing')
          return
        }
        // electron 下，打开屏幕流选择弹窗
        if (process.env.VUE_APP_CLIENT_TYPE === 'ELECTRON') {
          this.screenSelectModelShow = true
          return
        }
        this.startShareScreen()
      }
    },
    startShareScreen() {
      const successCallback = () => {
        window.onbeforeunload = () => "Try to change the prompt, but it doesn't work"
        this.$refs.shareModal.shareModalShow = false
        this.layoutType = LAYOUT_TYPE.DATA_VIDEO
        this.SET_SHARE_SELF_SCREEN(true)
      }
      const failCallback = error => {
        error && this.$message.warning(error.msg || `error: ${error.result}`)
      }
      this.hstWebEngine.createScreenSharing(successCallback, failCallback)
    },
    startJoin() {
      this.loginStatus = 0
      this.start()
    },
    start() {
      this.onEvents() // 一开始就注册需要监听的事件
      this.joinMeeting()
    },
    getDeviceList() {
      this.hstWebEngine.getMediaDevices().then(({ data }) => {
        this.devicesList = data
        // 设置摄像头，麦克风，扬声器
        this.setMicList(this.devicesList.micDevs)
        this.setSpkList(this.devicesList.spkDevs)
        this.setCamList(this.devicesList.camDevs)
        this.setResolutionList(defaultResolutionList)
      })
    },
    onEvents() {
      // 监听事件
      this.onNotify() // 会议状态监听
      this.onUserChange() // 监听用户的改变
      this.onRoleAuthCHange() // 监听角色权限的改变
      this.onMediaPublish() // 开始媒体监听
      this.onShareDataSwitch() // 共享数据替换监听
      this.onMediaBroadcast() // 本地广播监听
      this.onAuthChang() // 权限变化监听
      this.onProxyStatus() // 代理连接状态监听
      this.onUserForceLogout() // 账号重复登陆被挤掉
      this.onLayoutChange() // 布局变化
      this.onRecordChange() // 录制状态变化
    },
    onRecordChange () {
      this.hstWebEngine.on('CLOUD_RECORD', data => {
        data.result === 0 && this.SET_RECORD_STATUS({
          status: data.data,
          type: 1
        })
        // this.layoutType = data.layoutMode
        // this.videoLocations = data.position
      })
    },
    
    onLayoutChange () {
      this.hstWebEngine.on('LAYOUT_CHANGE', data => {
        // this.layoutType = data.layoutMode
        this.videoLocations = data.position
      })
    },
    joinMeeting(pwd) {
      // 登录加入会议室room
      const { roomId, userName, userPass, token } = this
      // this.userName = getUrlQuery('userName') // 用截取的方法获取userName参数，适配里面有其他字符
      if (
        [undefined, ''].includes(roomId) ||
        [undefined, ''].includes(userName)
      ) {
        this.$message.error('Missing room number or user name, please contact the conference administrator!')
        setTimeout(this.goBackByFrom, 1000 * 3)
        return
      }
      if (userName.length > 32) {
        this.setNotice('Usernames cannot be longer than 32 characters!')
        return
      }
      // 匿名入会
      if (!this.$route.query.hasOwnProperty('type')) {
        this.hstWebEngine
          .anonymousJoinMeet({
            roomId: parseInt(roomId),
            userName: userName,
            password: pwd !== undefined ? pwd : this.roomPwd || '',
          })
          .then(data => {
            //   '匿名方式入会成功'
            this.loginSuccess() // 会议室登录成功
          })
          .catch(err => {
            if (err.result === 8457) {
              // 测试密码 ffffff
              this.pwdVisiable = true
              if (this.oldPwd !== '') {
                this.helpInfo = 'The meeting password is wrong. Please reenter it'
                this.validateStatu = 'error'
              }
            } else if (err.result || err.data) {
              this.setNotice(proxyCode[err.result] || err.data)
            } else {
              console.log(err)
            }
          })
      } else {
        // 带token入会
        if ([1, 2].includes(+this.loginType)) {
          this.enterVisible = true // 用户名或密码错误，请重新输入
          return
        }
        this.hstWebEngine
          .accountJoinMeet({
            roomId: parseInt(roomId),
            userName: userName,
            token: token,
            userPass,
            password: pwd !== undefined ? pwd : this.roomPwd || '',
          })
          .then(() => {
            this.loginSuccess() // 会议室登录成功
          })
          .catch(err => {
            if (err.result === 8457) {
              // 需要输入入会密码的时候
              this.pwdVisiable = true
              if (this.oldPwd !== '') {
                this.helpInfo = 'The meeting password is wrong. Please reenter it'
                this.validateStatu = 'error'
              }
            } else {
              this.setNotice(proxyCode[err.result] || err.data)
            }
          })
      }
    },
    loginSuccess() {
      const rtcEngine = this.hstWebEngine.getRtcEngine()
      this.setRtcEngine(rtcEngine) // 登录成功可以获取RtcEngine
      // 用户登录会议室成功
      this.enterRommLockVisible = false // 锁定的弹窗--入会前会议被锁定
      this.loginStatus = 2 // 加入会议成功才有视频界面
      this.recordTime() // 开始会议计时
      this.getLocalUser() // 获取本地用户
      this.getGroupUsers() // 获取群组用户
      this.getNetfeature() // 获取网络特性
      this.getServiceAuth() // 获取大会议室权限
      this.getRoleAuth() // 获取权限
      this.getMeetConfig() // 获取会议室的允许基本配置
      // 缓存登录类型
      const { loginTab } = this.$route.query
      loginTab && window.sessionStorage.setItem('loginTab', loginTab)
      // 缓存登录信息
      const { roomId, userName, roomPwd } = this
      const from = sessionStorage.getItem('meeting_page_from')
      if (from === 'login') {
        localStorage.setItem('login_roomId', roomId)
        localStorage.setItem('login_roomPwd', roomPwd || '')
        localStorage.setItem('login_userName', userName)
      }
    },

    changePassword(e) {
      // 更改password
      if (e.target.value === '') {
        this.helpInfo = 'Please enter the password for the meeting!'
        this.validateStatu = 'error'
      } else {
        this.helpInfo = ''
        this.validateStatu = 'success'
      }
    },
    passwordJoin() {
      const {
        form: { validateFields },
      } = this
      // 验证入会的登录密码
      validateFields(['password'], { force: true }, (err, values) => {
        if (!err) {
          this.oldPwd = values.password
          this.pwdVisiable = false
          this.joinMeeting(values.password)
        } else {
          this.helpInfo = 'Please enter the password for the meeting!'
          this.validateStatu = 'error'
        }
      })
    },
    onDeviceChange() {
      // 设备热插拔监听
      const _this = this
      navigator.mediaDevices.ondevicechange = function (event) {
        // 有设备变化，更新设备
        _this.hstWebEngine.getMediaDevices().then(({ data }) => {
          _this.devicesList = data
          _this.setMicList(data.micDevs)
          _this.setSpkList(data.spkDevs)
          _this.setCamList(data.camDevs)
        })
      }
    },
    // 账号被挤掉
    onUserForceLogout() {
      this.hstWebEngine.on('onUserForceLogout', () => {
        this.forceLogoutShow = true
      })
    },
    cancelJoin() {
      this.goBackByFrom()
    },
    onMediaPublish() {
      // 监听媒体数据通知
      const _this = this
      const eventQueue = []

      function setMedia({
        opt,
        data,
        videoMap,
        audioMap,
        mediaShareMap,
        screenShareMap,
        whiteBoardMap,
      }) {
        if (opt === 'render') {
          // 收到需要渲染的数据
          if (
            videoMap.length === 0 &&
            audioMap.length === 0 &&
            mediaShareMap.length === 0 &&
            screenShareMap.length === 0 &&
            _this.mediaStatus === 'uninit'
          ) {
            // 首次收到视频数据
            _this.mediaStatus = 'inited'
            audioMap = data.AUDIO || []
            videoMap = data.VIDEO || []
            mediaShareMap = data.MEDIA_SHARE || []
            screenShareMap = data.SCREEN_SHARE || []
            whiteBoardMap = data.WHITE_BOARD || []
            if (data['AUDIO']) {
              // 媒体共享
              _this.cacheShareMedias({
                media: data['AUDIO'][0],
                type: 'render',
              })
            }
            if (data['MEDIA_SHARE']) {
              // 媒体共享
              _this.cacheShareMedias({
                media: data['MEDIA_SHARE'][0],
                type: 'render',
              })
            }
            if (data['SCREEN_SHARE']) {
              // 屏幕共享
              _this.cacheShareMedias({
                media: data['SCREEN_SHARE'][0],
                type: 'render',
              })
            }
          } else {
            if (data['VIDEO']) {
              // 视频 自己的视频总在最后一个，所以新到的插入倒数第一个
              const lastVideo = videoMap[videoMap.length - 1]
              if (lastVideo && lastVideo.isLocal) {
                videoMap.splice(-1, 0, ...data['VIDEO'])
              } else {
                videoMap.push(...data['VIDEO'])
              }
            }
            if (data['AUDIO']) {
              // 添加音频
              audioMap.push(...data['AUDIO'])
              _this.cacheShareMedias({
                media: data['AUDIO'][0],
                type: 'render',
              })
            }
            if (data['MEDIA_SHARE']) {
              // 添加媒体共享
              mediaShareMap.push(...data['MEDIA_SHARE'])
              _this.cacheShareMedias({
                media: data['MEDIA_SHARE'][0],
                type: 'render',
              })
            }
            if (data['SCREEN_SHARE']) {
              // 屏幕共享
              screenShareMap.push(...data['SCREEN_SHARE'])
              _this.cacheShareMedias({
                media: data['SCREEN_SHARE'][0],
                type: 'render',
              })
            }
            if (data['WHITE_BOARD']) {
              const findMedia = whiteBoardMap.find(
                board => board.mediaId == data['WHITE_BOARD'][0].mediaId
              )
              // 白板
              !findMedia && whiteBoardMap.push(...data['WHITE_BOARD'])
              !findMedia &&
                _this.cacheShareMedias({
                  media: data['WHITE_BOARD'][0],
                  type: 'render',
                })
            }
          }
        }

        if (opt === 'dispose') {
          // 收到需要取消渲染的数据
          if (data['VIDEO'] && data['VIDEO'].length > 0) {
            data['VIDEO'].forEach(item => {
              // 清除销毁的视频
              removeByValue(
                videoMap,
                'userId',
                item.userId,
                'mediaId',
                item.mediaId
              )
            })
          }
          if (data['AUDIO'] && data['AUDIO'].length > 0) {
            data['AUDIO'].forEach(item => {
              // 清除销毁的音频
              removeByValue(
                audioMap,
                'userId',
                item.userId,
                'mediaId',
                item.mediaId
              )
              _this.cacheShareMedias({
                media: {
                  mediaId: item.mediaId,
                  mediaType: 'AUDIO',
                  userId: item.userId,
                  screenAudio: item.screenAudio,
                },
                type: 'dispose',
              })
            })
          }
          if (data['MEDIA_SHARE'] && data['MEDIA_SHARE'].length > 0) {
            data['MEDIA_SHARE'].forEach(item => {
              // 清除销毁的媒体共享
              removeByValue(
                mediaShareMap,
                'userId',
                item.userId,
                'mediaId',
                item.mediaId
              )
              _this.cacheShareMedias({
                media: {
                  mediaId: item.mediaId,
                  mediaType: 'MEDIA_SHARE',
                  userId: item.userId,
                },
                type: 'dispose',
              })
            })
          }
          if (data['SCREEN_SHARE'] && data['SCREEN_SHARE'].length > 0) {
            data['SCREEN_SHARE'].forEach(item => {
              // 清除销毁的屏幕共享
              removeByValue(
                screenShareMap,
                'userId',
                item.userId,
                'mediaId',
                item.mediaId
              )
              _this.cacheShareMedias({
                media: {
                  mediaId: item.mediaId,
                  mediaType: 'SCREEN_SHARE',
                  userId: item.userId,
                },
                type: 'dispose',
              })
            })
          }
          if (data['WHITE_BOARD'] && data['WHITE_BOARD'].length > 0) {
            data['WHITE_BOARD'].forEach(item => {
              _this.cacheShareMedias({
                media: {
                  mediaId: item.mediaId,
                  mediaType: 'WHITE_BOARD',
                  userId: item.userId,
                },
                type: 'dispose',
              })
              // 清除销毁的白板
              removeByValue(
                whiteBoardMap,
                'userId',
                item.userId,
                'mediaId',
                item.mediaId
              )
            })
          }
        }
        return {
          videoMap: videoMap,
          audioMap: audioMap,
          mediaShareMap: mediaShareMap,
          screenShareMap: screenShareMap,
          whiteBoardMap: whiteBoardMap,
        }
      }
      this.hstWebEngine.on('mediaStream', ({ opt, data }) => {
        console.log(opt)
        console.log(data)
        console.log(_this.mediasMap)

        eventQueue.push({ opt, data })

        setTimeout(() => {
          const videoMap = _this.mediasMap['VIDEO'].slice(0)
          const audioMap = _this.mediasMap['AUDIO'].slice(0)
          const mediaShareMap = _this.mediasMap['MEDIA_SHARE'].slice(0)
          const screenShareMap = _this.mediasMap['SCREEN_SHARE'].slice(0)
          const whiteBoardMap = _this.mediasMap['WHITE_BOARD'].slice(0)
          let mediaMap = {
            videoMap,
            audioMap,
            mediaShareMap,
            screenShareMap,
            whiteBoardMap,
          }
          while (eventQueue.length) {
            const item = eventQueue.shift()
            mediaMap = setMedia({
              opt: item.opt,
              data: item.data,
              ...mediaMap,
            })
          }
          _this.setMedias({
            VIDEO: mediaMap.videoMap,
            AUDIO: mediaMap.audioMap,
            MEDIA_SHARE: mediaMap.mediaShareMap,
            SCREEN_SHARE: mediaMap.screenShareMap,
            WHITE_BOARD: mediaMap.whiteBoardMap,
          })
          // 根据流类型切换本地布局
          const shareList = [
            ...mediaMap.mediaShareMap,
            ...mediaMap.screenShareMap,
            ...mediaMap.whiteBoardMap,
          ]
          _this.haveShareData = shareList.length > 0
          const { DATA_VIDEO, VIDEO } = LAYOUT_TYPE
          _this.layoutType = _this.haveShareData ? DATA_VIDEO : VIDEO
        }, 200)
      })
    },
    onProxyStatus() {
      // 代理状态监听
      this.hstWebEngine.on('Proxy.Status', ({ code, msg }) => {
        if (code === 1) {
          // 清空媒体们
          this.stopRecordTime() // 停止计时
          this.clearState() // 清除当前的状态
          // this.$refs.tool
          this.proxyNoticeVisiable = true
        }
        if (code === 0) {
          // 重连成功
          this.proxyNoticeVisiable = false
          this.restartRecordTime() // 重新开始会议计时
          this.localuser = this.hstWebEngine.getLocalUser()
          // this.timeuser = setInterval(() => {
          //   this.localuser = this.hstWebEngine.getLocalUser()
          //   if (this.localuser) clearInterval(this.timeuser)
          // }, 1000)
        }
      })
    },
    // 用户权限的变化
    onAuthChang() {
      this.hstWebEngine.on('Local.User.Auth.Change', data => {
        this.SetRoleAuth(data) // 更新当前角色的权限
      })
    },
    // 监听切换共享的数据
    onShareDataSwitch() {
      const _this = this
      let timer = null
      // 切换正在共享中全部内容
      this.hstWebEngine.on('shareDataSwitch', data => {
        console.log('==========> shareDataSwitch', data)
        clearTimeout(timer)
        timer = setTimeout(() => {
          const obj = JSON.parse(JSON.stringify(data))
          this.handlerRenderCurrentMediaId(obj)
          this.SET_CURRENT_BOARD(_this.switchShareData)
          _this.switchShareData = obj
        }, 300)
      })
    },
    onMediaBroadcast() {
      // 监听本地广播音视频
      const _this = this
      this.hstWebEngine.on(
        'mediaBroadcast',
        function ({ mediaType, mediaId, state }) {
          if (state === 1) {
            if (mediaType === 'VIDEO') {
              _this.vDownHand = true
              _this.$toast('You are applying to start the video')
            }
            if (mediaType === 'AUDIO') {
              _this.aDownHand = true
              _this.$toast('You are applying to be unmuted')
            }
            mediaType === 'VIDEO'
              ? _this.SetVideoStatus(4)
              : _this.SetAudioStatus(4)
          }
          if (state === 2) {
            mediaType === 'VIDEO'
              ? _this.SetLocalPubVideo(true)
              : _this.SetLocalPubAudio(true)
            if (mediaType === 'VIDEO') {
              _this.vDownHand = false
              _this.$toast('You have started the video')
            }
            if (mediaType === 'AUDIO') {
              _this.aDownHand = false
              _this.$toast('You have unmuted')
              _this.setAudioStats() // 设置麦克风能量波动
            }

            mediaType === 'VIDEO'
              ? _this.SetVideoStatus(1)
              : _this.SetAudioStatus(1)
          }
          if (state === 0) {
            if (mediaType === 'VIDEO') {
              if (_this.vDownHand) {
                _this.$toast('You have abandoned your application')
                _this.vDownHand = false
              } else {
                _this.$toast('You have stopped the video')
              }
            }
            if (mediaType === 'AUDIO') {
              if (_this.audioStatsTimer) _this.audioStatsTimer.stop() // 停止音频统计
              if (_this.aDownHand) {
                _this.$toast('You have abandoned your application')
                _this.aDownHand = false
              } else {
                _this.$toast('You are muted')
              }
            }
          }
        }
      )
    },
    audioDownHand() {
      this.aDownHand = true
    },
    videoDownHand() {
      this.vDownHand = true
    },
    // 获取本地用户的信息
    getLocalUser() {
      this.localuser = this.hstWebEngine.getLocalUser() // 获取本机用户信息和权限
      this.localuser && this.SetLocalUser(this.localuser)
    },
    // 获取分组用户的信息
    getGroupUsers() {
      // 获取分组所有用户 Map结构
      this.groupUsers = this.hstWebEngine.getUsers()
      const objGroupUsers = strMapToObj(this.groupUsers)
      for (const key in objGroupUsers) {
        const Video = objGroupUsers[key].Video
        this.$set(objGroupUsers[key], 'Video', Video)
        if (Video && Video.length > 0) {
          Video.forEach((v, i) => {
            this.$set(Video, i, v)
          })
        }
      }
      // 对用户的 状态进行更改
      this.SetGroupInfo(objGroupUsers)
    },
    // 获取网络特性
    getNetfeature() {
      const netFeature = this.hstWebEngine.getNetFeature()
      this.setNetfeature(netFeature) // 设置网络特性
    },
    // 获取产品功能权限
    getServiceAuth() {
      const serviceAuths = this.hstWebEngine.getServiceAuth()
      this.SetserviceAuth(serviceAuths)
    },
    // 获取角色权限
    getRoleAuth() {
      const roleAuths = this.hstWebEngine.getRoleAuth()
      this.SetRoleAuths(roleAuths)
    },
    // 获取会议室的基本配置信息
    getMeetConfig() {
      this.hstWebEngine.getMeetConfig()
    },
    onRoleAuthCHange() {
      // TODO 监听角色权限的改变
      this.hstWebEngine.on('ROLE.AUTH.CHANGE', function ({ role, data }) {
        console.log(role)
        console.log(data)
        // if (role === _this.role) {
        //   _this.SetRoleAuth(data) // 更新当前角色的权限
        // }
      })
    },
    onUserChange() {
      // 监听用户的变更
      this.hstWebEngine.on('USER.CHANGE', res => {
        if (res.opt === 'new') {
          this.getGroupUsers()
        }
        if (res.opt === 'update') {
          res.data.forEach(item => {
            if (item.UserID === this.localuser.UserID) {
              // 更改是本地用户
              this.localuser = item
              if (item.AudioOutMute === 2) {
                // 被管理员静音
                this.toggleMuted(true) // 切换
              } else if (item.AudioOutMute === 0) {
                // 管理员解除静音
                this.toggleMuted(false) // 切换静音
              }
            }
            // const userInfo = Object.assigin({}, item)
            this.UpdateGroupInfo({ ...item })
          })
          const change = res.change || {}
          if (change.UserID === this.localuser.UserID) {
            this.SetLocalUser(change)
            // VNCState是屏幕共享字段，如果为0表示关闭，分为自己关闭和被他人关闭
            if (change.VNCState === 0) {
              window.onbeforeunload = undefined
              setTimeout(() => {
                this.SET_SHARE_SELF_SCREEN(false)
              }, 500)
              this.hstWebEngine.stopBrowserScreenSharing()
            }
            if (change.Video) {
              const { DATA_VIDEO, VIDEO } = LAYOUT_TYPE
              this.layoutType = this.haveShareData ? DATA_VIDEO : VIDEO
            }
            // 失去管理员或者主持人权限后关闭会议分享弹框
            if (change.ManagerState === 0 || change.DataState === 0) {
              this.meetingInfoVisible = false
            }
          }
        }
        // 删除用户
        if (res.opt === 'delete') {
          for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].UserID === this.localuser.UserID) {
              // 删除的是本地用户,被请出
              this.clearState() // 清除状态
              this.leaveText = 'You were asked to leave the meeting room by the administrator'
              this.leaveType = 1 // 被请出
              this.kickVisible = true
              // this.hstWebEngine.exitMeet()
              this.kickout()
              break
            }
            this.DeleteGroupInfo(res.data[i].UserID)
          }
        }
      })
    },
    // 监听会议室状态改变
    onNotify() {
      this.hstWebEngine.on('notify', data => {
        switch (data.code) {
          case 2001:
            this.clearState() // 清除状态
            this.leaveText = 'Admin has closed the meeting room!'
            this.leaveType = 2 // 被请出会议室
            this.kickVisible = true
            this.closeOut()
            break
          case '2002':
            this.vDownHand = false
            // 管理员开启本地视频,设置视频状态
            this.$toast(notify['2002'])
            this.SetLocalPubVideo(true)
            this.SetVideoStatus(1)
            break
          case '2003':
            // 管理停止本地视频
            this.$toast(notify['2003'])
            this.SetLocalPubVideo(false)
            if (this.roleAuth.BroadcastOwnVideo === '1') {
              this.SetVideoStatus(2)
            } else {
              this.SetVideoStatus(3)
            }
            break
          case '2004':
            this.vDownHand = false
            // 管理员开启本地视频,设置视频状态
            this.$toast(notify['2004'])
            this.SetLocalPubVideo(true)
            this.SetVideoStatus(1)
            break
          case '2005':
            // 主持人关闭本地视频
            this.$toast(notify['2005'])
            this.SetLocalPubVideo(false)

            if (this.roleAuth.BroadcastOwnVideo === '1') {
              this.SetVideoStatus(2)
            } else {
              this.SetVideoStatus(3)
            }
            break
          case '2006':
            this.aDownHand = false
            // 管理员开启本地麦克风,设置音频状态 'SetLocalPubAudio',
            this.$toast(notify['2006'])
            // 调用子组件的方法
            this.setAudioStats() // 设置麦克风能量波动
            this.SetLocalPubAudio(true)
            this.SetAudioStatus(1)
            break
          case '2007':
            // 管理停止本地音频
            this.$toast(notify['2007'])
            this.SetLocalPubAudio(false)
            this.audioStatsTimer.stop()
            if (this.roleAuth.BroadcastOwnAudio === '1') {
              this.SetAudioStatus(2)
            } else {
              this.SetAudioStatus(3)
            }
            break
          case '2008':
            this.aDownHand = false
            // 主持人开启本地麦克风,设置音频状态 'SetLocalPubAudio',
            this.$toast(notify['2008'])

            // 调用子组件的方法
            this.setAudioStats() // 设置麦克风能量波动
            this.SetLocalPubAudio(true)
            this.SetAudioStatus(1)
            break

          case '2009':
            // 管理停止本地音频
            this.$toast(notify['2009'])
            this.SetLocalPubAudio(false)
            this.audioStatsTimer.stop()
            if (this.roleAuth.BroadcastOwnAudio === '1') {
              this.SetAudioStatus(2)
            } else {
              this.SetAudioStatus(3)
            }
            break
          case 2010:
            // 被请出会议室
            this.clearState() // 清除状态
            this.leaveText = data.msg
            this.leaveType = 1 // 被请出会议室
            this.kickVisible = true
            this.kickout()
            break
          case 2011:
            // 会议室锁定，等待管理员解锁
            this.enterRommLockVisible = true
            break
          case 2012:
            // 代理链接主动断开，离开会议室
            this.noConnectVisible = true
            this.leaveText = data.msg
            this.leaveType = 1 // 离开会议室
            break
          case 2013:
            // 全场静音
            this.mutedVisible = true
            this.noticeText = data.msg
            break
          case 2015:
            // 重连代理失败
            this.porxyFailVisible = true
            this.proxyNoticeVisiable = false
            break
          case 2020:
            this.$toast('You are muted')
            this.SetLocalPubAudio(false)
            this.audioStatsTimer.stop()
            if (this.roleAuth.BroadcastOwnAudio === '1') {
              this.SetAudioStatus(2)
            } else {
              this.SetAudioStatus(3)
            }
            break
          case 2021:
            this.aDownHand = false
            this.$toast('You have unmuted')
            // 调用子组件的方法
            this.setAudioStats() // 设置麦克风能量波动
            this.SetLocalPubAudio(true)
            this.SetAudioStatus(1)
            break
          case 2022:
            this.$toast('You have stopped the video')
            this.SetLocalPubVideo(false)
            if (this.roleAuth.BroadcastOwnVideo === '1') {
              this.SetVideoStatus(2)
            } else {
              this.SetVideoStatus(3)
            }
            break
          case 2023:
            this.vDownHand = false
            this.$toast('You have started the video')
            // 调用子组件的方法
            this.SetLocalPubVideo(true)
            this.SetVideoStatus(1)
            break
          default:
            this.setNotice(data.msg)
        }
      })
    },
    // 获取会议信息
    async getMeetingInfos() {
      await this.hstWebEngine
        .getMeetInfo(this.roomId)
        .then(res => {
          if (res.result === 0) {
            this.meetingInfo = res.data
            this.getDeviceList()
            this.startJoin()
          } else {
            this.setNotice(res.data)
          }
        })
        .catch(err => {
          console.log(err)
          this.setNotice(err.data)
        })
      // 获取会议信息之后再获取设备
      // this.getDeviceList()
    },
    setAudioStats() {
      // 设置麦克风能量波动
      this.hstRtcEngine
        .getLocalAudioStats(
          this.currentMic,
          stats => {
            this.setMediaState({
              userId: this.localUser.UserID + '',
              state: stats.volume,
            })
          },
          500
        )
        .then(data => {
          // data.stop()
          this.audioStatsTimer = data
        })
        .catch(err => console.log(err))
    },
    changeMic() {
      if (this.audioStatsTimer) this.audioStatsTimer.stop() // 停止音频统计
      setTimeout(() => {
        this.setAudioStats() // 重新音频统计
      }, 0)
    },
    recordTime() {
      // 会议计时
      let hour, minute, second // 时 分 秒
      hour = minute = second = 0 // 初始化
      const start = Date.now()
      const that = this
      this.interval(function () {
        const msSecond = Date.now() - start // 减少误差，但由于浏览器的原因，还是会有误差
        second = parseInt(msSecond / 1000)
        minute = parseInt(msSecond / 60000)
        hour = parseInt(msSecond / 3600000)
        if (hour > 0) {
          that.timeRecord =
            addZero(hour) +
            ' : ' +
            addZero(minute % 60) +
            ' : ' +
            addZero(second % 60)
        } else {
          that.timeRecord = addZero(minute % 60) + ' : ' + addZero(second % 60)
        }
      }, 1000)
    },
    stopRecordTime() {
      // 停止会议计时
      clearTimeout(this.timer)
      this.timer = null
    },
    restartRecordTime() {
      // 重新会议计时
      this.stopRecordTime()
      this.timeRecord = '00 : 00'
      this.recordTime()
    },
    interval(callback, time) {
      // 计时器
      this.timer = setTimeout(() => {
        callback()
        this.interval(callback, time)
      }, time)
    },
    onSwitchAudio: throttle2(function (checked) {
      // 加入页切换麦克风开关 加入节流
      if (checked) {
        this.setPreOpenAudio(1)
      } else {
        this.setPreOpenAudio(0)
      }
    }, 200),
    onSwitchCamera: throttle2(function (checked) {
      // 加入页切换摄像头开关 加入节流，在摄像头成功获取到流之后才能关闭摄像头
      if (checked) {
        this.setPreOpenVideo(1)
        this.$nextTick(() => {
          this.noCheck = true
          this.hstWebEngine
            .previewLocalVideo(this.currentCam, this.$refs['preVideo'], {
              width: 640,
              height: 360,
              frameRate: 15,
            })
            .then(() => {
              // 渲染流成功之后才
              this.noCheck = false
            })
            .catch(err => {
              this.renderState = 2
              this.noCheck = false
              console.warn(err)
            })
        })
      } else {
        this.$nextTick(() => {
          this.hstWebEngine.closeLocalVideo(this.$refs['preVideo'])
          this.renderState = 1
          this.setPreOpenVideo(0)
        })
      }
    }, 200),
    changeCmera(cam) {
      console.warn(cam)
    },
    onChangeLayout(e) {
      // 切换屏幕布局
      this.setLayout(e.target.value)
    },
    openAudio() {
      this.aModel = true
    },
    openVideo() {
      this.vModel = true
    },
    setModal1Visible(modal1Visible) {
      this.modal1Visible = modal1Visible
    },
    // 鼠标移入顶部的bar,显示出来
    mouseOver() {
      this.isShow = true
    },
    KeyDown(event) {
      if (event.keyCode === 122) {
        // 禁用f11
        event.returnValue = false
        this.toggleScreenfulls()
        // 触发全屏的按钮
      }
    },
    showBar(val) {
      if (val && this.barTimer) {
        clearTimeout(this.barTimer)
      } else {
        this.topBarShow(5000)
      }
    },
    toLayoutScreenfull() {
      this.$refs.layoutSelector.visible = true
    },
    confirmLayout(type) {
      console.log(type)
      this.layoutType = type
    },
    toggleScreenfulls() {
      // 触发浏览器F11全屏，右上角箭头全屏
      if (!screenfull.isEnabled) {
        return false
      }
      screenfull.toggle()
    },
    screenChange() {
      // 切换全屏后
      this.isFullscreen = screenfull.isFullscreen // 页面全屏
      this.isShow = screenfull.isFullscreen // 顶部工具栏显示
    },
    topBarShow(delay = 5000) {
      // 全屏时延时关闭顶部工具栏
      if (!(this.uvModel || this.uaModel || this.uLayout)) {
        this.barTimer = setTimeout(() => {
          this.isShow = false
        }, delay)
      }
    },
    copyMeetingInfo() {
      // 复制会议信息
      this.hasCopyed = true
      // get current settings from mixin or this.$store.state.app, pay attention to the property name
      const text = `${this.userName} to invite you to a meeting he has arranged, you can go through the invitation code（${this.meetingInfo.inviteCode}）participation`
      this.$copyText(text).then(() => {
        this.$toast('Copy invitation successfully')
      })
    },
    closeOut() {
      // 关闭会议室离开会议
      this.interval2(() => {
        if (this.kickTime === 0) {
          clearInterval(this.timer2)
          this.timer2 = null
          this.goBackByFrom()
        } else {
          this.kickTime--
        }
      }, 1000)
    },
    kickout() {
      // 被请出会议室离开会议
      const _this = this
      // 关闭自己创建的白板 from './whiteBoardMixins.js'
      this.handlerAllCreateWhiteboard()

      this.interval2(() => {
        if (_this.kickTime === 0) {
          clearInterval(_this.timer2)
          this.timer2 = null
          this.goBackByFrom()
          // window.location.reload()
        } else {
          this.kickTime--
        }
      }, 1000)
    },
    interval2(callback, time) {
      // 计时器
      this.timer2 = setInterval(() => {
        callback()
      }, time)
    },
    exitMeetModal() {
      // 打开退出的框
      this.exitVisible = true
    },
    exitFullscreen() {
      // 退出全屏
      screenfull.exit()
    },
    getRefAff() {
      return this.$refs['mScreen']
    },
    // 账号密码错误退出
    userpwdError() {
      this.goBackByFrom()
    },
    async leaveMeeting() {
      // 离开会议
      this.clearState()
      try {
        await this.hstWebEngine.exitMeet()
      } finally {
        this.goBackByFrom()
      }
    },
    outMeeting() {
      this.clearState()
      this.goBackByFrom()
      if (this.leaveType === 1) {
        // 被请出会议
      } else if (this.leaveType === 2) {
        // 会议室被管理关闭
      }
    },
    async endMeeting() {
      // 结束会议
      this.clearState()
      try {
        await this.hstWebEngine.closeMeet()
      } finally {
        this.goBackByFrom()
      }
    },
    clearState() {
      this.emptyVideoMap() // 媒体置空
      this.CLEAR_LOCAL_USER() // 本地用户置空
      this.ClearGroupInfo() // 所有用户置空
      if (this.localPubVideo) {
        // 正在广播视频
        this.SetLocalPubVideo(false)
        if (this.roleAuth.BroadcastOwnVideo === '1') {
          this.SetVideoStatus(2)
        } else {
          this.SetVideoStatus(3)
        }
      }
      if (this.localPubAudio) {
        // 正在广播声音
        this.SetLocalPubAudio(false)
        if (this.audioStatsTimer) this.audioStatsTimer.stop() // 停止音频统计
        if (this.roleAuth.BroadcastOwnVideo === '1') {
          this.SetAudioStatus(2)
        } else {
          // 无需权限
          this.SetAudioStatus(3)
        }
      }
    },
    setNotice(txt) {
      // 打开提示框
      this.noticeVisiable = true
      this.notice = txt
    },
    closeNotic() {
      this.goBackByFrom()
    },
    closeLoginFrequency() {
      this.loginFrequencyVisible = false
      this.backLoginTimer && clearInterval(this.backLoginTimer)
      this.$router.replace('/login')
    },
    isEmptyObject(obj) {
      return obj === undefined || JSON.stringify(obj) === '{}'
    },
    showShareModal() {
      this.$refs.shareModal.shareModalShow = true
    },
    onStorageCleared() {
      const functional = window.sessionStorage.getItem(FUNCTIONAL_KEY)
      if ([null, undefined].includes(functional)) {
        this.storageCleared = true
      }
    },
    listenStorage() {
      window.sessionStorage.setItem(FUNCTIONAL_KEY, true)
      window.addEventListener('storage', this.onStorageCleared)
    },
    unlistenStorage() {
      window.removeEventListener('storage', this.onStorageCleared)
      window.sessionStorage.removeItem(FUNCTIONAL_KEY)
    },
    reloadPage() {
      window.location.reload()
    },
    goBackByFrom() {
      const from = sessionStorage.getItem('meeting_page_from') || ''
      switch (from) {
        case 'meeting-list':
          this.$router.back()
          break
        case 'login':
          this.$router.back()
          break
        default:
          this.$router.replace('/login')
      }
    },
    // 确认邀请
    onInviteOk(selectedUsersList) {
      const usersIdList = selectedUsersList.map(e => String(e.userId))
      const { inviteCode, roomId } = this.meetingInfo
      this.hstWebEngine.inviteUser(usersIdList, inviteCode, roomId)
      this.SET_INVITE_SHOW(false)
    },
  },
  beforeDestroy() {
    sessionStorage.removeItem('meeting_page_from')
    this.stopRecordTime() // 停止计时
    if (screenfull.isEnabled) {
      screenfull.off('change', this.screenChange)
    }
    if (this.audioStatsTimer) this.audioStatsTimer.stop() // 停止音频统计
    // this.destoryEngine() // 销毁web引擎
    this.unlistenStorage()
  },
}
</script>

<style lang="less" scoped>
@import '~@/utils/utils.less';
.bord-tools {
  position: fixed;
  z-index: 100;
  bottom: 100px;
}
.meeting-wrapper {
  width: 100%;
  height: 100%;
  min-width: 768px;
  min-height: 522px;
  background: url('~@/assets/img/bg2_img.png') no-repeat no-repeat;
  background-size: cover;
}

.join-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.join-meeting {
  width: 100%;
  padding-top: 300px;
  max-width: 1200px;
  min-width: 550px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 140px 90px;
  background: #ffffff;
  box-shadow: 0px 3px 24px 0px rgba(60, 152, 254, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;

  .medias {
    margin: 0 31px;
    flex-grow: 1;
    max-width: 699px;
    .media-title {
      font-size: 16px;
      line-height: 34px;
      text-align: center;
    }
    .media-wrap {
      width: 100%;
      padding-top: 56.25%;
      position: relative;
    }
    .media-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #272829;
      border: 1px solid #989ba4;
      z-index: 1;
      .media-cam {
        .centerAbs();
        width: 76px;
        height: 72px;
        background: url('~@/assets/img/close-camera.png') no-repeat center 0;
        background-size: 66px 52px;
        padding-top: 52px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        color: #bac7da;
      }
      .no-camera {
        background-image: url('~@/assets/img/no-cameras.png');
        color: #bac7da;
      }

      .media-video {
        width: 100%;
        height: 100%;
        background: #222324 url('~@/assets/img/videoloading.gif') no-repeat
          center;
      }
      .searchNone {
        background-image: url('~@/assets/img/video_serchnone.png');
      }
    }
    .buttons {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 38px;
      line-height: 1.2;
      padding-top: 8px;
      background: rgba(50, 55, 74, 0.5);
      color: #fff;
      z-index: 200;

      .audio-switch {
        padding: 0 20px;
      }
    }
  }
}

/* 媒体查询在宽度小于1024px时 */
@media screen and (max-width: 1024px) {
  .join-container {
    padding-top: 100px;
  }
  .join-meeting {
    flex-direction: column-reverse;
    .meeting-info {
      text-align: center;
      margin: 0 auto;
    }
    .join-btn {
      margin: 0 auto;
    }
    .medias {
      width: 100%;
      margin: 0;
      margin-bottom: 20px;
    }
  }
}
.logined {
  width: 100%;
  height: 100%;
}
.meeting-header {
  height: 30px;
  background: @primary-color;
  line-height: 30px;
  color: #c7d4e8;
  position: relative;
  .meeting-time {
    padding-left: 10px;
  }
  .meeting-name {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 30px;
    line-height: 30px;
    font-weight: 400;
    text-align: center;
    .meet-info {
      cursor: pointer;
    }
  }
}
.full-screen {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: relative;
}
.icons {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  cursor: pointer;
}

.logined-meeting-content {
  width: 100%;
  height: calc(100% - 30px);
  display: flex;

  &.full {
    height: 100%;
  }

  .left-part,
  .right-part {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .left-part {
    flex: 1;
    overflow-x: hidden;
  }

  .right-part {
    width: 360px;
    background: #fff;
    display: flex;
    flex-direction: column;
    &-tool {
      flex: 1;
      height: 0;
    }
  }
}

.meeting-main {
  flex: 1;
  position: relative;
  background: #272829;

  .screen-container {
    width: 100%;
    height: 100%;
  }
  .back-full-screen {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 30px;
    color: #c7d4e8;
    z-index: 10001;
  }
  .audio-wrapper {
    display: none;
  }
}

.content-item {
  padding: 20px;
  .radios {
    display: block;
    height: 30px;
    line-height: 30px;
  }
}
.bor-1 {
  border-bottom: 1px solid #e1e5ec;
}
.tooltip-title {
  font-weight: bold;
  color: #42424a;
  line-height: 30px;
}
.user-item {
  width: 64px;
  height: 60px;
  font-size: 12px;
  line-height: 16px;
  background-size: 64px 60px;
  color: #c8d7e6;
  text-align: center;
  cursor: pointer;
  &:hover {
    background: #434a63;
  }
}

.selects {
  width: 20px;
  height: 60px;
  position: relative;
  cursor: pointer;
  &:hover {
    background: #434a63;
  }
  .arrow-up,
  .arrow-down {
    position: absolute;
    top: 18px;
    left: 6px;
    width: 0;
    height: 0;

    font-size: 0;
    line-height: 0;
  }
  .arrow-up {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 6px solid #9dadc5;
  }
  .arrow-down {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid #9dadc5;
  }
}

.leave-container {
  padding: 40px 80px;
  .leave {
    padding: 10px;
    width: 220px;
    height: 70px;
    border: 1px solid #d5dae5;
    box-shadow: 0px 1px 0px 0px rgba(6, 22, 45, 0.05);
    border-radius: 4px;
    color: #42424a;
    cursor: pointer;
    &:hover {
      background: #e4ecfc;
    }
  }
  .l-left {
    padding: 10px;
    .l-icon {
      width: 30px;
      height: 30px;
      color: #ed7c66;
    }
  }
  .l-right {
    padding-top: 4px;
  }
  .leave-tit {
    font-size: 14px;
    font-weight: bold;
  }
  .others {
    font-size: 12px;
    font-weight: 400;
  }
}
.end {
  margin-top: 12px;
}
.info-container {
  padding: 40px 50px 50px 60px;

  .mt-title {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999ea3;
    line-height: 30px;
  }
  .mt-item {
    font-size: 12px;
  }
  .mt-phone-title {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #42424a;
  }
  .links {
    text-decoration: underline;
  }
  .meet-title {
    font-size: 12px;
    // font-family: Microsoft YaHei;
    font-weight: bold;
    color: #424856;
  }
  .orange {
    color: #ff8d41;
  }
  .blue {
    color: #3c98fe;
  }
  .darkbold {
    color: #424856;
    font-weight: 400;
  }
}
.pwd-container {
  padding: 30px 15px 20px;
  .pwd-title {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #42424a;
    line-height: 32px;
  }
  .pwd-item {
    padding: 0px 65px 10px;
  }
  .pwd-footer {
    text-align: right;

    .foot-btn {
      width: 100px;
      background: #5aa4f7;
      border-color: #5aa4f7;
      color: #fff;
    }

    .cancel-btn {
      background: #fff;
      color: #42424a;
      border: 1px solid #c6ccd8;
      margin-right: 10px;
    }
  }
}
.steps {
  padding: 0;
  .step {
    position: relative;
    line-height: 16px;
    font-size: 12px;
    color: #999ea3;
    margin-top: 8px;
    padding-left: 30px;
    .sequ {
      .centerAbs();
      width: 16px;
      height: 16px;
      border-radius: 16px;
      height: 16px;
      text-align: center;
      left: 10px;
      background: #ffdab3;
      color: #fff;
    }
  }
}
.copy-btn {
  font-size: 12px;
  font-weight: 400;
  background: #5aa4f7;
  border-color: #5aa4f7;
  color: #fff;
  padding: 0 30px;
  height: 36px;
}

.has-copyed {
  background: #33c27c;
  border-color: #33c27c;
}
.call-in {
  margin-top: 30px;
  padding-top: 30px;
  box-shadow: 0px -1px 0px 0px #f2f4f9;
}
.mt-footer {
  margin-top: 30px;
  padding-top: 30px;
  text-align: center;
  box-shadow: 0px -1px 0px 0px #f2f4f9;
}

@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}
@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
}

.nt-container {
  padding: 15px;
  .nt-item {
    text-align: center;
    padding: 15px;
  }
  .nt-footer {
    padding-top: 15px;
    text-align: right;

    .foot-btn {
      width: 100px;
      height: 30px;
      background: #5aa4f7;
      border-color: #5aa4f7;
      color: #fff;
    }

    .cancel-btn {
      background: #fff;
      color: #42424a;
      border: 1px solid #c6ccd8;
      margin-right: 10px;
    }
    .sure-btn {
      border: none;
      width: 100px;
      height: 30px;
      background: #5aa4f7;
      color: #fff;
    }
  }
}
</style>
