<template>
  <div class="clearfix no-select">
    <div class="user-item fl audio">
      <div class="layout-status">
        <div :class="['f-icons', active ? 'active' : '']">
          <toolBarInviteIcon class="icon" />
        </div>
        <div class="actions">Invitation</div>
      </div>
    </div>
  </div>
</template>

<script>
import { toolBarInviteIcon } from '@/core/icons'

export default {
  name: 'InviteBtn',
  components: { toolBarInviteIcon },
  props: {
    active: Boolean,
  },
}
</script>

<style lang="less" scoped>
.layout-status {
  padding-top: 4px;
  height: 60px;
  cursor: pointer;
}
.f-icons {
  margin: 0 auto;
  width: 34px;
  height: 34px;
  position: relative;
  text-align: center;

  &.active {
    color: #5aa4f7;
  }

  .icon {
    width: 32px;
    height: 32px;
  }
  .actions {
    line-height: 16px;
  }
}
.user-item {
  width: 64px;
  height: 60px;
  font-size: 12px;
  line-height: 16px;
  background-size: 64px 60px;
  color: #c8d7e6;
  text-align: center;
  &:hover {
    background: #434a63;
  }
}
</style>
