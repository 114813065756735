<template>
  <div class="video-wrp " ref="vrp">
    <auidoIcon :name="displayName"></auidoIcon>
    <video
      oncontextmenu="return false;"
      ref="videoEl"
      class="videos "
      autoplay
      playsinline
      muted
      style="object-fit: contain"
    ></video>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import auidoIcon from '@/components/hstMedia/audioIcon'
import { objToStrMap } from '@/utils/util'
export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    isControl: {
      type: Boolean,
      default: false
    },
    mediaType: {
      type: String,
      default: ''
    },
    mediaId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      displayName: 'user',
      hasVol: false, // 音频图标
      volume: '0',
      userInfo: {},
      renderuser: {}, // 渲染的用户
      callback: null
    }
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler (newVal, oldVal) {
        this.handleRender(newVal, oldVal)
        // if (newVal && newVal.userId) {
        //   console.log('---------------->>>>>>>>收到了媒体共享数据')
        //   this.render(newVal)
        //   this.renderuser = newVal
        // } else {
        //   this.unRnderMedia()
        //   console.log('----------------<<<<<<<<<<<<<没有了媒体共享数据')
        // }
      }
    },
    groupUserList: {
      immediate: true,
      deep: true,
      handler (newVal) {
        const userList = objToStrMap(newVal)
        console.log(userList)
        if (userList.has(this.user.userId + '')) {
          this.userInfo = userList.get(this.user.userId + '')
          this.displayName = this.userInfo.DisplayName + '-媒体共享'
        }
      }
    }
  },
  computed: {
    ...mapGetters(['currentCam', 'currentMic', 'isChangeLayout', 'hstWebEngine', 'groupUserList', 'mediaLayout'])
  },
  components: {
    auidoIcon
  },
  methods: {
    handleRender (newVal, oldVal) {
      if (this.isEmptyObject(this.user)) return
      this.$nextTick(() => {
        this.render(newVal, oldVal)
      })
    },
    render (newVal, oldVal) {
      // 显示用户名
      if (this.isEqualUser(newVal, oldVal)) return
      if (!this.isEmptyObject(oldVal)) {
        this.unRenderMedia(oldVal)
      }
      console.log('media_share', newVal.userId, newVal.mediaId)
      // 渲染video标签
      this.$nextTick(() => {
        console.log('*********************渲染媒体共享**************************')
        // 渲染视频
        this.callback = this.hstWebEngine.renderRemoteMedia(
          newVal.userId,
          newVal.mediaType,
          newVal.mediaId,
          this.$refs['videoEl']
        )
      })
    },
    unRenderMedia (user) {
      // 停止接收渲染视频

      if (!this.isEmptyObject(user)) {
        this.hstWebEngine.disposeRemoteMedia(
          user.userId,
          user.mediaType,
          user.mediaId,
          this.$refs['videoEl'],
          this.callback
        )
      }
    },
    isEmptyObject (value) {
      return value === undefined || JSON.stringify(value) === '{}'
    },
    isEqualUser (user, other) {
      if (!this.isEmptyObject(other)) {
        return user.userId === other.userId && user.mediaId === other.mediaId
      }
      return false
    }
  },
  beforeDestroy () {
    // 销毁
    // console.error('视频销毁之前')
    this.unRenderMedia(this.user)
  }
}
</script>
<style lang="less" scoped>
.video-wrp {
  width: 100%;
  height: 100%;
  position: relative;
  .videos {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    background: #222324 url('~@/assets/img/share_video.png') no-repeat center;
    box-sizing: border-box;
  }
  // .screen_share {
  //   transform: rotateX(180deg);
  //   object-fit: fill;
  // }
  .topbtn {
    position: absolute;
    left: 10px;
    top: 40px;
    z-index: 1000;
    .ant-btn {
      margin-right: 8px;
    }
  }
}
</style>
