<template>
    <svg-icon :name="deviceIconName" ></svg-icon>
</template>
<script>
export default {
  props: {
    deviceType: {
      type: Number,
      default: 8
    },
  },
  computed: {
    deviceIconName() {
      const deviceIconEnum = {
        // 32: 'device_x5',
        // 42: 'device_a3',
        // 52: 'device_v6',
        53: 'device_d1',
        // 4: 'device_telephone',
        // 14: 'device_h323',
        // 7: 'device_live',
      }
      const typeString = this.deviceType+'';
      if (Object.keys(deviceIconEnum).includes(typeString)) {
        return `#icon-${deviceIconEnum[this.deviceType]}`
      } else if ([1,21,31,41,51,8].includes(this.deviceType)) {
        return '#icon-device_pc';
      } else if ([4,3,13,23,33,43,63,73].includes(this.deviceType)) {
        return '#icon-device_mobile'
      }
      return '#icon-device_x5'
    }
  },
}
</script>
<style lang="less" scoped>
.svg-icon{
  font-size: 16px;
}
</style>
