<template>
  <div>
    <a-modal v-model="visible"  class='password-modal' title="Apply to be an administrator" :mask-closable='false' @cancel="handleCancel">
      <a-form class="pass-form" layout="vertical">
        <a-form-item :validate-status="vStatus" :help="errorMsg" label="Enter the administrator password">
          <a-input type="password" size="large" v-model="password" @focus="focus" placeholder="Please enter password" />
        </a-form-item>
        <!-- <div class="err-msg" v-show="errorMsg">{{errorMsg}}</div> -->
      </a-form>
      <template slot="footer">
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
          Confirm
        </a-button>
        <a-button key="back" @click="handleCancel">
          Cancel
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import clonedeep from 'lodash.clonedeep'
import { mapGetters } from 'vuex';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: false,
      vStatus: 'validating',
      errorMsg: '',
      password: ''
    };
  },
  watch: {
    localUserForWatch: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if((!oldVal || oldVal.ManagerState === undefined || oldVal.ManagerState === 0) && newVal.ManagerState === 2) {
          this.handleCancel();
          this.$toast('You have become an administrator');
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'hstWebEngine','roleAuth', 'localUser'
    ]),
    localUserForWatch() {
      return clonedeep(this.localUser);
    }
  },
  methods: {
    handleOk(e) {
      this.loading = true;
      if (this.password == '') {
        this.errorMsg = 'The administrator password cannot be empty'
        this.vStatus = 'error'
        this.loading = false;
        return
      }
      this.hstWebEngine.applyManagerRight(this.password, () => {
        this.loading = false;
        this.$toast('You have become an administrator');
        this.$emit('update:visible', false)
        this.password = ''
      }, (res) => {
        this.errorMsg = res.msg;
        this.vStatus = 'error'
        this.loading = false;
      });
    },
    focus() {
      this.errorMsg = '';
      this.vStatus = 'validating'
    },
    handleCancel(e) {
      this.$emit('update:visible', false)
      this.errorMsg = '';
      this.vStatus = 'validating'
      this.password = ''
    },
  },
};
</script>
<style lang="less" scoped>
.password-modal {
  /deep/.ant-modal-body{
    padding: 30px 20% 0 20%;
  }
  /deep/.ant-form label{
    font-size: 18px;
  }
}
</style>
