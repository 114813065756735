<template>
  <div class="video-wrp" ref="vrp">
    <auidoIcon
      :maxIconWidth="isBig ? '' : videoSize.maxIconWidth + 'px'"
      :hasIcon="hasIcon"
      :audioState="audioState"
      :volume="volume"
      :name="displayName"
    ></auidoIcon>
    <div
      :class="[ 
        { 'fullscreen': videoSize.fullscreen }, // svc 父组件一定需要可以获取到宽和高才能正确上报接受分辨率
        { 'big-wrap': isBig },
        { 'left-black': videoSize.leftBlack && isBig },
        { 'top-black': !videoSize.leftBlack && isBig },
      ]"
      :style="{ width: isBig ? videoSize.videoWidth : ''}"
      @dblclick="dbclickVideo"
    >
      <video
        oncontextmenu="return false;"
        ref="videoEl1"
        class="videos1"
        :class="{ renderVideo: renderState === 2 }"
        playsinline
        muted
        autoplay
        @play="playHandle"
        :style="{ 'object-fit': videoFit }"
      ></video>
    </div>
  </div>
</template>
<script>
import auidoIcon from '@/components/hstMedia/audioIcon'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { RESOLUTIONMAP } from '@/utils/constants'
import { objToStrMap } from '@/utils/util'
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    mediaType: {
      type: String,
      default: '',
    },
    mediaId: {
      type: String,
      default: '',
    },
    isBig: {
      type: Boolean,
      default: false,
    },
    videoSize: {
      type: Object,
      default: () => {},
    },
    videoFit: {
      type: String,
      default: 'cover',
    },
  },
  data() {
    return {
      hasIcon: true,
      displayName: '',
      hasVol: false, // 音频图标
      volume: '0',
      showLocalVal: false,
      audioState: 0, // 麦克风的状态
      userInfo: {},
      callback: null,
      renderState: 1, // 渲染本地视频的抓
      dbclicked: false,
    }
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        console.log('监听渲染视频')
        this.handleRender(newVal, oldVal)
      },
    },
    groupUserList: {
      immediate: true,
      deep: true,
      handler(newVal) {
        // 更新用户名
        this.handleUserName(newVal, this.user)
      },
    },
    mediaState: {
      immediate: true,
      deep: true,
      handler(newVal) {
        // console.warn('hstVideo mediaSate 更新....................')
        const volMap = objToStrMap(newVal)
        if (this.user) {
          if (volMap.has(this.user.userId + '')) {
            this.volume = volMap.get(this.user.userId + '').state
          }
        }
      },
    },
    maxVideo(newVal) {
      if (!newVal && this.dbclicked) {
        this.render(this.user, {})
      }
    },
  },
  components: {
    auidoIcon,
  },
  computed: {
    ...mapGetters([
      'currentCam',
      'currentResolution',
      'mediasMap',
      'mediaState',
      'currentMic',
      'isChangeLayout',
      'hstWebEngine',
      'hstRtcEngine',
      'groupUserList',
    ]),
    ...mapState({
      groupUser: state => state.user.groupUserList,
      maxVideo: state => state.medias.maxVideo,
    }),
  },
  methods: {
    ...mapMutations(['SET_MAX_VIDEO']),
    ...mapActions(['setCameraStop']),
    playHandle() {
      console.log('大窗口视频开始播放了', this.user)
      this.handleUserName(this.groupUserList, this.user)
    },
    handleUserName(newVal, user) {
      const userList = objToStrMap(newVal)
      if (userList.has(user.userId + '')) {
        this.userInfo = userList.get(user.userId + '')
        this.displayName = this.userInfo.DisplayName
        this.audioState = this.userInfo.Audio ? this.userInfo.Audio.State : 0
      }
    },
    handleRender(newVal, oldVal) {
      if (this.isEmptyObject(this.user)) return
      this.$nextTick(() => {
        this.render(newVal, oldVal)
      })
    },
    render(newVal, oldVal) {
      const videoEle = this.$refs['videoEl1']
      if (this.isEqualUser(newVal, oldVal)) {
        console.log('bigVideo这里跳出不渲染了')

        return
      }
      if (!this.isEmptyObject(oldVal)) {
        console.log('*******取消旧的渲染')
        this.unRnderMedia(oldVal) // 取消旧的渲染先
      }

      if (!this.isEmptyObject(newVal) && newVal.isLocal) {
        // 直接渲染本地视频
        console.log('渲染本地发出视频*******')
        this.setCameraStop(true) // 此时不能关闭视频
        const resolution = RESOLUTIONMAP[this.currentResolution] // 按设置的分辨率渲染本地视频
        this.hstRtcEngine
          .setLocalVideoRender(this.currentCam, videoEle, {
            width: resolution[0],
            height: resolution[1],
            frameRate: 30,
          })
          .then(() => {
            this.setCameraStop(false)
            console.log('本地渲染视频成功！')
          })
          .catch(err => {
            this.setCameraStop(false)
            this.renderState = 2
            console.warn(err)
          })

        return
      }
      console.log('对比新旧视频流一致性-->', this.isEqualUser(newVal, oldVal))
      if (!this.isEmptyObject(newVal) && !this.isEqualUser(newVal, oldVal)) {
        // 渲染新的流
        // 更新用户名
        this.handleUserName(this.groupUserList, newVal)
        this.callback = this.hstWebEngine.renderRemoteMedia(
          newVal.userId,
          'VIDEO',
          newVal.mediaId,
          videoEle,
          // {
          //   width: 1920,
          //   height: 1080
          // },
          () => {},
          () => {},
        )
      }
    },
    unRnderMedia(user) {
      this.renderState = 1
      // 停止接收渲染视频
      if (!this.isEmptyObject(user) && user.isLocal) {
        console.log('停止渲染本地视频')
        if (this.hstWebEngine) {
          this.hstWebEngine.closeLocalVideo(this.$refs['videoEl1'])
        }
      } else if (!this.isEmptyObject(user)) {
        if (this.hstWebEngine && user) {
          this.hstWebEngine.disposeRemoteMedia(
            user.userId,
            'VIDEO',
            user.mediaId,
            this.$refs['videoEl1'],
            this.callback
          )
        }
      }
    },
    isEmptyObject(value) {
      return value === undefined || JSON.stringify(value) === '{}'
    },
    isEqualUser(user, other) {
      if (!this.isEmptyObject(other)) {
        return user.userId === other.userId && user.mediaId === other.mediaId
      }
      return false
    },
    dbclickVideo() {
      if (this.user.isMax) {
        this.SET_MAX_VIDEO(undefined)
      } else {
        this.dbclicked = true
        this.unRnderMedia(this.user)
        this.SET_MAX_VIDEO({ ...this.user, isMax: true })
      }
    },
  },

  beforeDestroy() {
    // 销毁
    // console.error('beforeDestroy 视频销毁之前')
    const { user, maxVideo = {} } = this
    if (user.userId === maxVideo.userId && user.mediaId === maxVideo.mediaId) {
      this.SET_MAX_VIDEO(undefined)
    }
    this.unRnderMedia(this.user)
  },
}
</script>
<style lang="less" scoped>
.video-wrp {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .videos1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #222324 url('~@/assets/img/videoloading.gif') no-repeat center;
    box-sizing: border-box;
  }
  .renderVideo {
    background-image: url('~@/assets/img/video_serchnone.png');
  }
  .fullscreen {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .big-wrap {
    position: absolute;
  }
  .top-black {
    width: 100%;
    padding-top: 56.25%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    // border: 1px solid #fff;
  }

  .left-black {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    // border: 1px solid #f00;
  }
}
</style>
