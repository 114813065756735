<template>
  <div :class="['chat-item-wrapper', data.method]">
    <div class="chat-info">
      <div class="user-name">{{ data.name }}</div>
      <div class="ml-5 mr-5">to</div>
      <div class="user-name">{{ data.receiverName }}</div>
      <div class="ml-5 mr-10">say</div>
      <div>{{ data.time }}</div>
    </div>
    <div :class="['chat-item', data.method]" v-html="data.content"></div>
  </div>
</template>

<script>
export default {
  name: "ChatItem",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="less" scoped>
.chat-item-wrapper {
  padding-top: 15px;
  box-sizing: border-box;
  user-select: unset;

  .chat-info {
    display: flex;
    align-items: center;
    color: #9292a2;
    font-size: 12px;

    .ml-5 {
      margin-left: 5px;
    }

    .mr-5 {
      margin-right: 5px;
    }

    .mr-10 {
      margin-right: 10px;
    }

    .user-name {
      max-width: 120px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &.post {
    text-align: right;

    .chat-info {
      justify-content: flex-end;
    }
  }

  .chat-item {
    font-size: 14px;
    padding: 5px 10px;
    display: inline-block;
    max-width: 80%;
    white-space: pre-line;
    word-break: break-all;
    text-align: left;
    margin-top: 2px;
    user-select: text;
    line-height: 20px;

    &.get {
      color: #42424a;
      background: #fff;
      border-radius: 0px 8px 8px 8px;
    }

    &.post {
      color: #fff;
      background: #4d9cf5;
      border-radius: 8px 2px 8px 8px;

      &::selection {
        background: #76b6ff;
      }
    }

    /deep/ img {
      width: 20px;
      height: 20px;
      vertical-align: top;
    }
  }
}
</style>
