<template>
  <div class="screen-layout">
    <!-- 数据布局 -->
    <template v-if="layoutStyle === LAYOUT_TYPE.DATA">
      <hst-share :shareDataMedia="shareData" />
    </template>
    <!-- 视频布局 -->
    <template v-if="layoutStyle === LAYOUT_TYPE.VIDEO">
      <!-- 有视频流 -->
      <template v-if="showMedia">
        <focus-layout :videoDatas="videoDatas" :videoLocations="videoLocations" />
      </template>
      <!-- 没有推流 -->
      <template v-else>
        <div class="no-screen no-select">
          <div class="welcome">Welcome to use Cloudmeeting</div> <!--{{ welcomeTitle }}-->
          <div class="wait">No one has opened the video for the meeting, please wait patiently.</div>
        </div>
      </template>
    </template>
    <!-- 数据 + 视频布局 -->
    <template v-if="layoutStyle === LAYOUT_TYPE.DATA_VIDEO">
      <div class="mix-wrapper">
        <div class="data">
          <hst-share :shareDataMedia="shareData" />
        </div>
        <div class="video" ref="videoList">
          <div
            class="pre-page page-btn"
            v-if="videoStart > 0"
            @click="moveVideoToPre"
          >
            <a-icon type="up" />
          </div>
          <div
            class="next-page page-btn"
            v-if="videoEnd < videoDatas.length"
            @click="moveVideoToNext"
          >
            <a-icon type="down" />
          </div>
          <div
            class="video-item"
            ref="videoItem"
            v-for="item in displayingVideos"
            :key="`${item.userId}_${item.mediaId}`"
          >
            <div v-if="item.isBlank">
              <layout-video-icon />
            </div>
            <hst-video
              v-else
              :user="item"
              :isBig="false"
              :videoSize="videoSize"
            />
          </div>
        </div>
      </div>
    </template>
    <!-- 最大化某个视频 -->
    <div class="max-video" v-if="maxVideo">
      <a-icon
        type="fullscreen-exit"
        class="max-video-exit"
        @click="maxVideoExit"
      />
      <hst-video
        ref="maxVideoRef"
        :user="maxVideo"
        :isBig="false"
        :videoSize="maxVideoSize"
        video-fit="cover"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import clonedeep from "lodash.clonedeep";
import focusLayout from "@/components/screenLayout/focusLayout"; // 焦点布局
import hstVideo from "@/components/hstMedia/hstVideo";
import HstShare from "./HstShare";
import { layoutVideoIcon } from "@/core/icons";
import { LAYOUT_TYPE } from "@/utils/constants";
import appConfig from "@/config/appConfig";

// 每页视频的数量
const VIDEO_PAGE_SIZE = 4;

export default {
  name: "ScreenLayout",
  components: { focusLayout, hstVideo, HstShare, layoutVideoIcon },
  props: {
    layoutType: {
      type: Number,
      default: LAYOUT_TYPE.DATA,
    },
    showMedia: {
      type: Boolean,
      default: false,
    },
    videoDatas: {
      type: Array,
      default: () => [],
    },
    shareLayoutData: {
      type: Object,
      default: () => {},
    },
    videoLocations: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      welcomeTitle: appConfig.language.welcome,
      LAYOUT_TYPE,
      shareData: {},
      videoSize: {
        leftBlack: false,
        videoWidth: 0,
        maxIconWidth: 200,
      },
      maxVideoSize: {
        fullscreen: true,
        leftBlack: false,
        videoWidth: "100%",
        maxIconWidth: '',
      },
      videoStart: 0,
      videoEnd: VIDEO_PAGE_SIZE,
    };
  },
  computed: {
    maxVideo() {
      return this.$store.state.medias.maxVideo;
    },
    displayingVideos() {
      const avl = this.allVideosLength;
      let videos = clonedeep(this.videoDatas);
      let videoLocations = clonedeep(this.videoLocations);
      // if ( videos.length === 0 ) return
      if (avl < VIDEO_PAGE_SIZE) {
        let newVideos = []
        videos.map((item1) => {
          let curPos = -1
          videoLocations.map((item) => {
            let flag = item.id === item1.userId && item.mediaId === parseInt(item1.mediaId)
            if (flag) {
              curPos = item.pos
            }
          })
          item1.pos = curPos
          return item1
        })

        for (let i = 0; i < VIDEO_PAGE_SIZE; i++) {
          let index = videos.findIndex((item) => {
            return item.pos === i
          })
          if (index > -1) {
            newVideos.push(videos[index])
            videos.splice(index, 1)
          } else { // 不存在 随机抽取
            let index1 = videos.findIndex((item) => {
              return item.pos === -1
            })
            if (index1 > -1) { // 存在没有位置的视频
              newVideos.push(videos[index1])
              videos.splice(index1, 1)
            } else {
              newVideos.push({
                userId: i,
                mediaId: i,
                isBlank: true,
              })
            }
          }
        }
        videos = newVideos
      } else {
        videos.map((item1) => {
          let curPos = -1
          videoLocations.map((item) => {
            let flag = item.id === item1.userId && item.mediaId === parseInt(item1.mediaId)
            if (flag) {
              curPos = item.pos
            }
          })
          item1.pos = curPos
          return item1
        })
        videos = videos.sort((a, b) => {
          return a.pos - b.pos
        })
        videos = videos.slice(this.videoStart, this.videoEnd);
      }
      return videos;
    },
    layoutStyle() {
      return this.layoutType;
    },
    allVideosLength() {
      return this.videoDatas.length;
    },
  },
  mounted() {
    this.setVideoWindowSize();
    window.addEventListener("resize", this.setVideoWindowSize);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("resize", this.setVideoWindowSize);
    });
  },
  methods: {
    ...mapMutations(["SET_MAX_VIDEO"]),
    // 动态设置窗口大小
    setVideoWindowSize() {
      const { videoList } = this.$refs;
      if (videoList) {
        videoList.style.width = videoList.offsetHeight / 3 + "px";
      }
    },
    initShareData() {
      this.shareData = this.shareLayoutData;
    },
    maxVideoExit() {
      this.$refs.maxVideoRef.dbclickVideo();
    },
    // 向上翻页
    moveVideoToPre() {
      if (this.videoStart - VIDEO_PAGE_SIZE <= 0) {
        this.videoStart = 0;
        this.videoEnd = VIDEO_PAGE_SIZE;
      } else {
        this.videoStart -= VIDEO_PAGE_SIZE;
        this.videoEnd -= VIDEO_PAGE_SIZE;
      }
    },
    // 向下翻页
    moveVideoToNext() {
      const avl = this.allVideosLength;
      if (this.videoEnd + VIDEO_PAGE_SIZE > avl) {
        this.videoEnd = avl;
        this.videoStart = avl - VIDEO_PAGE_SIZE;
      } else {
        this.videoStart += VIDEO_PAGE_SIZE;
        this.videoEnd += VIDEO_PAGE_SIZE;
      }
    },
    resetVideoPageNum() {
      const avl = this.allVideosLength;
      this.videoStart = 0;
      this.videoEnd = avl < VIDEO_PAGE_SIZE ? avl : VIDEO_PAGE_SIZE;
    },
  },
  watch: {
    layoutType(value) {
      this.SET_MAX_VIDEO(undefined);
      this.resetVideoPageNum();
      if (value === LAYOUT_TYPE.DATA_VIDEO) {
        this.$nextTick(this.setVideoWindowSize);
      }
    },
    shareLayoutData: {
      handler() {
        this.initShareData();
      },
      immediate: true,
      deep: true,
    },
    allVideosLength(value) {
      if (value <= VIDEO_PAGE_SIZE) {
        this.videoStart = 0;
        this.videoEnd = value;
      } else {
        const expectEnd = this.videoStart + VIDEO_PAGE_SIZE;
        if (expectEnd > value) {
          this.videoEnd = value;
          this.videoStart = value - VIDEO_PAGE_SIZE;
        } else {
          this.videoEnd = expectEnd;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/utils/utils.less";
.screen-layout {
  width: 100%;
  height: 100%;
  position: relative;
  .no-screen {
    .centerAbs();
    padding-top: 210px;
    width: 260px;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;
    background: url("~@/assets/img/extendscreen.png") no-repeat center 10px;
    background-size: 180px 180px;
    .welcome {
      color: #bac7da;
      line-height: 1.5;
      font-size: 24px;
    }
    .wait {
      color: #767a82;
      width: 100% !important;
    }
  }
  .max-video {
    background: #272829;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    .max-video-exit {
      background: #32374a;
      color: #b0b7c1;
      padding: 3px;
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 1001;
      font-size: 18px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .mix-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    .data {
      height: 100%;
      flex: 1;
      background: #e3e7f1;
      position: relative;
    }
    .video {
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .page-btn {
        width: 50%;
        height: 18px;
        background: #32374a;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
      }
      .pre-page {
        top: 0;
      }
      .next-page {
        bottom: 0;
      }
      .video-item {
        width: 100%;
        height: 25%;
        background: #232425;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #000000;
        &:not(:first-child) {
          border-top: 1px solid #000000;
        }
      }
    }
  }
}
</style>
