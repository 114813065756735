<template>
  <a-modal
    class="no-select"
    v-model="visible"
    :maskClosable="false"
    :closable="false"
    title="Choose what to share"
    :width="480"
    centered
  >
    <a-tabs default-active-key="1">
        <a-tab-pane class="share-content" key="1" tab="Screen">
          <div
            class="share-item"
            v-for="item in screenShareContent"
            :key="item.id"
            @click="selectedId = item.id"
            @dblclick="handleShareClick(item.id)"
          >
            <div :class="{ itemImg: true, overview: true, selected: selectedId === item.id }" :style="'background-image:url('+ item.thumbnail +')'">
            </div>
            <div class="title">{{ item.title }}</div>
        </div>
        </a-tab-pane>
        <a-tab-pane class="share-content" key="2" tab="Window" force-render>
          <div
            class="share-item"
            v-for="item in windowShareContent"
            :key="item.id"
            @click="selectedId = item.id"
            @dblclick="handleShareClick(item.id)"
          >
            <div :class="{ itemImg: true, overview: true, selected: selectedId === item.id }" :style="'background-image:url('+ item.thumbnail +')'">
            </div>
            <div class="title">{{ item.title }}</div>
      </div>
        </a-tab-pane>
      </a-tabs>
    <template #footer>
      <a-button @click="handleCancel()">Cancel</a-button>
      <a-button :class="{'share-btn': selectedId !== null}" @click="handleShareClick()" :disabled="selectedId === null">Share</a-button>
    </template>
  </a-modal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selectedId: null,
      screenShareContent:[],
      windowShareContent:[]
    };
  },
  computed: {
    ...mapGetters(["mediasMap", "localUser", "hstWebEngine"]),
  },
  watch: {
    visible(v) {
      if(v) {
        this.getScreenContent();
      }
    }
  },
  methods: {
    async getScreenContent() {
      const screenSource =  await this.hstWebEngine.getScreenSource();
        this.screenShareContent = screenSource.filter(item =>
          item.id.indexOf('screen') > -1
        ).map((item, index)=>{
          return {
            id: item.id,
            title: 'Desktop' + (index+1),
            type: 'SCREEN',
            thumbnail: item.thumbnail.toDataURL()
          }
        })
        this.windowShareContent = screenSource.filter(item=>
          item.id.indexOf('window') > -1
        ).map(item =>{
          return {
            id: item.id,
            title: item.name,
            type: 'WINDOW',
            thumbnail: item.thumbnail.toDataURL()
          }
        })
    },
    handleShareClick(id) {
      const screenId = id || this.selectedId;
      this.hstWebEngine.setScreenStream(screenId);
      this.$emit('screen-selected');
      this.handleCancel();
    },
    handleCancel(e) {
      this.$emit('update:visible', false);
      this.selectedId = null;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-modal-body {
  padding: 10px;
  background: #f6f7f9;
}
.share-btn {
  background: #5aa4f7;
  border-color: #5aa4f7;
  color: #fff;
}
.share-content {
  display: flex;
  flex-wrap:wrap;
  justify-content: flex-start;
  .share-item {
    cursor: pointer;
    margin-right: 10px;
    .itemImg{
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }
    &:hover {
      .overview {
        border: 1px solid #3c98fe;
      }
    }
    .overview {
      width: 180px;
      height: 100px;
      border-radius: 3px;
      background: #fff;
      border: 1px solid #dee1e5;
      display: flex;
      justify-content: center;
      align-items: center;
      &.selected {
        border: 2px solid #3c98fe;
      }
    }
    .title {
      width: 180px;
      overflow: hidden;
      /* 强制在一排显示 */
			white-space: nowrap;
			/* 当文本溢出时，显示省略标记 */
			text-overflow: ellipsis;
      margin-top: 5px;
      text-align: center;
      padding-bottom: 6px;
    }
  }
}
</style>
