<!--
 * @Descripttion: 全屏时的头部工具栏
 * @version:
 * @Author: Terry
 * @Date: 2021-10-26 09:59:08
 * @LastEditors: Terry
 * @LastEditTime: 2021-10-26 11:37:31
-->
<template>
  <div class="tool-container">
    <div
      :class="[
        'tool-wrap',
        { 'show-tool': isShow, 'anonymous-join': isAnonymousJoin },
      ]"
    >
      <!-- 顶部工具栏 -->
      <div class="full-tool-wrap">
        <div class="full-tool">
          <div class="tool-c clearfix">
            <audio-status
              :place="'top'"
              :micList="devicesList.micDevs"
              :spkList="devicesList.spkDevs"
              @openPoupe="showBar"
              @changeMic="changeMic"
            ></audio-status>
          </div>
          <div class="tool-c clearfix">
            <video-status
              :place="'top'"
              :camList="devicesList.camDevs"
              :resList="resolutionList"
              @openPoupe="showBar"
            ></video-status>
          </div>
          <div class="clearfix" @click="showShareModal">
            <share-btn :active="isSelfShare"></share-btn>
          </div>
          <div class="clearfix" @click="toLayoutScreenfull">
            <layout-status></layout-status>
          </div>
          <div
            class="clearfix"
            v-if="!isAnonymousJoin"
            @click="openInviteModal"
          >
            <invite-btn :active="inviteShow" />
          </div>
          <div class="clearfix" @click="SET_USER_LIST_SHOW(!userListShow)">
            <user :active="userListShow"></user>
          </div>
          <div class="clearfix">
            <record-status
        :status="cloudRecordStatus"
        @onChange="operateCloudRecord"
      ></record-status>
          </div>
          <div class="clearfix" @click="SET_CHAT_SHOW(!chatShow)">
            <chat-btn :active="chatShow" />
          </div>
          <div class="clearfix">
            <div
              class="user-item fl audio"
              @click="exit"
              style="color: #f45b5b"
            >
              <div class="f-icons">
                <exitMeeting class="icon"></exitMeeting>
              </div>
              <div class="actions no-select">Exit</div>
            </div>
          </div>
          <div class="clearfix">
            <div class="user-item fl audio" @click="exitFullscreen">
              <div class="f-icons">
                <fullScreenBacks class="icon"></fullScreenBacks>
              </div>
              <div class="actions no-select">Exit full screen</div>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="['touchBar', { 'show-tool-bar': !isShow }]"
        @mouseover="mouseOver"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { fullScreenBacks, exitMeeting } from '@/core/icons' // 图标引入
import audioStatus from '@/components/toolBar/audioStatus'
import videoStatus from '@/components/toolBar/videoStatus'
import layoutStatus from '@/components/toolBar/layoutStatus'
import exitClient from '@/components/toolBar/exitClient'
import ShareBtn from '@/components/toolBar/shareBtn'
import ChatBtn from '@/components/toolBar/chatBtn'
import user from '@/components/toolBar/user'
import inviteBtn from '@/components/toolBar/inviteBtn'
import recordStatus from'@/components/toolBar/recordStatus'

export default {
  name: 'ToolbarTop',
  components: {
    fullScreenBacks,
    exitMeeting,
    audioStatus,
    videoStatus,
    layoutStatus,
    exitClient,
    ShareBtn,
    ChatBtn,
    user,
    inviteBtn,
    recordStatus
  },
  props: {
    devices: {
      type: [Object],
      default: () => {},
    },
    resolution: {
      type: [Array],
      default: () => {},
    },
    show: Boolean,
    isSelfShare: {
      type: Boolean,
      default: false,
    },
    isAnonymousJoin: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      devicesList: {},
    }
  },
  computed: {
    ...mapGetters(['chatShow', 'userListShow', 'inviteShow', 'roleAuth','cloudRecordStatus','hstWebEngine', 'localUser']),
    isShow() {
      return this.show
    },
    resolutionList() {
      if (!this.resolution) return []

      return this.resolution
    },
  },
  watch: {
    devices: {
      handler() {
        this.handleDeviceList()
      },
    },
  },
  created() {
    this.handleDeviceList()
  },
  methods: {
    ...mapMutations(['SET_CHAT_SHOW', 'SET_USER_LIST_SHOW', 'SET_INVITE_SHOW']),
    operateCloudRecord (status) {
      if (status) {
        if ((this.localUser && this.localUser.ManagerState == 2 && this.localUser.Right == 3) || this.localUser.DataState === 2) {
          this.$confirm({
            title: 'The current meeting is not over, are you sure you want to turn off cloud recording?',
            onOk: () => {
              this.hstWebEngine.stopRecord()
            }
          })
        } else {
          this.$info({
            title: 'Only the host or administrator can operate cloud recording',
            okText: 'Got it',
          })
        }
      } else {
        if ((this.localUser && this.localUser.ManagerState == 2 && this.localUser.Right == 3) || this.localUser.DataState === 2) {
          this.hstWebEngine.startRecord()
        } else {
          this.$info({
            title: 'Only the host or administrator can operate cloud recording',
            okText: 'Got it',
          })
        }
      }
    },
    // 设备列表
    handleDeviceList() {
      const { micDevs = [], camDevs = [], spkDevs = [] } = this.devices
      this.devicesList = { micDevs, camDevs, spkDevs }
    },
    // 开关音频
    changeMic() {
      this.$emit('on-change-audio')
    },
    showBar() {
      this.$emit('on-show-bar')
    },
    // 布局
    toLayoutScreenfull() {
      this.$emit('on-layout-click')
    },
    // 退出会议
    exit() {
      this.$emit('on-exit')
    },
    // 退出全屏
    exitFullscreen() {
      this.$emit('on-exit-ffullscreen')
    },
    mouseOver() {
      this.$emit('on-mouseover')
    },
    // 打开共享
    showShareModal() {
      this.$emit('on-show-share')
    },
    // 打开通讯录邀请
    openInviteModal() {
      const { OnlineInvitation } = this.roleAuth
      if (parseInt(OnlineInvitation) === 1) {
        this.SET_INVITE_SHOW(true)
      } else {
        this.$toast('You do not have permission to operate')
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/utils/utils.less';

.tool-container {
  width: 80%;
  height: 60px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;

  .tool-wrap {
    @base-width: 575px;
    width: calc(@base-width + 65px);
    height: 60px;
    margin: 0 auto;
    transform: translateY(-60px);
    background: @primary-color;
    transition: 0.5s;
    border-radius: 0px 0px 6px 6px;
    z-index: 2;
    .tool-c {
      width: 84px;
    }

    &.show-tool {
      margin: 0 auto;
      z-index: 1000;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
      transform: translateY(0);

      .full-tool-wrap {
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
      }
    }

    &.anonymous-join {
      width: @base-width;
    }
  }

  .full-tool-wrap {
    width: 100%;
    padding: 0 10px;
    position: absolute;
    left: 0;
    top: 0;
    background: @primary-color;
    border-radius: 0px 0px 6px 6px;

    .full-tool {
      margin: 0 auto;
      display: flex;
      height: 60px;
      flex-direction: row;
      color: #c7d4e8;

      .user-item {
        width: 64px;
        height: 60px;
        font-size: 12px;
        line-height: 16px;
        background-size: 64px 60px;
        color: #c8d7e6;
        text-align: center;
        cursor: pointer;
        &:hover {
          background: #434a63;
        }
      }
    }
  }

  .touchBar {
    width: 100%;
    height: 20px;
    background: @primary-color;
    opacity: 0;
    z-index: -10;
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
  }
  .show-tool-bar {
    opacity: 0;
    transform: translateY(20px);
    transition: 3s;
    display: block;
  }

  .f-icons {
    margin: 4px auto 0;
    width: 34px;
    height: 34px;
    position: relative;
    text-align: center;

    .icon {
      width: 34px;
      height: 34px;
    }
    .actions {
      line-height: 16px;
    }
  }
}
</style>
