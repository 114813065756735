<template>
  <div class="audio-icon no-select" :style="{ 'max-width': maxIconWidth }">
    <span :class="{ haspadding: hasIcon }">
      <span v-if="hasIcon">
        <template v-if="audioState === 2">
          <audioMic class="icon"></audioMic>
          <audioMicVolume1 v-show="volume > 0 && volume <= 15" class="icon vols"></audioMicVolume1>
          <audioMicVolume2 v-show="volume > 15 && volume <= 35" class="icon vols"></audioMicVolume2>
          <audioMicVolume3 v-show="volume > 35 && volume <= 50" class="icon vols"></audioMicVolume3>
          <audioMicVolume4 v-show="volume > 50 && volume <= 65" class="icon vols"></audioMicVolume4>
          <audioMicVolume5 v-show="volume > 65 && volume <= 85" class="icon vols"></audioMicVolume5>
          <audioMicVolume6 v-show="volume > 85 && volume <= 100" class="icon vols"></audioMicVolume6>
        </template>
        <template v-if="audioState === 1">
          <audioHandUp class="icon"></audioHandUp>
        </template>
        <template v-if="audioState === 0">
          <audioStop class="icon"></audioStop>
        </template>
      </span>
    </span>
    <span>{{ name }}</span>
  </div>
</template>
<script>
import {
  audioVol,
  audioMicVolume1,
  audioMicVolume2,
  audioMicVolume3,
  audioMicVolume4,
  audioMicVolume5,
  audioMicVolume6,
  audioMic,
  audioHandUp,
  audioStop
} from '@/core/icons' // 图标引入
import { mapGetters } from 'vuex'
export default {
  data () {
    return {}
  },
  props: {
    hasIcon: {
      type: Boolean,
      default: false
    },
    showVol: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: '用户'
    },
    volume: {
      type: [String, Number],
      default: '0'
    },
    audioState: {
      type: Number,
      default: 0
    },
    maxIconWidth: {
      type: String,
      default: ''
    }
  },
  components: {
    audioVol,
    audioMicVolume1,
    audioMicVolume2,
    audioMicVolume3,
    audioMicVolume4,
    audioMicVolume5,
    audioMicVolume6,
    audioMic,
    audioHandUp,
    audioStop
  },
  computed: { ...mapGetters(['audioStatus']) }
}
</script>
<style lang="less" scoped>
.audio-icon {
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 6px;
  padding-right: 8px;
  font-size: 12px;
  color: #ffffff;
  // line-height: 28px;
  line-height: 16px;
  background: rgba(81, 86, 113, 0.6);
  border-radius: 3px;
  z-index: 10;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .haspadding {
    padding-left: 16px;
  }
  .icon {
    position: absolute;
    top: 7px;
    left: 6px;
    width: 16px;
    height: 16px;
    // cursor: pointer;
    // transform: translateY(0.12rem);
  }
  .vols {
    position: absolute;
    top: 7px;
    left: 6px;
  }
}
</style>
